import locale from 'locale-codes';

const getShortLanguageName = (language?: string ) => {
    if (!language) return '-';

    const short = locale.getByName(language);

    if (short) {
        return short['iso639-2']?.toUpperCase();
    } else {
        return language.substring(0, 3).toUpperCase();
    }
};

export default getShortLanguageName;
