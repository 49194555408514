import React from 'react';
import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';


type YearMonthPickerType<T> = {
    yearProp: keyof T;
    monthProp: keyof T;
    label: string
}

function YearMonthPicker<T>({ yearProp, monthProp, label }: YearMonthPickerType<T>) {
    const { values, setFieldValue } = useFormikContext<T>();

    const getValue = () => {
        if (values[yearProp] && values[monthProp]) {
            return moment(values[monthProp] + '/' + values[yearProp]).format('MMM/YYYY');
        }

        if (values[yearProp]) {
            return moment(values[yearProp]).format('YYYY');
        }

        if (values[monthProp]) {
            return moment(values[monthProp]).format('MMM');
        }

        return null;
    };


    const setValue = (newValue: string | null) => {
        if (!newValue) {
            setFieldValue(yearProp.toString(), null);
            setFieldValue(monthProp.toString(), null);
            return;
        }
        setFieldValue(yearProp.toString(), moment(newValue, 'MMM/YYYY').format('YYYY'));
        setFieldValue(monthProp.toString(), moment(newValue, 'MMM/YYYY').format('MMM'));
    };


    return <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
            views={['year', 'month']}
            minDate={moment('01/01/2020', 'DD/MM/YYYY')}
            maxDate={moment()}
            label={label}
            value={getValue()}
            onChange={(newValue) => {
                setValue(newValue as unknown as string);
            }}
            renderInput={(params) => <TextField fullWidth size={'small'} {...params} />}
        />
    </LocalizationProvider>;
}

export default YearMonthPicker;
