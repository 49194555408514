import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import ChatOptionForm from './ChatOptionForm';
import { API } from '../../../../utils/ApiClient';


function useChangeChatAdministrator() {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation<{ status: number }, unknown, ChatOptionForm, unknown>({
        mutationFn: (form) => API.put('users/setchatadministrator', form),
        onSuccess() {
            queryClient.invalidateQueries('users/getchatadministrator');
            enqueueSnackbar('Settings saved', {
                variant: 'success',
            });
        },
    });
}


export default useChangeChatAdministrator;
