import { useMutation, useQueryClient } from 'react-query';
import { API } from '../utils/ApiClient';


function useDeleteFile(toDelete: string, toInvalidate?: string, onClose?: () => void) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => API.delete(toDelete),
        onSuccess() {
            if (toInvalidate) {
                queryClient.invalidateQueries(toInvalidate);
            }
            onClose?.();
        },
    });
}

export default useDeleteFile;
