import TextField from '../../Form/fields/TextField';
import { Stack } from '@mui/material';

type AddressEditorProps = {
    type: 'postal' | 'invoice';
};

const AddressEditor = ({ type }: AddressEditorProps) : JSX.Element => {
    return (
        <Stack spacing={3}>
            <TextField name={`${type}Address.name`} label={'Name'} />
            <Stack direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3, sm: 2 }}>
                <TextField name={`${type}Address.zip`} label={'ZIP'} />
                <TextField name={`${type}Address.country`} label={'Country'} />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3, sm: 2 }}>
                <TextField name={`${type}Address.state`} label={'State'} />
                <TextField name={`${type}Address.city`} label={'City'} />
            </Stack>
            <TextField name={`${type}Address.address`} label={'Address'} />
            {type === 'invoice' && (
                <TextField name={`${type}Address.vatNumber`} label={'VAT Number'} />
            )}
        </Stack>
    );
};

export default AddressEditor;
