import React from 'react';
import { FileType } from '../../../enums/FileType';
import File from '../../../models/File';
import { CircularProgress, Tooltip, Typography } from '@mui/material';
import { UserRole } from '../../../enums/UserRole';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import User from '../../../models/User';
import useFilesByTask from './useFilesByTask';

type Props = {
    fileType: FileType;
    files?: File[];
    currentUser?: User;
    taskId?: string;
}

const DataLoader: React.FC<Props> = ({ fileType, files, currentUser, taskId }) => {
    const { data: originalFiles } = useFilesByTask(FileType.ORIGINAL, taskId);
    if (currentUser && currentUser.role !== UserRole.ADMIN && currentUser.role !== UserRole.AGENT) return <></>;

    const checkAutoTranslationStatus = () => {
        if (fileType === FileType.AUTOTRANSLATION && files && !files[0]) {
            if (!originalFiles?.length) {
                return (
                    <Tooltip title={'Original File is missing...'}>
                        <WarningTwoToneIcon fontSize={'small'} color={'warning'}/>
                    </Tooltip>
                );
            } else {
                return (
                    <Tooltip title={'Counting characters in file...'}>
                        <CircularProgress color='primary' size={24} />
                    </Tooltip>
                );
            }
        }
    };

    return (
        <>
            <Typography variant='subtitle2' color='text.secondary' sx={{ textTransform: 'capitalize' }}>
                {fileType + ' file'}
            </Typography>
            {files && files[0] && files[0].parseStatus === 'parsing' && <Tooltip title={'Counting characters in file...'}>
                <CircularProgress color='primary' size={24} />
            </Tooltip>}
            {files && files[0] && files[0].parseStatus === 'error' && <Tooltip title={'File could not be processed for character count'}>
                <WarningTwoToneIcon fontSize={'small'} color={'warning'}/>
            </Tooltip>}
            {fileType === FileType.AUTOTRANSLATION && checkAutoTranslationStatus()}
        </>
    );
};

export default DataLoader;
