import React, { ReactElement } from 'react';
import { useQuery } from 'react-query';
import { Typography } from '@mui/material';
import Customer from '../../../models/Customer';

function useCustomerOptions() {
    return useQuery<{ customers: Customer[] }, unknown, { id: string; name: ReactElement }[]>('customers', {
        select(data) {
            return data.customers.map((customer) => ({
                id: customer.id ?? 'id missing',
                name: (
                    <Typography>
                        {customer.name}
                    </Typography>
                ),
            }));
        },
    });
}

export default useCustomerOptions;
