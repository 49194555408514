import * as Yup from 'yup';

const CustomerSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
    postalAddress: Yup.object().shape({
        name: Yup.string().required('Name required'),
        zip: Yup.number().required('ZIP required'),
        country: Yup.string().required('Country required'),
        state: Yup.string().required('State required'),
        city: Yup.string().required('City required'),
        address: Yup.string().required('Name required'),
    }),
});

export default CustomerSchema;
