import React, { useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import uploadFill from '@iconify/icons-eva/cloud-upload-fill';
import downloadFill from '@iconify/icons-eva/cloud-download-fill';
import { Icon } from '@iconify/react';
import ReportPDF from '../ReportPDF/ReportPDF';
import TranslatorReport from '../../../models/TranslatorReport';
import InvoiceDialog from '../InvoiceDialog';

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.primary,
}));

type ReportProps = {
    report: TranslatorReport | undefined;
    reportId?: string;
};

export default function ReportToolbar({ report, reportId }: ReportProps): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);


    const handleOpen = () => {
        setOpen(!open);
    };


    return (
        <>
            <Stack
                spacing={2}
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="space-between"
                alignItems={{ sm: 'center' }}
                sx={{ mb: 5 }}
            >
                <PDFDownloadLink
                    document={<ReportPDF report={report}/>}
                    fileName={report ? `Report_${report.month}/${report.year}` : 'TranslatorReport'}
                    style={{ textDecoration: 'none' }}
                >
                    <StyledButton variant="outlined" endIcon={<Icon icon={downloadFill} />}>
                        Download Table
                    </StyledButton>
                </PDFDownloadLink>


                <StyledButton
                    variant="outlined"
                    onClick={handleOpen}
                    startIcon={<Icon icon={uploadFill} />}
                    sx={{ alignSelf: 'flex-end' }}
                >
                    Manage Invoice
                </StyledButton>

            </Stack>

            <InvoiceDialog reportId={reportId} open={open} setOpen={setOpen}/>
        </>
    );
}
