import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeConfig from './theme';
import Settings from './components/settings';
import Router from './routes';
import NotistackProvider from './components/NotistackProvider';
import { ReactQueryDevtools } from 'react-query/devtools';
import QueryClientProvider from './contexts/QueryClientProvider';
import { SocketProvider } from './contexts/SocketContext';


function App():JSX.Element {
    return (
        <ThemeConfig>
            <ThemePrimaryColor>
                <NotistackProvider>
                    <QueryClientProvider>
                        <Settings />
                        <BrowserRouter>
                            <SocketProvider>
                                <Router />
                            </SocketProvider>
                        </BrowserRouter>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </NotistackProvider>
            </ThemePrimaryColor>
        </ThemeConfig>
    );
}

export default App;
