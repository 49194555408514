import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';
import { useNavigate } from 'react-router';

function useReopenTask() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<{ data: { id: string } }, unknown, { taskId?: string }>({
        mutationFn: ({ taskId }) => API.get(`tasks/${taskId}/reopen`),
        onSuccess(res) {
            queryClient.invalidateQueries('tasks');
            navigate(`/tasks/${res.data.id}/edit`);
        },
    });
}

export default useReopenTask;
