import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { NewPasswordForm } from '../../components/authentication/new-password';
import Page from '../../components/Page';
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
import useAPI from '../../utils/ApiClient';

const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));

export default function NewPassword(): JSX.Element {
    const jwt = new URLSearchParams(location.search).get('jwt');

    const [, newPassword] =
        useAPI({ url: `/users/newpassword`, params: { jwt }, method: 'PUT' }, { manual: true });
    const [password, setPassword] = useState<string>('');
    const [passwordAgain, setPasswordAgain] = useState<string>('');
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleNewPassword = async () => {
        newPassword({ data: { newPassword: password, newPasswordAgain: passwordAgain } })
            .then(() => {
                enqueueSnackbar(
                    'New password has been set, you will be redirected to login in 2 seconds',
                    { variant: 'success' },
                );
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            })
            .catch((res) => {
                if (res.status === 400) {
                    enqueueSnackbar('Two passwords are not the same!', { variant: 'error' });
                } else {
                    enqueueSnackbar(
                        'Unknown error occured while tried to set new password. Please try again later.',
                        { variant: 'error' },
                    );
                }
            });
    };

    return (
        <RootStyle title="WeTranslate">
            <LogoOnlyLayout />

            <Container>
                <Box sx={{ maxWidth: 480, mx: 'auto' }}>

                    <Typography variant="h3" paragraph>
                        New password
                    </Typography>

                    <NewPasswordForm
                        handlePassword={(value: string) => setPassword(value)}
                        handlePasswordAgain={(value: string) => setPasswordAgain(value)}
                        setNewPassword={handleNewPassword}
                    />

                </Box>
            </Container>
        </RootStyle>
    );
}
