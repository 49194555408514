import { Grid } from '@mui/material';
import TextField from '../../../Form/fields/TextField';

const PostalAddress: React.FC = () => {
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <TextField name={'postalAddress.name'} label={'Name'} />
        </Grid>
        <Grid item xs={6}>
            <TextField name={'postalAddress.zip'} label={'ZIP'} />
        </Grid>
        <Grid item xs={6}>
            <TextField name={'postalAddress.country'} label={'Country'} />
        </Grid>
        <Grid item xs={6}>
            <TextField name={'postalAddress.state'} label={'State'} />
        </Grid>
        <Grid item xs={6}>
            <TextField name={'postalAddress.city'} label={'City'} />
        </Grid>
        <Grid item xs={12}>
            <TextField name={'postalAddress.address'} label={'Address'} />
        </Grid>
    </Grid>;
};

export default PostalAddress;
