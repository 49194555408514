import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { FormattedAnnotation } from './AnnotationTypes';
import DocumentBlocks from './DocumentBlocks';
import DocumentEditor from './DocumentEditor';
import useEditor from './useEditor';

const DocumentPage = () => {
    const {
        setTransformedData,
        editorState: { selectedPageData, selectedPage, transformedData },
    } = useEditor();

    useEffect(() => {
        if (!selectedPageData) return;

        const updatedTransformedData = transformedData.map((data, i) => {
            if (i === selectedPage - 1) {
                return selectedPageData;
            } else {
                return data;
            }
        });

        setTransformedData(updatedTransformedData as FormattedAnnotation);
    }, [selectedPageData]);

    return (
        <Box pb={10} pt={4}>
            <DocumentEditor />
        </Box>
    );
};

export default DocumentPage;
