import closeFill from '@iconify/icons-eva/close-fill';
import downloadFill from '@iconify/icons-eva/download-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import { Icon } from '@iconify/react';
import {
    List,
    ListItem,
    ListItemIcon, ListItemText, Stack, Tooltip,
    Typography,
} from '@mui/material';
import useDeleteFile from '../hooks/useDeleteFile';
import useDownloadFile from '../hooks/useDownloadFile';
import usePreviewFile from '../hooks/usePreviewFile';
import File from '../models/File';
import convertBytes from '../utils/ByteConverter';
import { MIconButton } from './@material-extend';

type ListFilesProps = {
    file: File | undefined;
    reportId?: string;
}

export default function ShowFile(props: ListFilesProps): JSX.Element {
    const downloadFile = useDownloadFile(`/translatorreports/${props.reportId}/files/${props.file?.id}/download`);
    const previewFile = usePreviewFile(`/translatorreports/${props.reportId}/files/${props.file?.id}/preview`);
    const { mutate: deleteFile } = useDeleteFile(
        `/translatorreports/${props.reportId}/files/${props.file?.id}`,
        `translatorreports/${props.reportId}/invoice`,
    );

    if (!props.file) return <Typography>Invoice not uploaded yet</Typography>;

    return (
        <List>
            <ListItem
                key={props.file.id}
                component={'div'}
                sx={{
                    py: 0.5,
                    px: 1,
                    borderRadius: 1,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    bgcolor: 'background.paper',
                }}
            >
                <ListItemIcon>
                    <Icon icon={fileFill} width={28} height={28} />
                </ListItemIcon>
                <ListItemText
                    primary={props.file.filename}
                    secondary={convertBytes(props.file.size ?? 0)}
                    primaryTypographyProps={{ variant: 'subtitle2', sx: {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    } }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                />
                <Stack direction={'row'}>
                    <Tooltip title={'Download'}>
                        <MIconButton edge="end" size="small" onClick={downloadFile}>
                            <Icon icon={downloadFill} />
                        </MIconButton>
                    </Tooltip>

                    <Tooltip title={'Preview'}>
                        <MIconButton edge="end" size="small" onClick={previewFile}>
                            <Icon icon={eyeFill} />
                        </MIconButton>
                    </Tooltip>

                    <Tooltip title={'Delete'}>
                        <MIconButton edge="end" size="small" onClick={() => deleteFile()}>
                            <Icon style={{ color: 'red' }} icon={closeFill} />
                        </MIconButton>
                    </Tooltip>
                </Stack>
            </ListItem>
        </List>
    );
}
