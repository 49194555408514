import Task from '../../../models/Task';
import { Badge, useTheme } from '@mui/material';
import Label from '../../../components/Label';
import React from 'react';
import snakeCaseToReadable from '../../../utils/snakeCaseToReadable';
import { UserRole } from '../../../enums/UserRole';
import useQueryString from '../../../hooks/useQueryString';

const Status: React.FC<{ task: Task, role: UserRole }> = ({ task, role }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const [query] = useQueryString();

    const isActionNeeded = query['section'] === 'actionNeeded';
    const hasWarningBadge = () => {
        if (!isActionNeeded) {
            return false;
        }

        const adminShouldBeWarned = role === UserRole.ADMIN && !task.adminSeen;
        const customerShouldBeWarned = [UserRole.USER, UserRole.AGENT].includes(role) && !task.customerSeen;
        const translatorShouldBeWarned = role === UserRole.TRANSLATOR && !task.translatorSeen;
        return adminShouldBeWarned || customerShouldBeWarned || translatorShouldBeWarned;
    };


    if (hasWarningBadge()) {
        return <Badge sx={{ pr: 1 }}
            badgeContent="!"
            color="error"
        >
            <Label
                variant={isLight ? 'ghost' : 'filled'}
                taskStatus={task.status}
            >
                {task.status?.split('_').join(' ')}
            </Label>
        </Badge>;
    }

    return (
        <Label
            variant={isLight ? 'ghost' : 'filled'}
            taskStatus={task.status}
        >
            {snakeCaseToReadable(task.status ?? '')}
        </Label>);
};

export default Status;
