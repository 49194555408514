import { useQuery } from 'react-query';

type TableData = {
  name: string
  marginRatio: number
  pagesDelivered: number
  averageDelivery: number
}[]

type Response = {
  user: {
    id: string
    name: string
    canLogin: boolean
  }
  data: {
    averageDelivery: number;
    marginRatio: number;
    totalPages: number;
  }
}[]

function useTranslatorStatistics() {
    return useQuery<Response, unknown, TableData>('dashboard/translator-infos', {
        select(res) {
            return res.map(({ user, data }) => ({
                name: user.name,
                marginRatio: data.marginRatio,
                pagesDelivered: data.totalPages ?? 0,
                averageDelivery: data.averageDelivery,
            }));
        },
    });
}

export default useTranslatorStatistics;
