import { useMutation } from 'react-query';
import Task from '../../models/Task';
import { API } from '../../utils/ApiClient';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

function useUpdateTask(taskId?: string) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    return useMutation<{ status: number }, unknown, Task, unknown>({
        mutationFn: (form) => API.put(`tasks/${taskId}`, form ),
        onSuccess() {
            enqueueSnackbar('Task updated', {
                variant: 'success',
            });
            navigate('/tasks');
        },
    });
}

export default useUpdateTask;
