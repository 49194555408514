/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { TaskStatus } from '../enums/TaskStatus';
import Task from '../models/Task';
import useAPI from '../utils/ApiClient';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props}>{props.children!}</Slide>;
});

type ActionNeededSeen = {
    actionNeeded: number;
}

type PriceRejectionDialogProps = {
    open: boolean;
    onClose: () => void;
    onSubmit?: (task: any, explanation?: string) => void;
    task?: Task;
    taskId?: string;
    status: TaskStatus;
    setStatus?: React.Dispatch<React.SetStateAction<TaskStatus | undefined>>;
    setTasks?: React.Dispatch<React.SetStateAction<Task[] | undefined>>;
    setTotalCount?: React.Dispatch<React.SetStateAction<number>>;
    section?: string;
    offset?: number;
    limit?: number;
    setActionNeededCount?: React.Dispatch<React.SetStateAction<ActionNeededSeen | undefined>>;
    onEdit?: boolean;
};

export default function PriceRejectionDialog(
    { open, onClose, task, onSubmit, status, section, setTasks, setActionNeededCount,
        setTotalCount, offset, limit, onEdit }: PriceRejectionDialogProps): JSX.Element {
    const navigate = useNavigate();
    const [explanation, setExplanation] = useState<string>('');
    const [, getTargetTask] = useAPI({ method: 'GET' }, { manual: true });
    const [, updateTask] = useAPI({ method: 'PUT' }, { manual: true });
    const [, getTasks] = useAPI<{ tasks: Task[], totalCount: number }>(
        { url: '/tasks', method: 'GET' },
        { manual: true },
    );
    const [, getActionNeededNotSeen] = useAPI(
        { url: '/tasks/list/unseen', method: 'GET' },
        { manual: true },
    );

    const handleSubmit = async () => {
        if (task) {
            task.explanation = explanation;
            if (status && onSubmit) {
                onSubmit(status, explanation);
            } else if (updateTask) {
                const taskToUpdate = await (await getTargetTask({ url: `/tasks/${task.id}` })).data;
                taskToUpdate.status = status;
                taskToUpdate.explanation = explanation;
                updateTask({ url: `/tasks/${task.id}`, data: taskToUpdate }).then(() => {
                    if (onEdit) {
                        navigate('/tasks');
                    } else {
                        getTasks({ params: { section: section, offset, limit } })
                            .then((res) => {
                                setTasks && setTasks(res?.data.tasks);
                                setTotalCount && setTotalCount(res?.data.totalCount);
                                getActionNeededNotSeen().then((r) => {
                                    setActionNeededCount && setActionNeededCount(r?.data);
                                });
                            });
                    }
                });
            }
            onClose();
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
            >
                <DialogTitle id="price-rejection-dialog-title"
                    sx={{ textAlign: 'center' }}>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="price-rejection-dialog-description" sx={{ textAlign: 'center' }}>
                        Please tell us why do you want to reject the Task?
                    </DialogContentText>
                    <TextField
                        fullWidth
                        label="Explanation"
                        sx={{ mt: 2 }}
                        value={explanation}
                        onChange={(e) => setExplanation(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSubmit} color="primary">
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
