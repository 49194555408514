function createDateArrayFromRange(startDate: Date, endDate: Date) {
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
        dateArray.push(new Date(currentDate));
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return dateArray;
}

export default createDateArrayFromRange;
