/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';
import { Card, Container, Grid, Stack } from '@mui/material';
import { useParams } from 'react-router';
import useTask from '../useTask';
import Form from '../../../components/Form';
import PostalAddressEditor from '../PostalAddressEditor';
import Page from '../../../components/Page';
import TaskDetailEditor from '../TaskDetailEditor';
import Task from '../../../models/Task';
import useUpdateTask from '../useUpdateTask';
import TaskInfo from '../TaskInfo';
import TextField from '../../../components/Form/fields/TextField';
import FileByType from '../FilesByType';
import { FileType } from '../../../enums/FileType';
import CommentsSection from '../CommentsSection';
import TaskLoading from '../TaskLoading';
import { UserRole } from '../../../enums/UserRole';
import StatusHandlerButtons from '../../../utils/StatusHandler';
import { useQuery } from 'react-query';
import Customer from '../../../models/Customer';
import SuggestionSection from '../Suggestions';
import useSeenTask from '../useSeenTask';
import useGetTaskWidgets from '../../../hooks/useGetTaskWidgets';
import { TaskWidgetSlug } from '../../../enums/TaskWidgetSlug';
import WidgetGuard from './WidgetGuard';

const TranslatorEditor: React.FC = () => {
    const { id: taskId } = useParams();
    const { data: task } = useTask(taskId);
    const { data: customer } = useQuery<Customer>(['customers/', task?.customerId], {
        enabled: !!task?.customerId,
    });
    const { data: widgetsRoles, isLoading: loadingWidgets } = useGetTaskWidgets();
    const { mutate: updateTask } = useUpdateTask(taskId);
    const { mutate: setSeen } = useSeenTask();

    useEffect(() => {
        setSeen({ id: taskId });
    }, [setSeen, taskId]);

    if (!task || loadingWidgets) {
        return <TaskLoading />;
    }

    return (
        <Page title='WeTranslate'>
            <Form<Task> validationSchema={undefined} onSubmit={updateTask} initialValues={task}>
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <WidgetGuard
                                    role={UserRole.TRANSLATOR}
                                    widgetSlug={TaskWidgetSlug.GENERAL_INFO}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}
                                >
                                    <Card sx={{ p: 2 }}>
                                        <TaskInfo task={task} isCustomer={false} />
                                    </Card>
                                </WidgetGuard>

                                <WidgetGuard
                                    role={UserRole.TRANSLATOR}
                                    widgetSlug={TaskWidgetSlug.TRANSLATED_FILE}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}
                                >
                                    <FileByType fileType={FileType.TRANSLATED} taskId={taskId} taskStatus={task.status} />
                                </WidgetGuard>

                                <WidgetGuard
                                    role={UserRole.TRANSLATOR}
                                    widgetSlug={TaskWidgetSlug.ORIGINAL_FILE}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}
                                >
                                    <FileByType fileType={FileType.ORIGINAL} taskId={taskId} taskStatus={task.status} />
                                </WidgetGuard>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <WidgetGuard
                                    role={UserRole.TRANSLATOR}
                                    widgetSlug={TaskWidgetSlug.TASK_DETAILS}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}
                                >
                                    <Card sx={{ p: 2, bgcolor: 'background.neutral' }}>
                                        <TaskDetailEditor disabled />
                                    </Card>
                                </WidgetGuard>

                                {task.hardCopyRequired && (
                                    <WidgetGuard
                                        role={UserRole.TRANSLATOR}
                                        widgetSlug={TaskWidgetSlug.POSTAL_ADDRESS}
                                        widgetRoles={widgetsRoles}
                                        withApproval={false}
                                    >
                                        <Card>
                                            <PostalAddressEditor cannotEdit />
                                        </Card>
                                    </WidgetGuard>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <WidgetGuard
                                    role={UserRole.TRANSLATOR}
                                    widgetSlug={TaskWidgetSlug.STATUS}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}
                                >
                                    <StatusHandlerButtons
                                        userId={task.userId}
                                        task={task!}
                                        role={UserRole.TRANSLATOR}
                                        size={'small'}
                                        shouldApprovePrice={customer?.mustApprovePrice ?? false}
                                    />
                                </WidgetGuard>

                                <WidgetGuard
                                    role={UserRole.TRANSLATOR}
                                    widgetSlug={TaskWidgetSlug.COMMENTS}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}
                                >
                                    <CommentsSection />
                                </WidgetGuard>

                                <WidgetGuard
                                    role={UserRole.TRANSLATOR}
                                    widgetSlug={TaskWidgetSlug.SUGGESTION}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}
                                >
                                    <SuggestionSection />
                                </WidgetGuard>

                                {task.hardCopyRequired && (
                                    <WidgetGuard
                                        role={UserRole.TRANSLATOR}
                                        widgetSlug={TaskWidgetSlug.TRACKING_NUMBER}
                                        widgetRoles={widgetsRoles}
                                        withApproval={false}
                                    >
                                        <Card sx={{ p: 2 }}>
                                            <TextField
                                                name={'trackingNumber'}
                                                label={'Tracking number'}
                                            />
                                        </Card>
                                    </WidgetGuard>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Form>
        </Page>
    );
};

export default TranslatorEditor;
