import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { API } from '../../../utils/ApiClient';
import { TranslationOfferWithPrices } from './Prices';


function useSaveTranslationOffers() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ translationOffers }: {translationOffers: TranslationOfferWithPrices[]}) => API.post(`/translationoffers/bulkcreate`, translationOffers),
        onSuccess() {
            enqueueSnackbar('Offers saved', {
                variant: 'success',
            });
            queryClient.invalidateQueries(`translationoffers`);
        },
    });
}


export default useSaveTranslationOffers;

