import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import Customer from '../../../models/Customer';
import Form from '../../Form';
import PenaltyEditor from './PenaltyEditor';
import usePenaltyById from './usePenaltyById';
import useSavePenalty from './useSavePenalty';

type DiscountProps = {
    customer?: Customer;
}

export type PenaltyForm = {
  hours?: number;
  amount?: number;
  active?: boolean;
  customerId?: string;
}

export default function Penalty({ customer }: DiscountProps): JSX.Element {
    const { id: customerId } = useParams();
    const { data: penalty } = usePenaltyById(customer?.translationPenaltyId);
    const { mutate } = useSavePenalty(customer);

    const onSubmit = (form: PenaltyForm) => {
        mutate(form);
    };

    return (
        <Container>
            <Form<PenaltyForm> onSubmit={onSubmit} initialValues={penalty ? penalty : { hours: 0, amount: 0, active: false, customerId: customerId ?? '' }}>
                <PenaltyEditor />
            </Form>
        </Container>
    );
}
