import { useContext } from 'react';
import { FormattedAnnotation, AnnotatedPage, AnnotatedBlock } from './AnnotationTypes';
import { EditorContext } from './EditorContext';

export default function useEditor() {
    const { editorState, editorDispatch } = useContext(EditorContext);

    const discardEditor = () => {
        editorDispatch({
            type: 'DISCARD',
        });
    };

    const initEditor = (annotations: FormattedAnnotation) => {
        editorDispatch({
            type: 'INIT_EDITOR',
            payload: {
                annotations,
                selectedPage: 1,
                selectedPageData: annotations[0],
            },
        });
    };

    const setSelectedPage = (pageNumber: number) => {
        if (pageNumber > 0) {
            editorDispatch({
                type: 'SET_SELECTED_PAGE',
                payload: pageNumber,
            });
        }
    };

    const setSelectedPageData = (annotatedPage: AnnotatedPage) => {
        editorDispatch({
            type: 'SET_SELECTED_PAGE_DATA',
            payload: annotatedPage,
        });
    };

    const setSelectedBlockId = (blockId: string) => {
        editorDispatch({
            type: 'SET_SELECTED_BLOCK_ID',
            payload: blockId,
        });
    };

    const setSelectedScrollToId = (blockId: string) => {
        editorDispatch({
            type: 'SET_SELECTED_SCROLL_TO_ID',
            payload: blockId,
        });
    };

    const setTransformedData = (data: FormattedAnnotation) => {
        editorDispatch({
            type: 'SET_TRANSFORMED_DATA',
            payload: data,
        });
    };

    const toggleSidebar = (status: boolean) => {
        editorDispatch({ type: 'SET_SIDEBAR_STATUS', payload: status });
    };

    return {
        editorState,
        discardEditor,
        initEditor,
        setSelectedPage,
        setSelectedPageData,
        setSelectedBlockId,
        setSelectedScrollToId,
        setTransformedData,
        toggleSidebar,
    };
}
