import { Card, Skeleton } from '@mui/material';
import TreeMapMonoChrome from '../../../../../components/charts/TreeMapMonoChrome';
import { useQuery } from 'react-query';


type Response = {
  sourceLanguage: {
    name: string;
    id: string;
  };
  targetLanguage: {
    name: string;
    id: string;
  };
  numberOfTasks: number
}[]

const TopLanguagePairs: React.FC = () => {
    const { data } = useQuery<Response>('dashboard/languagepairs?offset=0&limit=10&sort=numberOfTasks&order=asc');

    const chartData = data?.map((item) => ({
        x: item.sourceLanguage.name + ' - ' + item.targetLanguage.name,
        y: item.numberOfTasks * 100,
    }));

    if (!chartData) {
        return <Card>
            <Skeleton height={400} variant={'rectangular'} />
        </Card>;
    }

    return <Card>
        <TreeMapMonoChrome
            // The minimum value the chart can handle is 100, so we need this multiplication and division
            valueFormatter={(value) => value / 100 + ''}
            title={'Top 10 requested language pairs'}
            chartData={chartData} />
    </Card>;
};

export default TopLanguagePairs;
