import { useQuery } from 'react-query';
import File from '../../../models/File';
import { FileType } from '../../../enums/FileType';

function useFilesByTask(fileType: FileType, taskId?: string) {
    return useQuery<File[]>(`/tasks/${taskId}/files`, {
        enabled: !!taskId,
        select(files) {
            return files?.filter((file) => file.type === fileType);
        },
    });
}

export default useFilesByTask;
