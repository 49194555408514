import { useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import useDeleteLanguagePair from './useDeleteLanguagePair';
import { Button, IconButton, Stack } from '@mui/material';
import LanguagePair from '../../../../models/LanguagePair';
import { LanguagePairForm } from './LanguagePairs';
import useLanguageOptions from './useLanguageOptions';
import SelectField from '../../../Form/fields/SelectField';

const LanguagePairEditor: React.FC = () => {
    const { data: options } = useLanguageOptions();
    const { mutate: deleteLanguage } = useDeleteLanguagePair();
    const { setFieldValue, values } = useFormikContext<LanguagePairForm>();

    const onAddLanguagePair = () => {
        const lp: LanguagePair = {
            id: uuidv4(),
            isNew: true,
        };

        setFieldValue('languagePairs', [...values.languagePairs, lp]);
    };


    function removeLanguageByIndex(lpIndex: number) {
        const targetLp = values.languagePairs[lpIndex];
        if (targetLp.isNew) {
            setFieldValue('languagePairs', values.languagePairs.filter((l, index) => index !== lpIndex));
        } else {
            deleteLanguage({ id: targetLp.id });
        }
    }

    return (
        <Stack>
            {values.languagePairs.map((lp, index) => (
                <Stack direction={'row'} spacing={2} key={lp.id} sx={{ my: 1 }}>
                    <SelectField name={`languagePairs[${index}].sourceLanguageId`} label={'Source'} options={options ?? []} />
                    <SelectField name={`languagePairs[${index}].targetLanguageId`} label={'Target'} options={options ?? []} />
                    <IconButton onClick={() => removeLanguageByIndex(index)}>
                        <Icon icon={trash2Outline} width={20} height={20} />
                    </IconButton>
                </Stack>
            ))}
            <Stack direction='column' gap={2} alignItems="center">
                <Button color="inherit"
                    startIcon={<Icon icon={plusFill} width={20} height={20} />}
                    onClick={onAddLanguagePair}
                >
                    Add language pair
                </Button>
                <Button type={'submit'} variant={'contained'}>Save</Button>
            </Stack>

        </Stack>
    );
};

export default LanguagePairEditor;
