import CheckboxField from '../../../Form/fields/CheckboxField';
import { Card, Grid } from '@mui/material';
import useMe from '../../../../hooks/useMe';

const DeclareCorrectInformation: React.FC = () => {
    const { data: me } = useMe();

    if (me?.isAdmin) {
        return null;
    }

    return <>
        <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
                <CheckboxField name={'isDataCorrect'}
                    label={'I hereby declare that the information\n' +
                                   'provided in this form is correct.'} />
            </Card>
        </Grid>
    </>;
};

export default DeclareCorrectInformation;
