import { TableCell, TableRow, TableHead as MuiTableHead, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import getFilterIcon from './getFilterIcon';
import * as React from 'react';
import Column from '../Column';
import SortModel from '../SortModel';
import useQueryString from '../../../hooks/useQueryString';


type TableHeadProps<T> = {
    columns: Column<T>[]
    sortModel?: SortModel<T>
    expandedComponent?: (row: T) => React.ReactNode
}

function TableHead<T>({ columns, sortModel, expandedComponent }: TableHeadProps<T>) {
    const [query, setQuery] = useQueryString();

    const onColumnClick = (column: Column<T>) => {
        if (!query['sort'] || query['sort'] !== column.id) {
            setQuery({ 'sort': column.id, 'order': 'ASC' });
        }

        if (query['sort'] === column.id) {
            if (query['order'] === 'ASC') {
                setQuery({ 'order': 'DESC' });
            }
            if (query['order'] === 'DESC') {
                setQuery({ 'order': '', 'sort': '' });
            }
        }
    };

    return <MuiTableHead>
        <TableRow>
            {expandedComponent && <TableCell sx={{ width: 30, p: 0 }} />}
            {columns.map((column) =>
                !column.isHidden &&
                    <TableCell
                        align={column.align || 'left'}
                        onClick={() => column.isClickable && onColumnClick(column)}
                        sx={{
                            width: column.width,
                            color: column.isClickable ? 'primary.main' : 'text.primary',
                            cursor: column.isClickable ? 'pointer' : 'default',
                        }}
                        key={column.id.toString()}>

                        {column.isClickable ?
                            <Button
                                variant={'text'}
                                sx={{ fontSize: 12, pl: 0, justifyContent: 'flex-start' }}
                                endIcon={getFilterIcon(column.id, sortModel?.sort, sortModel?.order )}>
                                {column.label}
                            </Button> :
                            <Typography noWrap fontSize={12} fontWeight={700}>{column.label}</Typography>}

                    </TableCell>)}
        </TableRow>
    </MuiTableHead>;
}

export default TableHead;
