import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { API } from '../../../utils/ApiClient';

function useAddHolidays() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ holidays }: { holidays: {date: string}[] }) => API.post('translator-holidays', holidays),
        onSuccess: () => {
            enqueueSnackbar('Holiday(s) added successfully', { variant: 'success' });
            queryClient.invalidateQueries('translator-holidays');
        },
    });
}


export default useAddHolidays;
