import { useMutation } from 'react-query';
import { GrantType } from '../../enums/GrantType';
import { LoginForm } from './LoginForm';
import { API, ApiURL } from '../../../utils/ApiClient';
import TokenHelper from '../../../utils/TokenHelper';
import LocalStorageManager from '../../../utils/LocalStorageManager';
import { useNavigate } from 'react-router';
import { UserRole } from '../../enums/UserRole';
import axios from 'axios';

function useLogin() {
    const navigate = useNavigate();
    return useMutation<{ data: any }, unknown, { form: LoginForm }>({
        mutationFn: (data) => axios.post(`${ApiURL}/oauth/token${data.form.rememberMe ? '?keepMeLoggedIn=true' : ''}`, {
            grant_type: GrantType.PASSWORD,
            username: data.form.email,
            password: data.form.password,
        }),
        onSuccess: async (res) => {
            TokenHelper.setBoth(res.data);
            const user = await API.get('/me');
            await LocalStorageManager.setUser(user.data);
            const redirect = user.data.role === UserRole.ADMIN ? '/dashboard' : '/tasks';
            navigate(redirect);
        },
    });
}

export default useLogin;

