import React from 'react';
import { Box, CircularProgress } from '@mui/material';


const TaskLoading: React.FC = () => {
    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
    );
};

export default TaskLoading;
