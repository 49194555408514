import { useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import columns from './getColumns';
import ConnectedTable from '../../ConnectedTable';
import User from '../../../models/User';
import DialogAnimate from '../../animate/DialogAnimate';
import EmployeeForm from './EmployeeForm';
import { Box, Button, Container, DialogTitle, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

export default function Employees(): JSX.Element {
    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Container>
            <Stack direction='row' justifyContent={'flex-end'} sx={{ m: 2 }}>
                <Box textAlign='center'>
                    <Button
                        variant="contained"
                        onClick={() => setIsOpen(true)}
                        startIcon={<Icon icon={plusFill} />}
                    >
                        New employee
                    </Button>
                </Box>
            </Stack>
            <ConnectedTable<User>
                queryKey={`users`}
                idKey={'id'}
                defaultFilters={{ customerId: id ?? '' }}
                columns={columns}/>
            <DialogAnimate open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>New user</DialogTitle>
                <EmployeeForm onClose={() => setIsOpen(false)} id={id ?? ''} />
            </DialogAnimate>
        </Container>
    );
}
