import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import User from '../../models/User';

function useParticipant() {
    const { userId } = useParams();
    return useQuery<User>('users/' + userId, { staleTime: Infinity });
}

export default useParticipant;
