import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import TextField from '../../../components/Form/fields/TextField';
import React from 'react';

const PostalAddressEditor: React.FC<{ cannotEdit?: boolean }> = ({ cannotEdit }) => {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}>
                <Typography variant='subtitle2' color='text.secondary'>Postal Address</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={2}>
                    <TextField label={'Name'} name={'postalAddress.name'} disabled={cannotEdit} />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField label={'ZIP'} name={'postalAddress.zip'} disabled={cannotEdit} />
                        <TextField label={'Country'} name={'postalAddress.country'} disabled={cannotEdit} />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField label={'State'} name={'postalAddress.state'} disabled={cannotEdit} />
                        <TextField label={'City'} name={'postalAddress.city'} disabled={cannotEdit} />
                    </Stack>
                    <TextField label={'Address'} name={'postalAddress.address'} disabled={cannotEdit} />
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};

export default PostalAddressEditor;
