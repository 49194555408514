import { Card, CardHeader } from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';
import useCustomers from '../../../../../hooks/useCustomers';
import HorizontalBarChart from '../../../../../components/charts/HorizontalBarChart';

const TopCustomers: React.FC = () => {
    const { data: customers } = useCustomers('order=DESC&sort=translatedPageNumber&limit=5&offset=0');

    const chartData = useMemo(() => {
        return customers?.reduce<{ data: number[]; categories: string[] }>((acc, curr) => {
            acc.data.push(curr.translatedPageNumber ?? 0);
            acc.categories.push(curr.name ?? 'Missing name');
            return acc;
        }, { data: [], categories: [] });
    }, [customers]);

    return (
        <Card>
            <CardHeader title="Translated pages by customer" subheader="Top 5" />
            <HorizontalBarChart
                data={chartData?.data ?? []}
                categories={chartData?.categories ?? []} />
        </Card>
    );
};

export default TopCustomers;
