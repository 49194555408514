/* eslint-disable indent */
import React from 'react';
import { Box, Chip, CircularProgress, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import Task from '../../../../models/Task';
import { useQuery } from 'react-query';
import PriceInfo from '../../../../models/PriceInfo';
import useMe from '../../../../hooks/useMe';
import { UserRole } from '../../../../enums/UserRole';

const PricingComponent: React.FC = () => {
    const { data: me } = useMe();
    const { values: task } = useFormikContext<Task>();
    const { data: priceInfos } = useQuery<PriceInfo>(`tasks/${task?.id}/priceinfos/get`);

    return (
        <>
            {priceInfos && priceInfos.status === 'parsed' ? (
                <>
                    <List>
                        <Stack direction={'row'} sx={{ width: 300 }}>
                            <ListItem>
                                <ListItemText primary="Characters" secondary={priceInfos?.fileCharacters} primaryTypographyProps={{
                                    variant: 'subtitle2', sx: {
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    },
                                }}
                                    secondaryTypographyProps={{ variant: 'subtitle2' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Characters/Page" secondary={priceInfos?.charactersPerPage} primaryTypographyProps={{
                                    variant: 'subtitle2', sx: {
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    },
                                }}
                                    secondaryTypographyProps={{ variant: 'subtitle2' }} />
                            </ListItem>
                        </Stack>
                        <Stack direction={'row'} sx={{ width: 300 }}>
                            <ListItem>
                                <ListItemText primary="Pages" secondary={priceInfos?.pages} primaryTypographyProps={{
                                    variant: 'subtitle2', sx: {
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    },
                                }}
                                    secondaryTypographyProps={{ variant: 'subtitle2' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Price/Page" secondary={`${priceInfos?.price} €`} primaryTypographyProps={{
                                    variant: 'subtitle2', sx: {
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    },
                                }}
                                    secondaryTypographyProps={{ variant: 'subtitle2' }} />
                            </ListItem>
                        </Stack>
                    </List>
                    <Stack sx={{ my: 1 }}>
                        <Typography>Total:</Typography>
                        <Chip label={`${priceInfos?.totalPrice} €`} color="info" />
                    </Stack>
                </>
            ) : priceInfos && priceInfos.status === 'error' ? (
                <Stack sx={{ width: 220, mt: 2 }} gap={1}>
                    <Chip label={`Error occured during calculation`} color="error" />
                    {me && me.role === UserRole.ADMIN && (
                        <Typography sx={{ color: 'text.secondary', mb: 5 }} variant='caption'>
                            {`Autotranslation file or character counting error. Please check Language or Customer's Pricing options if Autotranslation exists.`}
                        </Typography>
                    )}
                </Stack>
            ) : (
                <Box sx={{ my: 2 }}>
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};

export default PricingComponent;

