import { Typography } from '@mui/material';
import React from 'react';
import Task from '../../../../models/Task';

const TotalPrice: React.FC<{ tasks: Task[] }> = ({ tasks }) => {
    const totalPrice = tasks.reduce((sum, task) => {
        if (task.translatorPrice) return sum += +task.translatorPrice;
        return sum;
    }, 0);

    return <Typography noWrap fontSize={12} mr={0.5}>{totalPrice} €</Typography>;
};

export default TotalPrice;
