import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { API } from '../../../utils/ApiClient';
import Discount from '../../../models/Discount';


function useSaveDiscounts() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ discounts }: {discounts: Discount[]}) => API.post('/discounts/bulkcreate', discounts),
        onSuccess() {
            enqueueSnackbar('Discounts saved', {
                variant: 'success',
            });
            queryClient.invalidateQueries(`discounts`);
        },
    });
}


export default useSaveDiscounts;
