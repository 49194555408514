import { useQuery } from 'react-query';
import File from '../../../models/File';
import { FileType } from '../../../enums/FileType';

function useGetTranslatedFile(taskId?: string) {
    return useQuery<File[]>(`/tasks/${taskId}/files`, {
        refetchOnWindowFocus: false,
        enabled: false,
        select(files) {
            const translatedFiles = files?.filter((file) => file.type === FileType.TRANSLATED);
            if (translatedFiles && translatedFiles.length > 0) return translatedFiles;
            return files;
        },
    });
}

export default useGetTranslatedFile;
