function getAccessToken() {
    try {
        const accessToken = localStorage.getItem('access_token') ?? '';
        const token = JSON.parse(accessToken);
        return token.value;
    } catch (error) {
        return '';
    }
}

export default getAccessToken;
