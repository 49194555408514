import { QueryFunction } from 'react-query';
import { API } from './ApiClient';

/**
 * Default query function can be overriden on a per query basis
 * By default queryKeys will be used as path.
 */
const defaultQueryFn: QueryFunction<any, any> = async ({ queryKey }) => {
    const path = queryKey.join('');
    const response = await API.get(path);
    return response.data;
};

export default defaultQueryFn;
