import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../../utils/ApiClient';
import Billing from '../../../../models/BillingDetails';
import { useSnackbar } from 'notistack';

function useCreateBillingDetails() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: ({ billing }: {billing: Billing}) => API.post('/billing-details', billing),
        onSuccess() {
            queryClient.invalidateQueries('billing');
            enqueueSnackbar('Billing details created successfully', { variant: 'success' });
        },
    });
}

export default useCreateBillingDetails;
