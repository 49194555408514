import { useFormikContext } from 'formik';
import TextField from '../../Form/fields/TextField';
import { Button, Card, CardContent, Stack } from '@mui/material';
import useMe from '../../../hooks/useMe';
import { PenaltyForm } from './Penalty';


const PenaltyEditor = () : JSX.Element => {
    const { data: me } = useMe();
    const { setFieldValue, values } = useFormikContext<PenaltyForm>();

    const handleChangeStatus = () => {
        setFieldValue('active', !values.active);
    };


    return (
        <Stack>
            <Card sx={{ mb: 1, border: values.active ? 1 : 0, borderColor: 'primary.main' }}>
                <CardContent>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField label={'Hours'} name={`hours`} disabled={!me?.isAdmin} />
                        <TextField label={'Amount'} name={`amount`} disabled={!me?.isAdmin} />
                        <Button onClick={handleChangeStatus} disabled={!me?.isAdmin}>
                            {values.active ? 'Deactivate' : 'Activate'}
                        </Button>
                    </Stack>
                </CardContent>
            </Card>
            {me?.isAdmin && (
                <Stack direction='column' alignItems='center'>
                    <Button type={'submit'} variant={'contained'}>Save</Button>
                </Stack>
            )}

        </Stack>
    );
};

export default PenaltyEditor;
