import { useQuery } from 'react-query';
import { Typography } from '@mui/material';
import Language from '../../../../models/Language';

function useLanguageOptions() {
    return useQuery<any>('languages', {
        select(res) {
            return res.map((language: Language) => ({
                id: language.id ?? 'id missing',
                name: (
                    <Typography>
                        {language.name}
                    </Typography>
                ),
            }));
        },
    });
}

export default useLanguageOptions;
