import React, { useMemo } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useField } from 'formik';
import useRemoteOptions from './useRemoteOptions';

const SelectRemoteOptions: React.FC<{ name: string; label: string; queryKey: string, disabled?: boolean }> = ({ name, label, queryKey, disabled }) => {
    const [field, meta] = useField(name);
    const { data: options, isLoading } = useRemoteOptions(queryKey);

    return useMemo(() => {
        const hasError = Boolean(meta.touched && meta.error);
        const helperText = hasError && meta.error;
        const hasOptions = options && options?.length > 0;

        return (
            <TextField
                select
                fullWidth
                size={'small'}
                name={name}
                error={hasError}
                helperText={helperText}
                value={field.value ?? ''}
                label={label}
                onChange={field.onChange}
                disabled={disabled ?? false}
            >
                {isLoading && (
                    <MenuItem value={''}>
                        Loading options
                    </MenuItem>
                )}

                {hasOptions && options?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}

                {!hasOptions && (
                    <MenuItem value={''}>
                        No options available
                    </MenuItem>
                )}
            </TextField>
        );
    }, [field.onChange, field.value, isLoading, label, meta.error, meta.touched, name, options, disabled]);
};

export default SelectRemoteOptions;
