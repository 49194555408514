import { useMutation } from 'react-query';
import { API } from '../../../utils/ApiClient';
import { useSnackbar } from 'notistack';
import { MIconButton } from '../../@material-extend';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import React from 'react';
import ChangePasswordForm from './ChangePasswordForm';


function useChangePassword(userId?: string) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    return useMutation<{ status: number }, unknown, ChangePasswordForm, unknown>({
        mutationFn: (form) => API.put(`/users/${userId}/changepassword`, form),
        onSuccess(res) {
            enqueueSnackbar(res.status === 204 ? 'Password saved' : 'Error occurred', {
                variant: res.status === 204 ? 'success' : 'error',
                action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                ),
            });
        },
        onError() {
            enqueueSnackbar('Error occurred', { variant: 'error' });
        },
    });
}


export default useChangePassword;
