import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../utils/ApiClient';

function useSeenTask() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }: { id?: string }) => API.put(`/tasks/${id}/seen`),
        onSuccess() {
            queryClient.invalidateQueries('tasks');
            queryClient.invalidateQueries('tasks/list/unseen');
        },
    });
}

export default useSeenTask;
