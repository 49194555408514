import { Button, Card, Container, Stack } from '@mui/material';
import PageHeader from '../../../components/PageHeader';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-eva/plus-fill';
import ConnectedTable from '../../../components/ConnectedTable';
import Page from '../../../components/Page';
import React from 'react';
import Customer from '../../../models/Customer';
import moment from 'moment';
import Filters from './Filters';
import { API } from '../../../utils/ApiClient';
import fileDownload from 'js-file-download';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import columns from './columns';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import { v4 as uuidv4 } from 'uuid';
const UserList: React.FC = () => {
    const { isCollapse } = useCollapseDrawer();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: addCustomer } = useMutation<{data: { id: string }}, unknown, unknown>({

        mutationFn: () => {
            const newCustomer: Customer = {
                id: uuidv4(),
            };
            return API.post('customers', newCustomer);
        },
        onSuccess(res) {
            enqueueSnackbar('Customer created');
            navigate('/customers/' + res.data.id);
        },
    });

    const exportPrices = async () => {
        const res = await API.get('/export/language-prices', { responseType: 'blob' });
        return fileDownload(res.data, `Customer_Prices_${moment().format('YYYYMMDDHHmm')}.xlsx`);
    };


    return (
        <Page title={'Customers'}>
            <Container maxWidth={isCollapse ? false : 'lg'}>
                <PageHeader
                    title={'Customers'}
                    actions={
                        <Stack direction={'row'} gap={1}>
                            <Button color={'secondary'} onClick={exportPrices} startIcon={<Icon icon={add}/>} variant={'contained'}>Export Customer Prices</Button>
                            <Button onClick={addCustomer} startIcon={<Icon icon={add}/>} variant={'contained'}>New Customer</Button>
                        </Stack>
                    } />
                <Card>
                    <Filters />
                    <Stack px={2}>
                        <ConnectedTable<Customer>
                            queryKey={'customers'}
                            idKey={'id'}
                            columns={columns}/>
                    </Stack>
                </Card>
            </Container>
        </Page>
    );
};

export default UserList;
