import * as React from 'react';
import { Icon } from '@iconify/react';
import infoOutline from '@iconify/icons-eva/info-outline';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import useCustomers from '../../../../hooks/useCustomers';

export default function CodeInfo() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const { data: customers } = useCustomers();

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <Icon icon={infoOutline} />
            </IconButton>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {customers && customers.map((customer) => (
                    <Typography sx={{ p: 1 }} variant='subtitle2' key={customer.id}>{customer.code ?? 'n/a'}{' - '}{customer.name ?? 'n/a'}</Typography>
                ))}
            </Popover>
        </>
    );
}

