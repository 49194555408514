import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';
import { useParams } from 'react-router-dom';

function useSendMessage() {
    const queryClient = useQueryClient();
    const { userId } = useParams();

    return useMutation({
        mutationFn: ({ message }: { message: string }) => API.post('conversations/' + userId, { body: message }),
        onSuccess() {
            queryClient.invalidateQueries('conversations?name=');
            queryClient.invalidateQueries('messages/' + userId);
        },
    });
}

export default useSendMessage;
