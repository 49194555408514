import { useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { useQueryClient } from 'react-query';

function useAutomaticPriceCalculationUpdate(socket: Socket | null) {
    const queryClient = useQueryClient();

    useEffect(() => {
        socket?.on(
            'AUTOMATIC_PRICE_CALCULATION_UPDATE',
            (message: {parseStatus: any, taskId: string}) => {
                queryClient.invalidateQueries(`tasks/${message.taskId}/priceinfos/get`);
            });

        return () => {
            socket?.off('AUTOMATIC_PRICE_CALCULATION_UPDATE');
        };
    }, [queryClient, socket]);
}

export default useAutomaticPriceCalculationUpdate;
