/* eslint-disable quote-props */
import { Box, Drawer } from '@mui/material';
import { FormattedAnnotation } from './AnnotationTypes';
import DocumentPage from './DocumentPage';
import useEditor from './useEditor';
import { useEffect, useState } from 'react';
import DocumentPager from './DocumentPager';
import Actions from './Actions';
import EditorSidebar from './EditorSidebar';
import { styled } from '@mui/material/styles';

const drawerWidth = 300;

type PropTypes = {
    annotations: FormattedAnnotation;
};

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const LiveEditorWrapper = ({ annotations }: PropTypes) => {
    const {
        initEditor,
        discardEditor,
        toggleSidebar,
        editorState: { sidebarStatus },
    } = useEditor();

    useEffect(() => {
        initEditor(annotations);

        return () => {
            discardEditor();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [annotations]);

    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,

                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant='persistent'
                anchor='left'
                open={sidebarStatus}
            >
                <EditorSidebar />
            </Drawer>
            <Main open={sidebarStatus}>
                <Actions
                    open={sidebarStatus}
                    handleOpen={() => toggleSidebar(true)}
                    handleClose={() => toggleSidebar(false)}
                />
                <DocumentPager />
                <DocumentPage />
            </Main>
        </Box>
    );
};

export default LiveEditorWrapper;
