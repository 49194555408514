import { useQuery } from 'react-query';
import createQueryString from '../../../utils/createQueryString';
import { Typography } from '@mui/material';
import Task from '../../../models/Task';
import Priority from '../../../models/Priority';

function usePriorityOptions(task: Task) {
    return useQuery<any>(createQueryString('priorities', { customerId: task.customerId }), {
        enabled: !!task.customerId,
        select(res) {
            return res.map((priority: Priority) => ({
                id: priority.id ?? 'id missing',
                name: <Typography>
                    {priority.name}
                </Typography>,
            }));
        },
    });
}

export default usePriorityOptions;
