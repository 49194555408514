import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { useMutation, useQueryClient } from 'react-query';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-eva/plus-fill';
import TextField from '../../../Form/fields/TextField';
import DatePicker from '../../../Form/fields/DatePicker';
import { API } from '../../../../utils/ApiClient';
import FormDialog from '../../../FormDialog';

const AddNewHoliday: React.FC = () => {
    const queryClient = useQueryClient();
    const { mutate: addNewHoliday } = useMutation({
        mutationFn: (holiday?: { name: string, date: string }) => API.post('holidays', holiday),
        onSuccess() {
            queryClient.invalidateQueries('holidays');
        },
    });

    return (
        <>
            <FormDialog
                content={<Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField name={'name'} label={'Name'} />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker name={'date'} label={'Date'} />
                    </Grid>
                </Grid>}
                onSubmit={(holiday) => addNewHoliday(holiday)}
                title={'Update holiday'}
                initialValues={{ name: '', date: '' }}
                submitLabel={'Save'}
                trigger={<Button startIcon={<Icon icon={add} />} variant={'contained'}>new holiday</Button>} />
        </>
    );
};

export default AddNewHoliday;
