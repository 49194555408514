import React from 'react';
import { Stack } from '@mui/material';
import SelectRemoteOptions from '../../../components/Form/fields/SelectRemoteOptions';
import RadioField from '../../../components/Form/fields/RadioField';
import { surenessOptions } from '../../../enums/Sureness';
import TranslatorSelector from '../TranslatorSelector';

const TaskDetailEditor: React.FC<{ showTranslationOptions?: boolean, disabled?: boolean }> = ({ showTranslationOptions, disabled }) => {
    return (
        <Stack spacing={2}>
            <Stack direction={'row'} spacing={2}>
                <SelectRemoteOptions label={'From'} name={'sourceLanguageId'} queryKey={'languages'} disabled={disabled} />
                <SelectRemoteOptions label={'To'} name={'targetLanguageId'} queryKey={'languages'} disabled={disabled} />
            </Stack>
            <RadioField name={'sureness'} label={'How sure are you about the languages?'} options={surenessOptions} disabled={disabled} />
            {showTranslationOptions && (
                <TranslatorSelector />
            )}
        </Stack>
    );
};

export default TaskDetailEditor;
