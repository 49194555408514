import merge from 'lodash/merge';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, CardHeader, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import getBaseOptionChart from '../../theme/getBaseOptionChart';
import ChartData from './ChartData';
import { ApexOptions } from 'apexcharts';


export type AreaChartMonthlyBreakDownType = {
    chartData: ChartData
    title: string
    subTitle?: string
    customLegendItems: string[]
    valueFormatter?: (value: number) => string | number
}

const AreaChartMonthlyBreakDown: React.FC<AreaChartMonthlyBreakDownType> = ({ chartData, valueFormatter, title, subTitle, customLegendItems }) => {
    const [seriesData, setSeriesData] = useState(2022);
    const theme = useTheme();

    const handleChangeSeriesData = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSeriesData(Number(event.target.value));
    };

    const chartOptions: ApexOptions = merge(getBaseOptionChart(theme), {
        legend: { position: 'top', horizontalAlign: 'right', customLegendItems },
        yaxis: {
            labels: {
                formatter: valueFormatter,
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
    });


    return <>
        <CardHeader
            title={title}
            subheader={subTitle}
            action={
                <TextField
                    select
                    fullWidth
                    value={seriesData}
                    SelectProps={{ native: true }}
                    onChange={handleChangeSeriesData}
                    sx={{
                        '& fieldset': { border: '0 !important' },
                        '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
                        '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
                        '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 },
                    }}
                >
                    {chartData.map((option) => (
                        <option key={option.year} value={option.year}>
                            {option.year}
                        </option>
                    ))}
                </TextField>
            }
        />

        {chartData
            .filter((item) => item.year === seriesData)
            .map((item) => (
                <Box key={item.year} sx={{
                    'mt': 3, 'mx': 3,
                    '& .apexcharts-tooltip.apexcharts-theme-light': {
                        backgroundColor: theme.palette.background.paper,
                    } }} dir="ltr">
                    <ReactApexChart type="area" series={item.data} options={chartOptions} height={364} />
                </Box>
            ))}
    </>;
};

export default AreaChartMonthlyBreakDown;
