import React, { useMemo } from 'react';
import { TextField as MuiTextField, TextFieldProps as BaseTextFieldProps } from '@mui/material';
import { useField } from 'formik';


type TextFieldProps = { name: string; label: string, disabled?: boolean } & BaseTextFieldProps

const TextField: React.FC<TextFieldProps> = ({ name, label, disabled, variant, ...props }) => {
    const [field, meta] = useField(name);

    return useMemo(() => {
        const hasError = Boolean(meta.touched && meta.error);
        const helperText = hasError && meta.error;

        return <MuiTextField
            fullWidth
            size={'small'}
            name={name}
            label={label}
            value={field.value ?? ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
            type={'text'}
            error={hasError}
            helperText={helperText}
            disabled={disabled ?? false}
            variant={variant ?? 'outlined'}
            {...props}
        />;
    }, [meta.touched, meta.error, name, label, field.value, field.onChange, field.onBlur, disabled, variant, props]);
};

export default TextField;
