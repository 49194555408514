import { Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import Form from '../../Form';
import PasswordField from '../../Form/fields/PasswordField';
import validationSchema from './validationSchema';
import ChangePasswordForm from './ChangePasswordForm';
import initialValues from './initialValues';
import useChangePassword from './useChangePassword';


const ChangePassword: React.FC<{ userId?: string }> = ({ userId }) => {
    const { mutate: changePassword } = useChangePassword(userId);
    const onSubmit = (form: ChangePasswordForm) => {
        changePassword(form);
    };

    return (
        <Card sx={{ p: 3 }}>
            <Form<ChangePasswordForm>
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                initialValues={initialValues}>
                <Stack spacing={3} alignItems="flex-end">
                    <PasswordField name={'currentPassword'} label={'Current password'}/>
                    <PasswordField name={'newPassword'} label={'New password'}/>
                    <PasswordField name={'newPasswordAgain'} label={'Repeat password'}/>
                    <LoadingButton type="submit" variant="contained">
                          Save
                    </LoadingButton>
                </Stack>
            </Form>
        </Card>
    );
};

export default ChangePassword;
