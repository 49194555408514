import React from 'react';
import Column from '../../../components/ConnectedTable/Column';
import User from '../../../models/User';
import Company from './customFields/Company';
import Status from './customFields/Status';
import Actions from './customFields/Actions';
import { UserRole } from '../../../enums/UserRole';

const getColumns = (role: UserRole): Column<User>[] => [
    {
        getter(row): string | React.ReactNode {
            return row.firstname + ' ' + row.lastname;
        },
        id: 'id',
        label: 'Name',
    },
    {
        id: 'email',
        label: 'E-mail',
    },
    {
        id: 'role',
        label: 'Role',
        width: 100,
    },
    {
        id: 'company',
        label: 'Company',
        isHidden: role === UserRole.LINGUIST,
        getter(user) {
            return <Company user={user} />;
        },
    },

    {
        id: 'active',
        label: 'Status',
        isHidden: role === UserRole.LINGUIST,
        width: 120,
        getter(user) {
            return <Status user={user} />;
        },
    },

    {
        id: 'lastname',
        label: '',
        width: 60,
        getter(user) {
            return <Actions user={user} />;
        },
    },
];


export default getColumns;
