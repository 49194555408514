import { useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import TextField from '../../Form/fields/TextField';
import { Button, IconButton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import useMe from '../../../hooks/useMe';
import { BonusForm } from './Bonuses';
import TranslationBonus from '../../../models/TranslationBonus';
import useDeleteBonus from './useDeleteBonus';
import useCustomerPriorities from './useCustomerPriorities';
import SelectField from '../../Form/fields/SelectField';


const BonusEditor = () : JSX.Element => {
    const { data: me } = useMe();
    const { id: customerId } = useParams();
    const { mutate: deleteBonus } = useDeleteBonus();
    const { setFieldValue, values } = useFormikContext<BonusForm>();
    const { data: priorities } = useCustomerPriorities(customerId);

    const onAddBonus = () => {
        const bonus: TranslationBonus = {
            id: uuidv4(),
            amount: 0,
            hours: 0,
            customerId: customerId,
            isNew: true,
        };

        setFieldValue('bonuses', [...values.bonuses, bonus]);
    };


    const removeBonusByIndex = (index: number) => {
        const target = values.bonuses[index];
        if (target.isNew) {
            setFieldValue('bonuses', values.bonuses.filter((l, i) => i !== index));
        } else {
            deleteBonus({ id: target.id });
        }
    };


    return (
        <Stack>
            {values.bonuses.map((bonus, index) => (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} key={index} sx={{ my: 1 }}>
                    <Stack sx={{ minWidth: 220 }}>
                        <SelectField label={'Priority'} options={priorities ?? []} name={`bonuses[${index}].priorityId`} disabled={!me?.isAdmin} />
                    </Stack>
                    <TextField label={'Hours'} name={`bonuses[${index}].hours`} disabled={!me?.isAdmin} />
                    <TextField label={'Amount'} name={`bonuses[${index}].amount`} disabled={!me?.isAdmin} />
                    <IconButton onClick={() => removeBonusByIndex(index)} disabled={!me?.isAdmin}>
                        <Icon icon={trash2Outline} width={20} height={20} />
                    </IconButton>
                </Stack>
            ))}
            {me?.isAdmin && (
                <Stack direction='column' gap={1} alignItems='center'>
                    <Button color="inherit"
                        startIcon={<Icon icon={plusFill} width={20} height={20}/>}
                        onClick={onAddBonus}
                    >
                        Add Bonus
                    </Button>
                    <Button type={'submit'} variant={'contained'}>Save</Button>
                </Stack>
            )}

        </Stack>
    );
};

export default BonusEditor;
