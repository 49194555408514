import { Card, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import RadioField from '../../../components/Form/fields/RadioField';
import SwitcherField from '../../../components/Form/fields/SwitcherField';
import { styleOptions } from '../../../enums/TranslationStyleType';
import useMe from '../../../hooks/useMe';
import Task from '../../../models/Task';
import PrioritySelector from './PrioritySelector';

const Options: React.FC = () => {
    const { values: task, setFieldValue } = useFormikContext<Task>();
    const { data: me } = useMe();

    useEffect(() => {
        if (me && me.customerId) {
            setFieldValue('customerId', me.customerId );
        } else return;
    }, [me, setFieldValue]);

    return (
        <Card sx={{ p: 2 }}>
            <Stack spacing={2}>
                <Typography variant='subtitle2' gutterBottom>
                    Translation Settings
                </Typography>
                <Stack sx={{ m: 1 }} gap={2}>
                    {task.customerId ? (
                        <PrioritySelector />
                    ) : null}
                    <RadioField name={'style'} label={'Task Type'} options={styleOptions} />
                    <Stack gap={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <SwitcherField label={'Hard Copy Required'} name={'hardCopyRequired'} checked />
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    );
};

export default Options;
