import OriginalDocument from './OriginalDocument';
import { Box, Button, Container, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import TranslatedDocument from './TranslatedDocument';
import { useState } from 'react';
import useEditor from './useEditor';
import { useSnackbar } from 'notistack';
import { AnnotatedPage, FormattedAnnotation } from './AnnotationTypes';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentEditor = () => {
    const {
        setTransformedData,
        setSelectedPage,
        setSelectedPageData,
        editorState: { selectedPage, transformedData, selectedPageData },
    } = useEditor();
    const [show, setShow] = useState<'translated' | 'both' | 'original'>('translated');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const deletePage = () => {
        if (transformedData.length === 1) {
            enqueueSnackbar('Page cannot be deleted because only one page belongs to the document', {
                variant: 'error',
            });
            return false;
        }

        const newFormattedData: FormattedAnnotation = transformedData.filter(
            (page, index) => index + 1 !== selectedPage,
        );
        setTransformedData(newFormattedData);
        setSelectedPage(newFormattedData.length);
        setSelectedPageData(newFormattedData[newFormattedData.length - 1]);
    };

    const newPage = () => {
        const newPage: AnnotatedPage = {
            blocks: [],
            characters: 0,
            height: selectedPageData?.height || 0,
            width: selectedPageData?.width || 0,
            language: selectedPageData?.language || 'en',
        };

        const newTransformedData: FormattedAnnotation = [...transformedData, newPage];

        setTransformedData(newTransformedData);
        setSelectedPage(newTransformedData.length);
        setSelectedPageData(newPage);
    };

    return (
        <Container>
            <Stack position={'relative'} direction={'row'} justifyContent='center' spacing={3} mb={4}>
                <Tooltip title={'Show original file'}>
                    <IconButton
                        color={show === 'original' ? 'primary' : 'default'}
                        onClick={() => setShow('original')}
                        size='small'
                    >
                        Original
                    </IconButton>
                </Tooltip>

                <Tooltip title={'Show translated file'}>
                    <IconButton
                        color={show === 'translated' ? 'primary' : 'default'}
                        onClick={() => setShow('translated')}
                        size='small'
                    >
                        Translated
                    </IconButton>
                </Tooltip>

                <Stack
                    sx={{
                        position: 'absolute',
                        right: 0,
                    }}
                    position={'relative'}
                    direction={'row'}
                    justifyContent='center'
                    spacing={3}
                >
                    <Button variant='contained' color={'error'} size='small' onClick={deletePage}>
                        Delete page
                    </Button>

                    <Button variant='contained' color={'primary'} size='small' onClick={newPage}>
                        New page
                    </Button>
                </Stack>
            </Stack>

            <Box>
                <Box maxWidth={800} mx='auto'>
                    {show === 'original' && <OriginalDocument show={show} />}
                    {show === 'translated' && <TranslatedDocument show={show} />}
                </Box>
            </Box>
        </Container>
    );
};

export default DocumentEditor;
