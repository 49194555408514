import FormDialog from '../../FormDialog';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-eva/plus-fill';
import DateRangePicker from '../../Form/fields/DateRangePicker';
import moment from 'moment';
import * as Yup from 'yup';
import createDateArrayFromRange from '../../../utils/createDateArrayFromRange';
import useAddHolidays from './useAddHolidays';

const AddNewHoliday: React.FC = () => {
    const { mutate: addNewHolidays } = useAddHolidays();

    return <FormDialog
        validationSchema={Yup.object().shape({
            from: Yup.string().required('Start date required (can be same as end date)'),
            to: Yup.string().required('End date required, can be same as start date'),
        })}
        onSubmit={(form) => {
            const holidays = createDateArrayFromRange(new Date(form?.from ?? ''), new Date(form?.to ?? ''));
            addNewHolidays({ holidays: holidays.map((date) => ({
                date: moment(date).format('YYYY-MM-DD'),
            })) });
        }}
        content={<Grid container spacing={1}>
            <Grid item xs={12}>
                <DateRangePicker disablePast lowerBoundName={'from'} upperBoundName={'to'} lowerBoundLabel={'From'} upperBoundLabel={'To'} />
            </Grid>
        </Grid>}
        title={'Save holiday'}
        initialValues={{ from: '', to: '' }}
        submitLabel={'Save'}
        trigger={<Button startIcon={<Icon icon={add}/>}
            variant={'contained'}>new holiday</Button>} />;
};

export default AddNewHoliday;
