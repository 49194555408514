import { Container, Grid, Typography } from '@mui/material';
import TaskStatistics from './widgets/TaskStatistics';
import Page from '../../../components/Page';
import RevenueAndExpenditure from './widgets/RevenueAndExpenditure';
import TopLanguagePairs from './widgets/TopLanguagePairs';
import TopCustomers from './widgets/TopCustomers';
import KeyFigures from './KeyFigures';
import useMe from '../../../hooks/useMe';
import TranslatorStatistics from './widgets/TranslatorStatistics';

const Dashboard: React.FC = () => {
    const { data: me } = useMe();
    return (
        <Page title="Dashboard | WeTranslate">
            <Container maxWidth={'lg'}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'h4'} mb={1}>Hello {me?.firstname ?? 'user'}, welcome back!</Typography>
                    </Grid>
                    <Grid container item spacing={2} xs={12}>
                        <KeyFigures />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TopCustomers />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TaskStatistics />
                    </Grid>
                    <Grid item xs={12}>
                        <TopLanguagePairs />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RevenueAndExpenditure />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TranslatorStatistics />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default Dashboard;
