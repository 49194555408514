import * as React from 'react';
import { TablePagination } from '@mui/material';
import useQueryString from '../../hooks/useQueryString';

function Pagination({ totalRows }: { totalRows: number }) {
    const [query, setQuery] = useQueryString();

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setQuery({
            offset: newPage,
        });
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setQuery({
            limit: parseInt(event.target.value, 10),
            offset: 0,
        });
    };

    const page = query['offset'] ? Number(query['offset']) : 0;
    const rowsPerPage = query['limit'] ? Number(query['limit']) : 25;

    return (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
}

export default Pagination;
