import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Icon as Iconify } from '@iconify/react';
import Task from '../../../models/Task';
import reopenIcon from '@iconify/icons-ic/outline-open-in-new';
import checkMarkIcon from '@iconify/icons-eva/checkmark-circle-2-outline';
import React from 'react';
import { TaskStatus } from '../../../enums/TaskStatus';
import { UserRole } from '../../../enums/UserRole';
import useUpdateTask from './useUpdateTask';
import FormDialog from '../../../components/FormDialog';
import useReopenTask from './useReopenTask';
import useDeleteTask from './useDeleteTask';
import TextField from '../../../components/Form/fields/TextField';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import ControlledDialog from '../../../components/ControlledDialog';
import useGetTranslatedFile from './useGetTranslatedFile';
import { API } from '../../../utils/ApiClient';

const TaskActions: React.FC<{ task: Task; role: UserRole }> = ({ task, role }) => {
    const { mutate: reopenTask } = useReopenTask();
    const { mutate: deleteTask } = useDeleteTask();
    const { mutate: updateTask } = useUpdateTask();
    const { data: files, refetch } = useGetTranslatedFile(task.id);

    const downloadTranslation = async () => {
        refetch();
        if (files) {
            const fileId = files[0].id;
            const file = await API.get(`/tasks/${task.id}/files/${fileId}/download`);
            location.href = file.data;
        }
    };

    const isTranslationReady = task.status === TaskStatus.TRANSLATION_READY;
    const isCustomerRole = role === UserRole.AGENT || role === UserRole.USER;
    const showApproveOrDeclineAction = isTranslationReady && isCustomerRole;

    const isCustomerAndNewTask = (role === UserRole.USER || role === UserRole.AGENT) && task.status === TaskStatus.NEW_TASK;
    const isAdmin = role === UserRole.ADMIN;
    const showDeleteIcon = isCustomerAndNewTask || isAdmin;

    const handleStatusChange = (status: TaskStatus, explanation?: string) => {
        delete task.files;
        delete task.remainingHours;
        updateTask({
            ...task,
            status: status,
            explanation: explanation ?? undefined,
        });
    };

    const showReopenTask = task.status === TaskStatus.APPROVED && role !== UserRole.TRANSLATOR;


    return (
        <Stack direction={'row'} justifyContent={'flex-end'}>
            {showDeleteIcon && (
                <ControlledDialog
                    title={'Are you sure?'}
                    content={'This action is irreversible.'}
                    onSubmit={() => deleteTask({ id: task.id })} submitLabel={'Delete'} trigger={
                        <IconButton color={'error'}>
                            <Iconify fontSize={18} icon={trash2Outline}/>
                        </IconButton>
                    } />
            )}

            {task?.files?.[0] ?
                <Tooltip title={'Download file'}>
                    <IconButton onClick={downloadTranslation}>
                        <Iconify fontSize={18} icon={'eva:download-fill'}/>
                    </IconButton>
                </Tooltip> :
                <IconButton disabled>
                    <Iconify fontSize={18} icon={'eva:download-fill'}/>
                </IconButton>}

            {showReopenTask && (
                <Tooltip title={'Reopen task'}>
                    <IconButton onClick={() => reopenTask({ taskId: task.id })}>
                        <Iconify fontSize={18} icon={reopenIcon}/>
                    </IconButton>
                </Tooltip>
            )}
            {showApproveOrDeclineAction && (
                <>
                    <Tooltip title={'Approve'}>
                        <IconButton onClick={() => handleStatusChange(TaskStatus.APPROVED)}>
                            <Iconify
                                fontSize={18}
                                style={{ color: 'green' }}
                                icon={checkMarkIcon}/>
                        </IconButton>
                    </Tooltip>
                    <FormDialog<{ explanation: string }>
                        submitLabel={'Send'}
                        trigger={(
                            <Tooltip title={'Reject task'}>
                                <IconButton color="error">
                                    <Iconify fontSize={18} icon={'eva:close-circle-outline'}/>
                                </IconButton>
                            </Tooltip>
                        )}
                        initialValues={{ explanation: '' }}
                        title={'Are you sure?'}
                        content={
                            <>
                                <Typography>Please tell us why do you want to reject the Task?</Typography>
                                <TextField name={'explanation'} label={'Explanation'} />
                            </>

                        }
                        onSubmit={(form) => handleStatusChange(TaskStatus.INCORRECT_TRANSLATION, form?.explanation)}/>
                </>)}
        </Stack>
    );
};


export default TaskActions;
