import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Accordion, AccordionSummary, AccordionDetails, Button,
    Card, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import SuggestionComponent from './SuggestionComponent';
import useSuggestionsByTaskId from './useSuggestionsByTaskId';
import useAddSuggestionToTask from './useAddSuggestionToTask';
import useMe from '../../../hooks/useMe';
import { UserRole } from '../../../enums/UserRole';


const SuggestionSection: React.FC = () => {
    const { id: taskId } = useParams();
    const { data: currentUser } = useMe();
    const { data: suggestions } = useSuggestionsByTaskId(taskId);
    const { mutate, isLoading, isSuccess } = useAddSuggestionToTask(taskId);
    const [suggestionAmount, setSuggestionAmount] = useState('');
    const [suggestionAmountError, setSuggestionAmountError] = useState('');


    const submitSuggestion = () => {
        mutate(suggestionAmount);
    };

    const handleSubmit = () => {
        if (!suggestionAmount?.trim()) {
            setSuggestionAmountError('Required field');
            return;
        }

        if (suggestionAmount?.trim()) {
            setSuggestionAmountError('');
        }

        setSuggestionAmountError('');
        submitSuggestion();
    };


    useEffect(() => {
        if (isSuccess) {
            setSuggestionAmount('');
        }
    }, [isSuccess]);

    if (!suggestions?.length && currentUser?.role === UserRole.ADMIN) return <></>;


    return (
        <Card>
            <Accordion>
                <AccordionSummary
                    expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Stack gap={1}>
                        <Typography variant='subtitle2' color='text.secondary'>Translation Price Suggestions</Typography>
                        {suggestions && suggestions.length > 0 && <SuggestionComponent suggestion={suggestions[0]} currentUser={currentUser} />}
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    {suggestions?.slice(1).map((suggestion) => (
                        <SuggestionComponent suggestion={suggestion} key={suggestion.id} currentUser={currentUser} />
                    ))}
                    {currentUser?.role !== UserRole.ADMIN && (!suggestions?.length || !suggestions.find((s) => (s.status === 'pending'))) ? (
                        <Stack direction='row' sx={{ justifyContent: 'center', mt: 2 }} gap={1}>
                            <TextField
                                type='number'
                                error={!!suggestionAmountError}
                                helperText={suggestionAmountError}
                                fullWidth
                                size={'small'}
                                sx={{ maxWidth: 120 }}
                                disabled={isLoading}
                                value={suggestionAmount}
                                onChange={(e) => setSuggestionAmount(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                            />
                            <Button onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Stack>
                    ) : null}
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

export default SuggestionSection;
