import React, { FormEvent, KeyboardEventHandler, ReactNode } from 'react';
import { Form as FormikForm, Formik } from 'formik';

type FormProps<T> = {
    initialValues: T
    validationSchema?: unknown
    onSubmit: (form: T) => void
    children: ReactNode
    onKeyDown?: KeyboardEventHandler<HTMLFormElement>
    onChange?: (event: FormEvent<HTMLFormElement>) => void
}

function Form<T>({ initialValues, children, onSubmit, validationSchema, onKeyDown }: FormProps<T>) {
    return (
        <Formik
            enableReinitialize
            autoComplete={'off'}
            noValidate
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            <FormikForm onKeyDown={onKeyDown}>
                {children}
            </FormikForm>
        </Formik>);
}

export default Form;
