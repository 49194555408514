import { useState } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

function useAutocomplete<T>(queryKey: string, dataKey?: string, enabledWithoutQuery?: boolean) {
    const [query, setQuery] = useState('');

    const { data: options, isLoading } = useQuery<any, unknown, T[]>( {
        queryKey: [queryKey, query],
        enabled: enabledWithoutQuery || query.length > 0,
        select(res) {
            if (dataKey) {
                return res[dataKey];
            }

            return res;
        },
    });

    const hasNoOptions = options?.length === 0;

    const onChange =_.debounce((e) => {
        setQuery(e.target.value);
    }, 600);

    return { onChange, options, isLoading, hasNoOptions };
}

export default useAutocomplete;

