import useMe from '../hooks/useMe';
import { LinearProgress } from '@mui/material';
import Dashboard from './admin/Dashboard';
import TaskList from './TaskList';

const InitialPage: React.FC = () => {
    const { data: me } = useMe();

    if (!me) {
        return <LinearProgress />;
    }

    if (me.isAdmin) {
        return <Dashboard />;
    }

    return <TaskList />;
};

export default InitialPage;
