import React from 'react';
import MAvatar, { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';
import useBinaryLogoByCustomer from './CustomerCover/useBinaryLogoByCustomer';


export default function MyAvatar({ user, ...other }: MAvatarProps) {
    const { data: logoBinary } = useBinaryLogoByCustomer(user?.customerId);

    return (
        <MAvatar
            src={logoBinary}
            alt={user?.firstname || 'User'}
            color={logoBinary ? 'default' : createAvatar(user?.firstname || 'User').color}
            {...other}
        >
            {createAvatar(user?.firstname || 'User').name}
        </MAvatar>
    );
}
