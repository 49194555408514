import { Outlet } from 'react-router-dom';
import { Card, Stack } from '@mui/material';
import Sidebar from './Sidebar';
import useMe from '../../hooks/useMe';

const Chat: React.FC = () => {
    const { data: me } = useMe();


    return (
        <Stack component={Card} height={'100%'} maxWidth={1200} mx={'auto'}>
            <Stack direction={'row'} sx={{ height: '100%' }}>
                {me?.isAdmin && <Stack width={300} sx={{ borderRight: (t) => `1px solid ${t.palette.divider}` }}>
                    <Sidebar/>
                </Stack>}
                <Stack flex={1}>
                    <Outlet/>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Chat;
