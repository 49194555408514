import SelectField from '../../../Form/fields/SelectField';
import { roles } from '../../../../enums/UserRole';
import SwitcherField from '../../../Form/fields/SwitcherField';
import { Card, Grid, Typography } from '@mui/material';
import useMe from '../../../../hooks/useMe';
import { useParams } from 'react-router-dom';

const ProfilePanel: React.FC = () => {
    const { data: me } = useMe();
    const { id } = useParams();

    const showPanel = me?.isAdmin && me.id !== id;

    if (!showPanel) {
        return null;
    }

    return <Grid item xs={4}>
        <Card sx={{ p: 2, height: '100%' }}>
            <Typography mb={2} variant={'h5'}>Role</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SelectField name={'role'} label={'Role'} options={roles.map((role) => ({ id: role, name: role }))} />
                </Grid>
                <Grid item xs={12}>
                    <SwitcherField label={'Able to login'} name={'canLogin'} />
                </Grid>
            </Grid>
        </Card>
    </Grid>;
};

export default ProfilePanel;
