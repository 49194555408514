import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password required'),
    newPassword: Yup.string()
        .min(5, 'Must contain 5 characters at least')
        .required('New password required'),
    newPasswordAgain: Yup.string().oneOf([Yup.ref('newPassword'), null], 'New passwords must match'),
});


export default validationSchema;
