import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../utils/ApiClient';

function useUploadCustomerLogo(customerId?: string) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ file }: { file: File }) => {
            const data = new FormData();
            data.append('logo', file, file.name);
            await API.post(`/customers/${customerId}/logo`, data);
        },
        onSuccess() {
            queryClient.invalidateQueries(`customers/${customerId}/logo`);
        },
    });
}

export default useUploadCustomerLogo;
