import React from 'react';
import { Chip, Stack, Typography, Tooltip } from '@mui/material';
import checkMarkIcon from '@iconify/icons-eva/checkmark-circle-2-fill';
import closeMarkIcon from '@iconify/icons-eva/close-circle-fill';
import Suggestion from '../../../models/Suggestion';
import { SuggestionStatus } from '../../../enums/SuggestionStatus';
import { Icon } from '@iconify/react';
import { MIconButton } from '../../../components/@material-extend';
import useUpdateSuggestion from './useUpdateSuggestion';
import { useParams } from 'react-router';
import User from '../../../models/User';
import moment from 'moment';
import { UserRole } from '../../../enums/UserRole';

const SuggestionComponent: React.FC<{ suggestion: Suggestion, currentUser?: User }> = ({ suggestion, currentUser }) => {
    const { id: taskId } = useParams();
    const { mutate: updateSuggestion } = useUpdateSuggestion(taskId);

    const handleUpdateSuggestion = (s: Suggestion, status: SuggestionStatus) => {
        s.status = status;
        updateSuggestion(s);
    };

    const checkBadgeColor = (status?: SuggestionStatus) => {
        switch (status) {
        case SuggestionStatus.ACCEPTED: return 'success';
        case SuggestionStatus.DECLINED: return 'error';
        case SuggestionStatus.PENDING: return 'warning';
        default: return 'info';
        }
    };

    return (
        <Stack direction={'row'} gap={1} sx={{ maxHeight: 60, alignItems: 'center', mt: 1 }}>
            <Chip label={suggestion.amount + ' €'} color="info" size="small"/>
            <Typography variant='caption'>by</Typography>
            <Stack>
                <Typography variant='caption'>{suggestion.user?.firstname ?? 'Anonymous'}</Typography>
                <Typography variant='caption'>{suggestion.user?.lastname ?? ''}</Typography>
            </Stack>
            {(currentUser?.role !== UserRole.ADMIN || suggestion.status !== SuggestionStatus.PENDING) && (
                <Chip label={suggestion.status} color={checkBadgeColor(suggestion.status)} size="small"/>
            )}
            {currentUser?.role === UserRole.ADMIN && suggestion.status === SuggestionStatus.PENDING && (
                <Stack direction={'row'}>
                    <Tooltip title={'Accept'}>
                        <MIconButton edge="end" size="small" onClick={() => handleUpdateSuggestion(
                            suggestion, SuggestionStatus.ACCEPTED,
                        )}>
                            <Icon
                                width={20}
                                height={20}
                                style={{ color: 'green' }}
                                icon={checkMarkIcon} />
                        </MIconButton>
                    </Tooltip>
                    <Tooltip title={'Decline'}>
                        <MIconButton edge="end" size="small" onClick={() => handleUpdateSuggestion(
                            suggestion, SuggestionStatus.DECLINED,
                        )}>
                            <Icon
                                width={20}
                                height={20}
                                style={{ color: 'red' }}
                                icon={closeMarkIcon} />
                        </MIconButton>
                    </Tooltip>
                </Stack>
            )}
            {suggestion.status !== SuggestionStatus.PENDING && (
                <Typography variant='caption' sx={{ pt: 0.5 }}>at {moment(suggestion.judgedAt)?.format('MM/DD/YY HH:mm')}</Typography>
            )}
        </Stack>
    );
};

export default SuggestionComponent;
