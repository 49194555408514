import { Stack, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import AddNewHoliday from './AddNewHoliday';

type MenuProps = {
    years: number[],
    year: number,
    setYear: (value: number) => void;
};

const HolidayMenu = ({ years, year, setYear }: MenuProps): JSX.Element => {
    const handleChange = (year: number) => {
        setYear(year);
    };

    return (
        <Stack direction='row' sx={{ justifyContent: 'space-between', mb: 1 }}>
            <Stack sx={{ minWidth: 160 }}>
                <FormControl variant="outlined" size="small">
                    <InputLabel>
                        Year
                    </InputLabel>
                    <Select
                        label='Year'
                        value={year}
                        onChange={(e) => handleChange(e.target.value as number)}
                    >
                        {years.map((y) => (
                            <MenuItem
                                key={y}
                                value={y}
                            >
                                {y}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
            <Stack>
                <AddNewHoliday />
            </Stack>
        </Stack>
    );
};

export default HolidayMenu;
