import React from 'react';
import Customer from '../../models/Customer';
import useQueryString from '../../hooks/useQueryString';
import { Button, Grid } from '@mui/material';
import Form from '../../components/Form';
import AutoComplete from '../../components/Form/fields/AutoComplete/AutoComplete';
import { v4 as uuidv4 } from 'uuid';
import DateRangePicker from '../../components/Form/fields/DateRangePicker';
import TextField from '../../components/Form/fields/TextField';
import filter from '@iconify/icons-ant-design/filter-outline';
import { Icon } from '@iconify/react';


type FilterForm = {
    customerId?: string
    filename?: string
    targetLang?: string
    sourceLang?: string
    taskId?: string
    before?: string
    after?: string
}

const emptyFilters: FilterForm = {
    filename: '',
    customerId: '',
    before: '',
    after: '',
    sourceLang: '',
    targetLang: '',
    taskId: '',
};


const Filters: React.FC = () => {
    const [query, setQuery] = useQueryString();

    const onSubmit = (form: FilterForm) => {
        setQuery({ ...emptyFilters, ...form });
    };

    return (
        <Form<FilterForm>
            onSubmit={onSubmit}
            initialValues={{
                ...emptyFilters,
                ...query,
            }}
        >
            <Grid container spacing={1} sx={{ p: 1 }}>
                <Grid item xs={3}>
                    <AutoComplete name={'customerId'} label={'Customer'} queryKey={'customers'} queryOptions={{ select: (res: any) => res.customers.map((cust: Customer) => ({
                        id: cust.id ?? uuidv4(),
                        name: cust.name ?? 'Missing name',
                    })) }} />
                </Grid>
                <Grid item xs={6}>
                    <DateRangePicker lowerBoundLabel={'Created after'} upperBoundLabel={'Created before'} upperBoundName={'before'} lowerBoundName={'after'} />
                </Grid>
                <Grid item xs={3}>
                    <TextField label={'Task ID'} name={'taskId'} />
                </Grid>
                <Grid item xs={3}>
                    <TextField label={'File name'} name={'filename'} />
                </Grid>
                <Grid item xs={3}>
                    <AutoComplete name={'sourceLang'} label={'Source language'} queryKey={'languages'} />
                </Grid>
                <Grid item xs={3}>
                    <AutoComplete name={'targetLang'} label={'Target language'} queryKey={'languages'} />
                </Grid>
                <Grid item xs={3}>
                    <Button sx={{ height: '100%' }} startIcon={<Icon icon={filter} />} fullWidth variant={'contained'} type={'submit'}>Filter</Button>
                </Grid>
            </Grid>
        </Form>
    );
};

export default Filters;
