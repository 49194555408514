import { Sureness } from '../enums/Sureness';
import { TaskStatus } from '../enums/TaskStatus';
import { TranslationStyleType } from '../enums/TranslationStyleType';
import Address from './Address';
import EntityBase from './EntityBase';
import File from './File';
import Language from './Language';
import Priority from './Priority';
import User from './User';

export default class Task extends EntityBase {
    public customerId?: string;
    public taskId?: string;
    public deadLine?: string;
    public remainingHours?: number;
    public adminSeen?: boolean;
    public directedToAdmin?: boolean;
    public customerSeen?: boolean;
    public translatorSeen?: boolean;
    public hardCopyRequired?: boolean;
    public linguistSeen?: boolean;
    public customCustomerPrice?: boolean;
    public customTranslatorPrice?: boolean;
    public priorityId?: string;
    public priority?: Priority;
    public postalAddress?: Address;
    public status?: TaskStatus;
    public trackingNumber?: string;
    public include?: boolean;
    public label?: string;
    public approvedAt?: string;
    public finishedAt?: string;
    public priceApprovedAt?: string;
    public translationStartedAt?: string;
    public style?: TranslationStyleType;
    public files?: File[];
    public price?: number;
    public explanation?: string;
    public note?: string;
    public merchantId?: string;

    public currentBonusPercent?: number;

    public currentBonusAmount?: number;

    public currentPenaltyPercent?: number;

    public currentPenaltyAmount?: number;

    public currentDiscountPercent?: number;

    public currentDiscountAmount?: number;

    public sourceLanguage?: Language;
    public targetLanguage?: Language;
    public sourceLanguageId?: string;
    public targetLanguageId?: string;
    public sureness?: Sureness;
    public pages?: number
    public userId?: string;
    public creatorId?: string;
    public creator?: User;
    public translatorPrice?: number;
    public suggestions?: number;

    constructor(
        customerId?: string,
        priorityId?: string,
        postalAddress?: Address,
        status?: TaskStatus,
        trackingNumber?: string,
        include?: boolean,
        note?: string,
    ) {
        super();
        this.customerId = customerId;
        this.priorityId = priorityId;
        this.postalAddress = postalAddress;
        this.status = status;
        this.trackingNumber = trackingNumber;
        this.include = include;
        this.note = note;
    }
}
