import { useQuery } from 'react-query';
import createQueryString from '../../../utils/createQueryString';
import UserEvent from '../../../models/UserEvent';
import parseUserEvent from './parseUserEvent';
import useMe from '../../../hooks/useMe';

function useUserEventsByTask(taskId?: string) {
    const { data: me } = useMe();
    return useQuery<UserEvent[], unknown, Array<UserEvent & { general: string;details: string; }>>(createQueryString('userevents', { taskId }), {
        enabled: !!me && !!taskId,
        select(res) {
            return res.map((userEvent) => parseUserEvent(userEvent, me ));
        },
    });
}

export default useUserEventsByTask;
