import { LinearProgress, List, Stack } from '@mui/material';
import EventLog from './EventLog';
import UserEvent from '../../../models/UserEvent';

type Props = {
  data?: (UserEvent & { isNew: boolean })[]
  isLoading: boolean
}

const SidebarLogs: React.FC<Props> = ({ data, isLoading }) => {
    if (isLoading) {
        return <LinearProgress />;
    }

    if (!data) {
        return <div>no logs yet</div>;
    }


    return <Stack height={'90vh'} overflow={'scroll'}>
        <List dense>
            {data.map((event) => <EventLog key={event?.id} event={event} />)}
        </List>
    </Stack>;
};

export default SidebarLogs;
