import React, { ReactElement, useMemo } from 'react';
import { MenuItem, SxProps, TextField } from '@mui/material';
import { useField } from 'formik';


type SelectFieldProps = {
    name: string;
    label: string;
    sx?: SxProps;
    disabled?: boolean;
    options: {
        name: string | ReactElement;
        id: string;
    }[]
}

const SelectField: React.FC<SelectFieldProps> = ({ name, label, options, disabled, sx }) => {
    const [field, meta] = useField(name);

    return useMemo(() => {
        const hasError = Boolean(meta.touched && meta.error);
        const helperText = hasError && meta.error;

        const hasOptions = options?.length > 0;

        return (
            <TextField
                select
                fullWidth
                size={'small'}
                label={label}
                name={name}
                disabled={disabled}
                error={hasError}
                sx={sx}
                id={label}
                helperText={helperText}
                value={field.value ?? ''}
                onChange={field.onChange}
            >
                {hasOptions && options?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}

                {!hasOptions && (
                    <MenuItem value={''}>
                        No options available
                    </MenuItem>
                )}
            </TextField>
        );
    }, [disabled, field.onChange, field.value, label, meta.error, meta.touched, name, options, sx]);
};

export default SelectField;
