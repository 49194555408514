import { ListItem, ListItemIcon, ListItemText, Skeleton, Typography } from '@mui/material';

const SkeletonUser: React.FC = () => {
    return <ListItem>
        <ListItemIcon>
            <Skeleton variant={'circular'} width={60} height={60} />
        </ListItemIcon>
        <ListItemText>
            <Typography mb={0.5} variant={'body1'}><Skeleton width={260} variant={'rectangular'} /></Typography>
            <Typography variant={'body2'}><Skeleton width={60} variant={'rectangular'} /></Typography>
        </ListItemText>
    </ListItem>;
};

export default SkeletonUser;
