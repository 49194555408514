/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import plusFill from '@iconify/icons-eva/plus-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import { Button, Card, DialogActions, DialogContent, Divider, FormControl, IconButton, MenuItem, Stack, Switch, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import lodash from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { TranslationStyle } from '../../enums/TranslationStyle';
import { UserRole } from '../../enums/UserRole';
import Language from '../../models/Language';
import User from '../../models/User';
import { API } from '../../utils/ApiClient';
import useMe from '../../hooks/useMe';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useCustomers from '../../hooks/useCustomers';
import SelectField from '../Form/fields/SelectField';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

const IconButtonStyle = styled(IconButton)(() => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },
}));

const ROLE_OPTIONS = [
    { value: 'admin', name: 'Admin', agentAccess: false, linguistAccess: false },
    { value: 'linguist', name: 'Linguist', agentAccess: false, linguistAccess: false },
    { value: 'translator', name: 'Translator', agentAccess: false, linguistAccess: true },
    { value: 'user', name: 'User', agentAccess: true, linguistAccess: false },
    { value: 'agent', name: 'Agent', agentAccess: true, linguistAccess: false },
];


export default function UserForm({ onClose }: { onClose?: () => void }): JSX.Element {
    const { data: languages } = useQuery<Language[]>('languages');
    const { data: customers } = useCustomers();
    const { data: currentUser } = useMe();
    const { enqueueSnackbar } = useSnackbar();
    const [checked, setChecked] = useState(true);
    const queryClient = useQueryClient();

    const { mutate: addUser } = useMutation({
        mutationFn: (user: User) => API.post('users', user),
        onSuccess() {
            enqueueSnackbar('User added', { variant: 'success' });
            queryClient.invalidateQueries('users');
            onClose?.();
        },
    });


    const toggleChecked = () => {
        setChecked((prev) => !prev);
    };

    const UserSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email required'),
        firstname: Yup.string().required('First name required'),
        lastname: Yup.string().required('Last name required'),
        role: Yup.string().required('Role required'),
    });

    const formik = useFormik<User>({
        enableReinitialize: true,
        initialValues: {
            email: '',
            firstname: '',
            lastname: '',
            role: undefined,
            customerId: currentUser?.customerId,
            languages: [],
            canLogin: currentUser?.role !== UserRole.LINGUIST,
            style: undefined,
        },
        validationSchema: UserSchema,
        onSubmit: async (values) => {
            addUser({
                ...values,
                canLogin: currentUser?.isLinguist ? false : checked });
        },
    });

    const { errors, values, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <DialogContent sx={{ pb: 0, overflowY: 'unset' }}>
                    <TextField
                        fullWidth
                        label="First Name"
                        sx={{ mb: 3 }}
                        {...getFieldProps('firstname')}
                        value={values.firstname}
                        error={Boolean(touched.firstname && errors.firstname)}
                        helperText={touched.firstname && errors.firstname}
                    />

                    <TextField
                        fullWidth
                        label="Last Name"
                        sx={{ mb: 3 }}
                        {...getFieldProps('lastname')}
                        value={values.lastname}
                        error={Boolean(touched.lastname && errors.lastname)}
                        helperText={touched.lastname && errors.lastname}
                    />

                    <TextField
                        fullWidth
                        label="E-mail address"
                        sx={{ mb: 3 }}
                        {...getFieldProps('email')}
                        onChange={(e) => {
                            setFieldValue('email', e.target.value.toLowerCase(), true);
                        }}
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <TextField
                        select
                        fullWidth
                        label="Role"
                        sx={{ mb: 1 }}
                        {...getFieldProps('role')}
                        error={Boolean(touched.role && errors.role)}
                        helperText={touched.role && errors.role}
                    >
                        {currentUser?.customerId ? (ROLE_OPTIONS.filter((o) => (
                            o.agentAccess === true
                        )).map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.name}
                            </MenuItem>
                        ))) : currentUser?.role === UserRole.LINGUIST ? (
                            ROLE_OPTIONS.filter((o) => (
                                o.linguistAccess === true
                            )).map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.name}
                                </MenuItem>
                            ))
                        ) : (
                            ROLE_OPTIONS.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.name}
                                </MenuItem>
                            ))
                        )}
                    </TextField>
                    {currentUser?.role !== UserRole.LINGUIST && values.role === UserRole.TRANSLATOR ? (
                        <LabelStyle>
                            Has the ability to log in?
                            <Switch checked={checked} onChange={toggleChecked} />
                        </LabelStyle>
                    ) : null}

                    {values.role === UserRole.TRANSLATOR ? (
                        <>
                            <Stack spacing={3}>

                                <TextField
                                    select
                                    sx={{ mt: 1 }}
                                    fullWidth
                                    label='Set translator type'
                                    {...getFieldProps('style')}
                                    value={values.style}
                                >
                                    {Object.values(TranslationStyle).map((style, i) => (
                                        <MenuItem key={i} value={style}>
                                            {lodash.capitalize(style)}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </Stack>
                            <Stack>
                                <FieldArray
                                    name="languages"
                                    render={(arrayHelpers) => (
                                        <>
                                            {values.languages && values.languages.map((_: any, index: number) => (
                                                <Card key={index} sx={{ p: 2, mt: 1 }}>
                                                    <FormControl
                                                        fullWidth variant="outlined"
                                                        sx={{ mb: 1 }}>
                                                        <LabelStyle>Language name</LabelStyle>
                                                        <SelectField
                                                            options={languages?.map((lang) => ({
                                                                id: lang.id ?? '-',
                                                                name: lang.name ?? '-',
                                                            })) ?? []}
                                                            label={'Language'} name={`languages[${index}].id`} />
                                                        <IconButtonStyle
                                                            onClick={() => arrayHelpers.remove(index)}
                                                        >
                                                            <Icon
                                                                style={{ color: 'red' }}
                                                                icon={trash2Outline} />
                                                        </IconButtonStyle>
                                                    </FormControl>
                                                </Card>
                                            ))}
                                            <Button
                                                variant="outlined"
                                                onClick={() => arrayHelpers.push({ languageId: '', type: '' })}
                                                startIcon={<Icon icon={plusFill} width={20} height={20}/>}
                                                sx={{ my: 2 }}
                                            >
                                                Add language
                                            </Button>
                                        </>
                                    )}
                                />
                            </Stack>

                            <Divider />
                        </>
                    ) : null}

                    {(values.role === UserRole.AGENT || values.role === UserRole.USER) &&
                        (!currentUser?.customerId) ? (
                            <Stack spacing={3}>

                                <TextField
                                    select
                                    fullWidth
                                    label="Company"
                                    {...getFieldProps('customerId')}
                                    error={Boolean(touched.customerId && errors.customerId)}
                                    helperText={touched.customerId && errors.customerId}
                                    sx={{ mt: 1 }}
                                >
                                    {customers?.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </Stack>

                        ) : null}

                </DialogContent>
                <DialogActions>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Save
                    </LoadingButton>
                    <Button type="button" variant="contained" color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Form>
        </FormikProvider>
    );
}
