import { Theme } from '@mui/material/styles';


export default function Table(theme: Theme) {
    return {
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        'backgroundColor': theme.palette.action.selected,
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    'borderBottom': 'none',
                    'padding': '0 8px',
                    'fontSize': 12,
                    '& .MuiTypography-root': {
                        padding: '10px 0',
                    },
                },
                head: {
                    'color': theme.palette.text.secondary,
                    'backgroundColor': theme.palette.background.neutral,
                    '&:first-of-type': {
                        borderTopLeftRadius: theme.shape.borderRadius,
                        borderBottomLeftRadius: theme.shape.borderRadius,
                    },
                    '&:last-of-type': {
                        borderTopRightRadius: theme.shape.borderRadius,
                        borderBottomRightRadius: theme.shape.borderRadius,
                    },
                    '& .MuiTypography-root': {
                        padding: '10px 0',
                    },
                },
                stickyHeader: {
                    backgroundColor: theme.palette.background.paper,
                    backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%,
                        ${theme.palette.background.neutral} 100%)`,
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    borderTop: `solid 1px ${theme.palette.divider}`,
                },
                toolbar: {
                    height: 64,
                },
                select: {
                    '&:focus': {
                        borderRadius: theme.shape.borderRadius,
                    },
                },
                selectIcon: {
                    width: 20,
                    height: 20,
                    marginTop: 2,
                },
            },
        },
    };
}
