import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useField } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

const PasswordField: React.FC<{ name: string; label: string }> = ({ name, label }) => {
    const [field, meta] = useField(name);
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const type = showPassword ? 'text' : 'password';
    const hasError = Boolean(meta.touched && meta.error);
    const helperText = hasError && meta.error;

    return <TextField
        name={name}
        fullWidth
        label={label}
        size={'small'}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        type={type}
        error={hasError}
        helperText={helperText}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton onClick={toggleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                </InputAdornment>
            ),
        }}
    />;
};

export default PasswordField;
