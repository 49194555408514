import { AnnotationResponse } from './AnnotationTypes';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';

export default function useAnnotation(taskId?: string, fileId?: string) {
    const { enqueueSnackbar } = useSnackbar();

    return useQuery<AnnotationResponse>(`/annotation-metadata/${taskId}/${fileId}`, {
        onError: (err) => {
            console.log(err);
            enqueueSnackbar('Resource not found', { variant: 'error', autoHideDuration: 5000 });
        },
    });
}
