import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';

function useUploadFileToTask(taskId?: string) {
    const queryClient = useQueryClient();

    return useMutation<{ status: number }, unknown, { file: File; type: string }>({
        mutationFn: ({ file, type }) => {
            const data = new FormData();
            data.append(type, file, file.name);
            return API.post(`/tasks/${taskId}/files`, data, { timeout: 60000 });
        },
        onSuccess() {
            queryClient.invalidateQueries(`/tasks/${taskId}/files`);
        },
    });
}

export default useUploadFileToTask;
