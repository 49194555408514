import { useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import TextField from '../../Form/fields/TextField';
import { Button, Card, CardContent, IconButton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { DiscountForm } from './Discounts';
import useMe from '../../../hooks/useMe';
import Discount from '../../../models/Discount';
import useDeleteDiscount from './useDeleteDiscount';
import useRefetchDiscounts from './useRefetchDiscounts';


const DiscountEditor = () : JSX.Element => {
    const { data: me } = useMe();
    const { id: customerId } = useParams();
    const { mutate: deleteDiscount } = useDeleteDiscount();
    const { setFieldValue, values } = useFormikContext<DiscountForm>();
    const { refetch } = useRefetchDiscounts(customerId);

    const onAddDiscount = () => {
        const discount: Discount = {
            id: uuidv4(),
            customerId: customerId,
            isNew: true,
        };

        setFieldValue('discounts', [...values.discounts, discount]);
    };


    const removeDiscountByIndex = (index: number) => {
        const target = values.discounts[index];
        if (target.isNew) {
            setFieldValue('discounts', values.discounts.filter((l, i) => i !== index));
        } else {
            deleteDiscount({ id: target.id });
        }
    };

    const activateDiscount = () => {
        refetch();
    };


    return (
        <Stack>
            {values.discounts.map((discount, index) => (
                <Card sx={{ mb: 1, border: discount.active ? 1 : 0, borderColor: 'primary.main' }} key={index}>
                    <CardContent>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                            <TextField label={'Page number'} name={`discounts[${index}].pages`} disabled={!me?.isAdmin} />
                            <TextField label={'Discount'} name={`discounts[${index}].percent`} disabled={!me?.isAdmin} />
                            <IconButton onClick={() => removeDiscountByIndex(index)} disabled={!me?.isAdmin}>
                                <Icon icon={trash2Outline} width={20} height={20} />
                            </IconButton>
                        </Stack>
                    </CardContent>
                </Card>
            ))}
            {me?.isAdmin && (
                <Stack direction='column' gap={1} alignItems='center'>
                    <Button color="inherit"
                        startIcon={<Icon icon={plusFill} width={20} height={20}/>}
                        onClick={onAddDiscount}
                    >
                        Add Discount
                    </Button>
                    <Button color="inherit"
                        onClick={activateDiscount}
                    >
                        Calculate current discount
                    </Button>
                    <Button type={'submit'} variant={'contained'}>Save</Button>
                </Stack>
            )}

        </Stack>
    );
};

export default DiscountEditor;
