import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';

function useDeletePrice() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }: { id?: string }) => API.delete('/translatorprices/'+ id),
        onSuccess() {
            queryClient.invalidateQueries('translationoffers');
        },
    });
}

export default useDeletePrice;
