import { useMutation } from 'react-query';
import { API } from '../../../utils/ApiClient';
import fileDownload from 'js-file-download';
import moment from 'moment';
import useQueryString from '../../../hooks/useQueryString';

function useExportData() {
    const [query] = useQueryString();
    return useMutation({
        mutationFn: async () => {
            const startDate = query['after'];
            const endDate = query['before'];
            const selectedSrcLanguageId = query['sourceLang'];
            const selectedTargetLanguageId = query['targetLang'];
            const customerId = query['customerId'];
            const status = query['status'];
            const params: { [key: string]: any } = {};
            if (startDate && endDate) {
                params.after = startDate;
                params.before = endDate;
            }
            if (selectedSrcLanguageId) {
                params.sourceLang = selectedSrcLanguageId;
            }
            if (selectedTargetLanguageId) {
                params.targetLang = selectedTargetLanguageId;
            }
            params.customerId = customerId;
            params.status = status;
            params.summary = true;

            const res = await API.get('/export', { responseType: 'blob', params });
            fileDownload(res.data, `WeTranslate_${moment().format('YYYYMMDDHHmm')}.xlsx`);
        },
    });
}

export default useExportData;
