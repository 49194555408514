import { Card, CardContent, CardHeader, Container, Stack, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import Language from '../../../../models/Language';
import Form from '../../../Form';
import LanguageEditor from './LanguageEditor';
import useSaveLanguages from './useSaveLanguages';


export type LanguageForm = {
  languages: Language[]
}

export default function Languages(): JSX.Element {
    const { data: languages } = useQuery<Language[]>('languages');
    const { mutate } = useSaveLanguages();

    const onSubmit = (form: LanguageForm) => {
        mutate({ languages: form.languages });
    };


    return (
        <Container>
            <Card sx={{ p: 2 }}>
                <CardHeader title="Specify the languages that can be selected in the system" />
                <Stack spacing={2} sx={{ width: 1, px: 3.5 }}>
                    <Stack gap={1}>
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            Automated Translation supported languages are listed in the link below:<br />
                            <a href='https://cloud.google.com/translate/docs/languages' target={'_blank'} rel="noreferrer">Supported Languages</a><br />
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            Make sure the language names are the same. Chinese is accepted.
                        </Typography>
                    </Stack>
                </Stack>
                <CardContent>
                    <Stack spacing={3}>
                        <Form<LanguageForm> onSubmit={onSubmit} initialValues={{ languages: languages ?? [] }}>
                            <LanguageEditor />
                        </Form>
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    );
}

