import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { API } from '../../../utils/ApiClient';
import Form from '../../Form';
import TextField from '../../Form/fields/TextField';
import ResetForm from './ResetForm';
import validationSchema from './validationSchema';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';


type ResetPasswordFormProps = {
  setSent: (value: boolean) => void;
  setEmail: (value: string) => void;
};

export default function ResetPasswordForm({ setSent, setEmail }: ResetPasswordFormProps): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: resetPassword } = useMutation({
        mutationFn: (form: ResetForm) => API.post('/users/forgotpassword', form),
        onSuccess(data, variables: ResetForm) {
            setEmail(variables.email);
            setSent(true);
            enqueueSnackbar('Request sent', { variant: 'success' });
        },
    });

    return (
        <Stack spacing={3}>
            <Typography variant="h3" paragraph>
                Forgot your password?
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Please enter the email address associated with your account and we will email you a
                link to reset your password.
            </Typography>
            <Stack spacing={1}>
                <Form<ResetForm>
                    onSubmit={resetPassword}
                    initialValues={{ email: '' }}
                    validationSchema={validationSchema}>
                    <Stack gap={1}>
                        <TextField name={'email'} label={'Email address'} />
                        <Stack spacing={3} alignItems="center">
                            <LoadingButton type="submit" variant="contained" size="large" sx={{ textTransform: 'none' }}>
                                Reset password
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Form>
            </Stack>
        </Stack>
    );
}
