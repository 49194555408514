import { useQuery } from 'react-query';
import { TranslationOfferWithPrices } from './Prices';
import { API } from '../../../utils/ApiClient';
import TranslatorPrice from '../../../models/TranslatorPrice';
import TranslationOffer from '../../../models/TranslationOffer';

function useTranslatorOffers(id?: string) {
    return useQuery<TranslationOfferWithPrices[], unknown, TranslationOfferWithPrices[]>(['translationoffers', '?userId=', id], async () => {
        const offers: { data: TranslationOffer[] } = await API.get('translationoffers?userId=' + id);
        const prices: { data: TranslatorPrice[] } = await API.get('translatorprices');
        return offers.data.map((offer) => {
            const translatorPrices = prices.data
                .filter((p) => p.translationOfferId === offer.id)
                .map((p) => ({ ...p, formType: 'original' }));
            return {
                ...offer,
                translatorPrices,
                formType: 'original',
            };
        });
    }, { enabled: !!id });
}

export default useTranslatorOffers;
