import queryClient from '../utils/defaultQueryClient';
import { useSnackbar } from 'notistack';
import { QueryClientProvider as RQProvider } from 'react-query';


const QueryClientProvider: React.FC = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar();


    return <RQProvider client={queryClient((res) => {
        if (res && res.data && res.data.error) {
            enqueueSnackbar(res.data.error.message ?? res.data.error.detail ?? 'An error occurred', { variant: 'error', autoHideDuration: 5000 });
        }
    })}>
        {children}
    </RQProvider>;
};

export default QueryClientProvider;
