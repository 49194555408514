import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import MessageType from '../../../../models/Messsage';
import useParticipant from '../../useParticipant';
import moment from 'moment';
import React, { useState } from 'react';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import useDeleteMessage from './useDeleteMessage';

const Message: React.FC<{ message: MessageType }> = ({ message }) => {
    const { mutate: deleteMessage } = useDeleteMessage();
    const { data: participant } = useParticipant();
    const isParticipantMessage = participant?.id === message.sender.id;

    const [showDeleteButton, setShowDeleteButton] = useState(false);

    return <>
        <Stack
            onMouseEnter={() => setShowDeleteButton(true)}
            onMouseLeave={() => setShowDeleteButton(false)}
            ml={4}
            position={'relative'}
            alignSelf={isParticipantMessage ? 'flex-start' : 'flex-end'} maxWidth={500} direction={'row'} gap={2}>
            {isParticipantMessage && (
                <Stack>
                    <Avatar sx={{ width: 30, height: 30 }}/>
                </Stack>
            )}
            {showDeleteButton && !isParticipantMessage && (
                <IconButton
                    onClick={() => deleteMessage({ messageId: message.id })}
                    sx={{
                        position: 'absolute',
                        bottom: -12,
                        right: -12,
                        fontSize: '14px',
                    }}>
                    <Icon icon={closeFill} />
                </IconButton>
            )}
            <Stack
                alignItems={ isParticipantMessage ? 'flex-start' : 'flex-end'}>
                <Typography
                    mb={0.5}
                    align={isParticipantMessage ? 'left' : 'right'}
                    color={'text.secondary'}
                    fontSize={12}>
                    {moment(message.created).fromNow()}
                </Typography>
                <Typography
                    align={isParticipantMessage ? 'left' : 'right'}
                    sx={{ backgroundColor: (t) => isParticipantMessage ? alpha(t.palette.primary.light, 0.2) : t.palette.background.neutral }}
                    borderRadius={1} p={2}>
                    {message.body}
                </Typography>
            </Stack>
        </Stack>
    </>;
};

export default Message;
