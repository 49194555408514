import closeFill from '@iconify/icons-eva/close-fill';
import downloadFill from '@iconify/icons-eva/download-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import { Icon } from '@iconify/react';
import axios from 'axios';
import {
    List,
    ListItem,
    ListItemIcon, ListItemText, Stack, Tooltip,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { TaskStatus } from '../enums/TaskStatus';
import { UserRole } from '../enums/UserRole';
import File from '../models/File';
import User from '../models/User';
import DeleteFileDialog from '../pages/TaskEditor/FilesByType/DeleteFileDialog';
import useAPI from '../utils/ApiClient';
import convertBytes from '../utils/ByteConverter';
import { MIconButton } from './@material-extend';
import { FileType } from '../enums/FileType';

type ListFilesProps = {
    files: File[],
    onDelete?: (id?: string) => void,
    downloadable: boolean,
    previewable: boolean,
    taskId?: string,
    userId?: string,
    currentUser?: User,
    taskStatus?: string;
    translation?: boolean;
    disableDelete?: boolean;
}

export default function ListFiles(props: ListFilesProps): JSX.Element {
    const [openFileDeletion, setOpenFileDeletion] = useState<boolean>(false);

    const [, downloadFile] = useAPI({ method: 'GET' }, { manual: true });
    const [, previewFile] = useAPI({ method: 'GET' }, { manual: true });

    const handlePreview = async (id?: string) => {
        const url = props.taskId ?
            `/tasks/${props.taskId}/files/${id}/preview` :
            `/users/${props.userId}/files/${id}/preview`;
        const previewUrl = (await previewFile({ url })).data;
        const pdfWindow = window.open();
        if (pdfWindow) {
            pdfWindow.location.href = previewUrl;
        }
    };

    const handleDownload = async (id?: string) => {
        const url = props.taskId ?
            `/tasks/${props.taskId}/files/${id}/download` :
            `/users/${props.userId}/files/${id}/download`;
        const data = (await downloadFile({ url })).data;
        const file = await axios.get(data.url, {
            responseType: 'arraybuffer',
        });

        triggerFileDownload({ response: file, fileName: data.filename });
    };

    const triggerFileDownload = ({
        response,
        contentType,
        fileName,
    }: {
        response: AxiosResponse;
        contentType?: string;
        fileName?: string;
    }) => {
        const disposition = response.headers['content-disposition'];
        let name: string;
        if (disposition) {
            name = disposition.substring(disposition.indexOf('"') + 1, disposition.lastIndexOf('"'));
        } else if (fileName) {
            name = fileName;
        } else {
            name = 'file';
        }

        const type = contentType ? contentType : response.headers['content-type'];

        const blob = new Blob([response.data], { type });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = name;
        document.body.appendChild(a);
        a.click();
    };

    const checkOriginalDelete = (user?: User, status?: string) => {
        if (user && user.role === UserRole.ADMIN) return true;
        if (status === TaskStatus.INVALID_SOURCE_FILE || status === TaskStatus.NEW_TASK) {
            return user && user.role === UserRole.AGENT;
        }
        return false;
    };

    const checkTranslationDelete = (user?: User, status?: string) => {
        if (user && user.role === UserRole.ADMIN) return true;
        if (status !== TaskStatus.TRANSLATION_READY && status !== TaskStatus.APPROVED) {
            return user && (user.role === UserRole.TRANSLATOR || user.role === UserRole.LINGUIST);
        }
        return false;
    };

    return (
        <List>
            {props.files.map((file: File) => {
                return file.type === FileType.AUTOTRANSLATION && file.parseStatus === 'error' ? (
                    <ListItem
                        key={file.id}
                        component={'div'}
                        sx={{
                            py: 0.5,
                            px: 1,
                            borderRadius: 1,
                            border: (theme) => `solid 1px ${theme.palette.divider}`,
                            bgcolor: 'background.paper',
                        }}
                    >
                        <ListItemIcon>
                            <Icon icon={fileFill} width={28} height={28} />
                        </ListItemIcon>
                        <ListItemText
                            primary={'Automated Translation error!'}
                            secondary={'Please upload Original File again!'}
                            primaryTypographyProps={{
                                variant: 'subtitle2', sx: {
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                },
                            }}
                            secondaryTypographyProps={{ variant: 'caption' }}
                        />
                    </ListItem>
                ) : (
                    <ListItem
                        key={file.id}
                        component={'div'}
                        sx={{
                            py: 0.5,
                            px: 1,
                            borderRadius: 1,
                            border: (theme) => `solid 1px ${theme.palette.divider}`,
                            bgcolor: 'background.paper',
                        }}
                    >
                        <ListItemIcon>
                            <Icon icon={fileFill} width={28} height={28} />
                        </ListItemIcon>
                        <ListItemText
                            primary={file.filename}
                            secondary={convertBytes(file.size ?? 0)}
                            primaryTypographyProps={{
                                variant: 'subtitle2', sx: {
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                },
                            }}
                            secondaryTypographyProps={{ variant: 'caption' }}
                        />
                        <Stack direction={'row'}>
                            {props.downloadable ? (
                                <Tooltip title={'Download'}>
                                    <MIconButton edge="end" size="small" onClick={() => handleDownload(file.id)}>
                                        <Icon icon={downloadFill} />
                                    </MIconButton>
                                </Tooltip>
                            ) : null}

                            {props.previewable ? (
                                <Tooltip title={'Preview'}>
                                    <MIconButton edge="end" size="small" onClick={() => handlePreview(file.id)}>
                                        <Icon icon={eyeFill} />
                                    </MIconButton>
                                </Tooltip>
                            ) : null}

                            {!props.translation && checkOriginalDelete(props.currentUser, props.taskStatus) ? (
                                <Tooltip title={'Delete'}>
                                    <MIconButton disabled={props.disableDelete} edge="end" size="small" onClick={() => setOpenFileDeletion(true)}>
                                        <Icon style={{ color: 'red' }} icon={closeFill} />
                                    </MIconButton>
                                </Tooltip>
                            ) : null}

                            {props.translation && checkTranslationDelete(props.currentUser, props.taskStatus) ? (
                                <Tooltip title={'Delete'}>
                                    <MIconButton edge="end" size="small" onClick={() => setOpenFileDeletion(true)}>
                                        <Icon style={{ color: 'red' }} icon={closeFill} />
                                    </MIconButton>
                                </Tooltip>
                            ) : null}

                            {props.userId ? (
                                <Tooltip title={'Delete'}>
                                    <MIconButton edge="end" size="small" onClick={() => setOpenFileDeletion(true)}>
                                        <Icon style={{ color: 'red' }} icon={closeFill} />
                                    </MIconButton>
                                </Tooltip>
                            ) : null}
                        </Stack>

                        <DeleteFileDialog
                            open={openFileDeletion}
                            onClose={() => setOpenFileDeletion(false)}
                            containerId={props.taskId ?? props.userId ?? ''}
                            fileId={file.id ?? ''}
                            containerType={props.taskId ? 'task' : 'user'}
                        />
                    </ListItem>
                );
            })}

        </List>
    );
}
