/* eslint-disable indent */
import { Card, Stack, Typography } from '@mui/material';
import React from 'react';
import ListFiles from '../../../components/ListFiles';
import { UploadMultipleFile } from '../../../components/upload';
import CustomFile from '../../../interfaces/CustomFile';
import File from '../../../models/File';
import numeral from 'numeral';

type FileToTranslateProps = {
    files: CustomFile[];
    onUpload: (files: globalThis.File[]) => void;
    charCount?: number | null;
}

function FileToTranslate({ files, onUpload, charCount }: FileToTranslateProps): JSX.Element {
    return (
        <Card sx={{ p: 2 }}>
            <Stack gap={2}>
                <Typography variant='subtitle2' gutterBottom>
                    File to Translate {charCount && `- ${numeral(charCount).format(',')} characters`}
                </Typography>
                <UploadMultipleFile onUpload={onUpload} />
                <ListFiles files={files.map((f) => {
                    const file = new File();
                    file.id = f.id;
                    file.filename = f.file.name;
                    file.size = f.file.size;
                    file.type = f.type;
                    return file;
                })}
                    downloadable={false}
                    previewable={false} />
            </Stack>
        </Card>
    );
}

export default FileToTranslate;
