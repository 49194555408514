
import { TranslationStyle } from '../enums/TranslationStyle';
import { UserRole } from '../enums/UserRole';
import Address from './Address';
import EntityBase from './EntityBase';
import Language from './Language';
// import UserLanguage from './UserLanguage';

export default class User extends EntityBase {
    public email?: string;
    public phone?: string;
    public company?: string;
    public customerId?: string;
    public role?: UserRole;
    public active?: boolean;
    public firstname?: string;
    public lastname?: string;
    public languages?: Language[];
    // public languages?: any;
    public canLogin?: boolean;
    public password?: string;
    public postalAddress?: Address;
    public invoiceAddress?: Address;
    public style?: TranslationStyle;

    static hasRole(user?: User, roles?: UserRole[]): boolean {
        if (!user || !user?.role || !roles) return false;
        return roles.includes(user.role);
    }
}
