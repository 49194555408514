import { extendedRoles } from './taskWidgetsData';
import {
    Button,
    Card,
    Checkbox,
    Container,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import useGetTaskWidgets from '../../../../hooks/useGetTaskWidgets';
import { useEffect, useState } from 'react';
import TaskLoading from '../../../../pages/TaskEditor/TaskLoading';
import useUpdateTaskWidgets from './useUpdateTaskWidgets';
import TaskWidget from '../../../../models/TaskWidget';

const TaskWidgets = () => {
    const { data: taskWidgets, isLoading } = useGetTaskWidgets();
    const { mutate: updateTaskWidgets } = useUpdateTaskWidgets();
    const [taskWidgetsMask, setTaskWidgetsMask] = useState<Partial<TaskWidget>[] | undefined>(taskWidgets);

    const checkboxClick = (slug: string, role: string) => {
        if (!taskWidgetsMask) return;

        const newData = [...taskWidgetsMask];
        const slugIndex = newData.findIndex((data) => data.slug === slug);
        let newRolesArray = [...(newData[slugIndex].roles || [])];

        if (newRolesArray.includes(role)) {
            newRolesArray = newRolesArray.filter((_role) => _role !== role);
        } else {
            newRolesArray.push(role);
        }

        newData[slugIndex].roles = newRolesArray;

        setTaskWidgetsMask(newData);
    };

    const saveWidgets = () => {
        if (!taskWidgetsMask) return;

        updateTaskWidgets(taskWidgetsMask);
    };

    useEffect(() => {
        if (!taskWidgets) return;

        const mask = taskWidgets.map((widget) => ({
            slug: widget.slug,
            name: widget.name,
            roles: widget.roles,
            restrictedFor: widget.restrictedFor,
        }));

        setTaskWidgetsMask(mask);
    }, [taskWidgets]);

    if (isLoading) {
        return <TaskLoading />;
    }

    return (
        <Container>
            <Card sx={{ p: 2 }}>
                <Stack>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                            <TableHead>
                                <TableRow sx={{ py: 3 }}>
                                    <TableCell></TableCell>
                                    {extendedRoles.map((role) => (
                                        <TableCell align='center' key={role.name}>
                                            {role.header}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {taskWidgetsMask &&
                                    taskWidgetsMask.map((widget) => (
                                        <TableRow key={widget.slug}>
                                            <TableCell sx={{ py: 0.5 }} component='th' scope='row'>
                                                {widget.name}
                                            </TableCell>

                                            {extendedRoles.map((role) => (
                                                <TableCell key={role.name} sx={{ py: 0.5 }} align='center'>
                                                    <Checkbox
                                                        size='small'
                                                        checked={widget.roles?.includes(role.name)}
                                                        disabled={widget.restrictedFor?.includes(role.name)}
                                                        onClick={() => checkboxClick(widget.slug || '', role.name)}
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Card>
            <Stack sx={{ alignItems: 'center', mt: 4 }}>
                <Button variant={'contained'} onClick={saveWidgets}>
                    Save widgets
                </Button>
            </Stack>
        </Container>
    );
};

export default TaskWidgets;
