/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from '@iconify/react';
import { IconButton, Stack, Tooltip, Box } from '@mui/material';
import useEditor from './useEditor';
import leftCircleFilled from '@iconify/icons-ant-design/left-circle-filled';
import leftCircleOutlined from '@iconify/icons-ant-design/left-circle-outlined';
import rightCircleFilled from '@iconify/icons-ant-design/right-circle-filled';
import rightCircleOutlined from '@iconify/icons-ant-design/right-circle-outlined';
import { useMemo } from 'react';

const DocumentPager = () => {
    const { editorState, setSelectedPage, setSelectedPageData } = useEditor();
    const { transformedData, selectedPage } = editorState;
    const pageCount = transformedData.length;

    const handlePrev = () => {
        if (selectedPage > 1) {
            const _selectedPage = selectedPage;

            setSelectedPage(_selectedPage - 1);
            setSelectedPageData(transformedData[_selectedPage - 2]);
        }
    };

    const handleNext = () => {
        if (selectedPage < pageCount) {
            const _selectedPage = selectedPage;

            setSelectedPage(_selectedPage + 1);
            setSelectedPageData(transformedData[_selectedPage]);
        }
    };

    return useMemo(
        () => (
            <Stack direction='row' pt={3} width={'100%'} justifyContent={'center'} alignItems={'center'} spacing={5}>
                <Tooltip title={'Previous page'}>
                    <IconButton onClick={handlePrev}>
                        <Icon
                            icon={selectedPage === 1 ? leftCircleOutlined : leftCircleFilled}
                            width={26}
                            height={26}
                        />
                    </IconButton>
                </Tooltip>

                <Box sx={{ fontSize: 18 }}>
                    {pageCount} / {selectedPage}
                </Box>

                <Tooltip title={'Next page'}>
                    <IconButton onClick={handleNext}>
                        <Icon
                            icon={selectedPage === pageCount ? rightCircleOutlined : rightCircleFilled}
                            width={26}
                            height={26}
                        />
                    </IconButton>
                </Tooltip>
            </Stack>
        ),
        [selectedPage, pageCount],
    );
};

export default DocumentPager;
