import React from 'react';
import { Card, Container, Stack } from '@mui/material';
import Page from '../../components/Page';
import ReportFilters from './ReportTable/ReportFilters';
import ConnectedTable from '../../components/ConnectedTable';
import columns from './ReportTable/ReportColumns';
import TranslatorReport from '../../models/TranslatorReport';
import PageHeader from '../../components/PageHeader';


const Reports: React.FC = () => {
    return (
        <Page title="Reports | WeTranslate" >
            <Container maxWidth={'lg'}>
                <PageHeader title={'Translator reports'}/>
                <Card>
                    <Stack gap={1} p={2}>
                        <ReportFilters />
                        <ConnectedTable<TranslatorReport>
                            queryKey={'translatorreports'}
                            idKey={'id'}
                            columns={columns}/>
                    </Stack>
                </Card>
            </Container>
        </Page>
    );
};

export default Reports;

