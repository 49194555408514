import { Button, Grid } from '@mui/material';
import React from 'react';
import { useFormikContext } from 'formik';
import { PriceForm } from './Prices';

const SaveButton: React.FC = () => {
    const { values } = useFormikContext<PriceForm>();

    if (values.offers.length === 0) {
        return null;
    }

    return <Grid item xs={12}>
        <Button type={'submit'} variant={'contained'} fullWidth>Save</Button>
    </Grid>;
};

export default SaveButton;
