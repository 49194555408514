import { Avatar, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import MessageEditor from '../MessageEditor';
import Messages from './Messages';
import SkeletonUser from '../SkeletonUser';
import { alpha } from '@mui/material/styles';
import useParticipant from '../useParticipant';
import useSeenConversation from './useSeenConversation';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AllowBrowserNotifications from '../AllowBroswerNotification/AllowBrowserNotification';

const Conversation: React.FC = () => {
    const { userId } = useParams();
    const { data: user, isLoading } = useParticipant();
    const { mutate: setSeen } = useSeenConversation();

    useEffect(() => {
        setSeen({ id: userId });
    }, [setSeen, userId]);

    if (isLoading) {
        return <Stack height={80} px={1} sx={{ borderBottom: (t) => `1px solid ${t.palette.divider}` }}>
            <SkeletonUser />
        </Stack>;
    }

    return <Stack justifyContent={'space-between'} height={'100%'} flexGrow={1} position={'relative'}>
        <Stack
            height={80}
            position={'sticky'}
            top={0}
            direction={'row'}
            px={1}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
                zIndex: 1,
                backgroundColor: (theme) => alpha(theme.palette.background.default, 0.72),
                backdropFilter: 'blur(6px)',
                borderBottom: (t) => `1px solid ${t.palette.divider}` }}>
            <ListItem>
                <ListItemIcon>
                    <Avatar sx={{ fontSize: 60 }} color={'green'}/>
                </ListItemIcon>
                <ListItemText primary={user?.firstname + ' ' + user?.lastname} secondary={user?.role} />
            </ListItem>

            <AllowBrowserNotifications/>

        </Stack>
        <Stack
            position={'absolute'}
            overflow={'scroll'}
            top={0}
            bottom={42}
            left={0} right={0}>
            <Messages />
        </Stack>
        <Stack>
            <MessageEditor />
        </Stack>
    </Stack>;
};

export default Conversation;
