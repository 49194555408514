import { API } from '../utils/ApiClient';
import { useSnackbar } from 'notistack';

function usePreviewFile(url: string): () => void {
    const { enqueueSnackbar } = useSnackbar();

    const previewFile = async () => {
        try {
            const res = await API.get(url);
            const file = res.data;
            const newWindow = window.open();
            if (newWindow) {
                newWindow.location.href = file;
            }
        } catch (e) {
            enqueueSnackbar('Could not preview file', { variant: 'error' });
        }
    };

    return previewFile;
}

export default usePreviewFile;
