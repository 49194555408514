/* eslint-disable operator-linebreak */
import { Box, Grid, IconButton, Slider, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, forwardRef, useMemo, useRef, useState } from 'react';
import { AnnotatedBlock, AnnotatedPage } from './AnnotationTypes';
import useEditor from './useEditor';
import { debounce } from 'lodash';
import { Icon } from '@iconify/react';
import plusCircleFill from '@iconify/icons-eva/plus-circle-fill';
import minusCircleFill from '@iconify/icons-eva/minus-circle-fill';
import { useTheme } from '@mui/material/styles';
import deleteFilled from '@iconify/icons-ant-design/delete-filled';
import { unescape } from 'lodash';
import getFontFamily from '../../utils/getFontFamily';
import textAlignCenter from '@iconify/icons-fe/text-align-center';
import textAlignJustify from '@iconify/icons-fe/text-align-justify';
import textAlignLeft from '@iconify/icons-fe/text-align-left';
import textAlignRight from '@iconify/icons-fe/text-align-right';
import roundFormatItalic from '@iconify/icons-ic/round-format-italic';
import roundFormatBold from '@iconify/icons-ic/round-format-bold';

type PropTypes = {
    block: AnnotatedBlock;
};

const Block = forwardRef<HTMLDivElement, PropTypes>(({ block }, ref) => {
    const {
        editorState: { selectedPage, selectedPageData, selectedBlockId, transformedData },
        setSelectedPageData,
        setTransformedData,
    } = useEditor();
    const theme = useTheme();
    const [textValue, setTextValue] = useState(block.textTranslated);
    const [blockWidth, setBlockWidth] = useState(Math.floor((block.width / (selectedPageData?.width || 1)) * 100));
    const fontFamily = useMemo(() => {
        return getFontFamily(selectedPageData?.language || '');
    }, [selectedPageData?.language]);
    const debouncedUpdate = useRef(
        debounce((newData: AnnotatedPage) => {
            setSelectedPageData(newData);
        }, 1000),
    ).current;

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!selectedPageData) return;

        const newTextValue = e.target.value;

        const editedBlock = selectedPageData.blocks.map((_block) => {
            if (_block.id === block.id) {
                return {
                    ...block,
                    textTranslated: newTextValue,
                };
            } else {
                return _block;
            }
        });

        setTextValue(newTextValue);

        debouncedUpdate({
            ...selectedPageData,
            blocks: editedBlock,
        } as AnnotatedPage);
    };

    const changeFontSize = (type: 'decrease' | 'increase') => {
        if (!selectedPageData) return;

        const oldFontSize = block.fontSize;
        const newFontSize = type === 'decrease' ? oldFontSize - 1 : oldFontSize + 1;

        if (newFontSize < 4) return;

        const editedBlock = selectedPageData.blocks.map((_block) => {
            if (_block.id === block.id) {
                return {
                    ...block,
                    fontSize: newFontSize,
                };
            } else {
                return _block;
            }
        });

        setSelectedPageData({
            ...selectedPageData,
            blocks: editedBlock,
        });
    };

    const changeWidth = (value: number) => {
        if (!selectedPageData) return;

        const newWidth = (value / 100) * selectedPageData.width;

        const editedBlock = selectedPageData.blocks.map((_block) => {
            if (_block.id === block.id) {
                return {
                    ...block,
                    width: newWidth,
                };
            } else {
                return _block;
            }
        });

        setBlockWidth(newWidth);

        setSelectedPageData({
            ...selectedPageData,
            blocks: editedBlock,
        });
    };

    const deleteBlock = () => {
        const newBlocks = selectedPageData?.blocks.filter((_block) => _block.id !== block.id);
        const newSelectedPageData: AnnotatedPage = {
            ...(selectedPageData as AnnotatedPage),
            blocks: newBlocks || [],
        };
        const newTransformedData = transformedData.map((data, index) => {
            if (selectedPage === index + 1) {
                return newSelectedPageData;
            }

            return data;
        });
        setSelectedPageData(newSelectedPageData);
        setTransformedData(newTransformedData);
    };

    const changeTextAlign = (align: AnnotatedBlock['textAlign']) => {
        if (!selectedPageData) return;

        const editedBlock = selectedPageData.blocks.map((_block) => {
            if (_block.id === block.id) {
                return {
                    ...block,
                    textAlign: align,
                };
            } else {
                return _block;
            }
        });

        setSelectedPageData({
            ...selectedPageData,
            blocks: editedBlock,
        });
    };

    const setFontWeight = () => {
        if (!selectedPageData) return;

        const editedBlock = selectedPageData.blocks.map((_block) => {
            if (_block.id === block.id) {
                const newFontWeigh: AnnotatedBlock['fontWeight'] = block?.fontWeight
                    ? block.fontWeight === 'bold'
                        ? 'normal'
                        : 'bold'
                    : 'normal';

                return {
                    ...block,
                    fontWeight: newFontWeigh,
                };
            } else {
                return _block;
            }
        });

        setSelectedPageData({
            ...selectedPageData,
            blocks: editedBlock,
        });
    };

    const setFontStyle = () => {
        if (!selectedPageData) return;

        const editedBlock = selectedPageData.blocks.map((_block) => {
            if (_block.id === block.id) {
                const newFontStyle: AnnotatedBlock['fontStyle'] = block?.fontStyle
                    ? block.fontStyle === 'italic'
                        ? 'normal'
                        : 'italic'
                    : 'normal';
                return {
                    ...block,
                    fontStyle: newFontStyle,
                };
            } else {
                return _block;
            }
        });

        setSelectedPageData({
            ...selectedPageData,
            blocks: editedBlock,
        });
    };

    return (
        <Box
            ref={ref}
            sx={{
                outlineWidth: selectedBlockId === block.id ? 3 : 1,
                outlineColor: selectedBlockId === block.id ? theme.palette.primary.main : '#c7c7c7',
                outlineStyle: `solid`,
                borderRadius: '10px',
            }}
        >
            <Grid
                sx={{
                    backgroundColor: '#fff',
                    fontSize: '13px',
                    borderRadius: '10px',
                }}
            >
                <Box sx={{ p: 1 }}>
                    <Typography
                        color={theme.palette.primary.main}
                        fontSize={12}
                        fontWeight={600}
                        mb={1}
                        textAlign="center"
                    >
                        Original text
                    </Typography>
                    <TextField
                        defaultValue={unescape(block.textOriginal)}
                        multiline
                        fullWidth
                        rows={7}
                        sx={{ backgroundColor: '#f1f1f1' }}
                        inputProps={{ readOnly: true, style: { fontSize: 13 } }}
                        size="small"
                    />
                </Box>

                <Box sx={{ p: 1 }}>
                    <Typography
                        color={theme.palette.primary.main}
                        fontSize={12}
                        fontWeight={600}
                        mb={1}
                        textAlign="center"
                    >
                        Translated text
                    </Typography>
                    <TextField
                        value={unescape(textValue || '')}
                        onChange={(e) => handleInputChange(e)}
                        multiline
                        fullWidth
                        rows={7}
                        inputProps={{ style: { fontSize: 13, fontFamily, textAlign: block.textAlign } }}
                        size="small"
                    />
                </Box>

                <Stack direction={'row'} alignItems="center" justifyContent="space-between" paddingX={2}>
                    <Stack direction={'row'}>
                        <IconButton
                            color={block.textAlign === 'left' ? 'primary' : 'default'}
                            onClick={() => changeTextAlign('left')}
                        >
                            <Icon icon={textAlignLeft} width="20" height="20" />
                        </IconButton>
                        <IconButton
                            color={block.textAlign === 'center' ? 'primary' : 'default'}
                            onClick={() => changeTextAlign('center')}
                        >
                            <Icon icon={textAlignCenter} width="20" height="20" />
                        </IconButton>
                        <IconButton
                            color={block.textAlign === 'right' ? 'primary' : 'default'}
                            onClick={() => changeTextAlign('right')}
                        >
                            <Icon icon={textAlignRight} width="20" height="20" />
                        </IconButton>
                        <IconButton
                            color={block.textAlign === 'justify' ? 'primary' : 'default'}
                            onClick={() => changeTextAlign('justify')}
                        >
                            <Icon icon={textAlignJustify} width="20" height="20" />
                        </IconButton>
                    </Stack>
                    <Stack direction={'row'}>
                        <IconButton
                            color={block.fontWeight === 'bold' ? 'primary' : 'default'}
                            onClick={() => setFontWeight()}
                        >
                            <Icon icon={roundFormatBold} width="20" height="20" />
                        </IconButton>
                        <IconButton
                            color={block.fontStyle === 'italic' ? 'primary' : 'default'}
                            onClick={() => setFontStyle()}
                        >
                            <Icon icon={roundFormatItalic} width="20" height="20" />
                        </IconButton>
                    </Stack>
                </Stack>

                <Stack direction={'row'} alignItems="center" paddingX={2}>
                    <Box fontSize={'14px'} mr={2}>
                        Width
                    </Box>
                    <Slider
                        size="medium"
                        defaultValue={blockWidth}
                        min={1}
                        max={100}
                        onChangeCommitted={(e, value) => changeWidth(value as number)}
                    />
                </Stack>

                <Stack direction={'row'} alignItems="center" px={2} pb={1}>
                    <Box fontSize={'14px'} mr={1} whiteSpace="nowrap">
                        Font Size
                    </Box>
                    <Stack direction="row" width="100%" alignItems="center">
                        <IconButton color="primary" onClick={() => changeFontSize('decrease')}>
                            <Icon icon={minusCircleFill} width="24" height="24" />
                        </IconButton>

                        <Box>{block.fontSize}</Box>

                        <IconButton color="primary" onClick={() => changeFontSize('increase')}>
                            <Icon icon={plusCircleFill} width="24" height="24" />
                        </IconButton>
                    </Stack>
                    <Box>
                        <IconButton color="error" onClick={() => deleteBlock()}>
                            <Icon icon={deleteFilled} width="24" height="24" />
                        </IconButton>
                    </Box>
                </Stack>
            </Grid>
        </Box>
    );
});

export default Block;
