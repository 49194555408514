/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { HTMLProps, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import LocalStorageManager from '../../utils/LocalStorageManager';
import { UserRole } from '../../enums/UserRole';

type RoleGuardProps = {
    permissions: UserRole[],
} & HTMLProps<HTMLDivElement>

export default function RoleGuard(props: RoleGuardProps): JSX.Element {
    const [isLoading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const localUser = LocalStorageManager.getUser();

    useEffect(() => {
        setLoading(true);

        if (!localUser) {
            navigate('/login');
        } else if (!props.permissions.includes(localUser.role!)) {
            navigate('/404');
        } else {
            setLoading(false);
        }
    }, [localUser]);
    return <>{!isLoading && props.children}</>;
}
