import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { API } from '../../../utils/ApiClient';
import TranslationBonus from '../../../models/TranslationBonus';


function useSaveBonuses() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation<{ status: number }, unknown, { bonuses: TranslationBonus[] }>({
        mutationFn: ({ bonuses }) => API.post('/bonuses', bonuses),
        onSuccess() {
            enqueueSnackbar('Bonuses saved', {
                variant: 'success',
            });
            queryClient.invalidateQueries(`bonuses`);
        },
    });
}


export default useSaveBonuses;
