import { TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import Task from '../../models/Task';

type CollapseRowType = {
    task: Task;
}

const StyledTableCell = styled(TableCell)(() => ({
    textAlign: 'center',
}));

export default function SummaryListCollapseRow({ task }: CollapseRowType): JSX.Element {
    return (
        <TableRow>
            <StyledTableCell>
                {task.sourceLanguage?.name}
            </StyledTableCell>
            <StyledTableCell>
                {task.targetLanguage?.name}
            </StyledTableCell>
            <StyledTableCell>
                {task.pages}
            </StyledTableCell>
            <StyledTableCell>
                {task.price ?? '-'} €
            </StyledTableCell>
        </TableRow>
    );
}
