import { Card, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import Form from '../../Form';
import PriceEditors from './PriceEditors';
import TranslationOffer from '../../../models/TranslationOffer';
import TranslatorPrice from '../../../models/TranslatorPrice';
import { useParams } from 'react-router-dom';
import useTranslatorOffers from './useTranslatorOffers';
import useSaveTranslationOffers from './useSaveTranslationOffers';
import SaveButton from './SaveButton';

export type TranslationOfferWithPrices = TranslationOffer & {
  translatorPrices: TranslatorPrice[]
  formType: string
};

export type PriceForm = {
  offers: TranslationOfferWithPrices[]
}

const Prices: React.FC = () => {
    const { id } = useParams();
    const { data: offers } = useTranslatorOffers(id);
    const { mutate } = useSaveTranslationOffers();

    const onSubmit = (form: PriceForm) => {
        mutate({ translationOffers: form.offers });
    };

    return <Stack gap={2}>
        <Card sx={{ p: 2 }}>
            <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
              The application checks the time difference
              between Under Translation and Approved statuses, then finds the lowest hours below
              matching languages. For example: A translation finishes in 3 working hours and your lowest given
              hour is 8, then the price will be calculated by prices set next to 8 hours.
            </Typography>
        </Card>
        <Form<PriceForm> onSubmit={onSubmit} initialValues={{ offers: offers ?? [] }}>
            <Grid container spacing={2}>
                <PriceEditors/>
                <SaveButton />
            </Grid>
        </Form>
    </Stack>;
};

export default Prices;
