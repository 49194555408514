import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';
import Suggestion from '../../../models/Suggestion';
import createQueryString from '../../../utils/createQueryString';

function useUpdateSuggestion(taskId?: string) {
    const queryClient = useQueryClient();

    return useMutation<{ status: number }, unknown, Suggestion, unknown>({
        mutationFn: (data) => API.put(`suggestions/${data.id}`, data ),
        onSuccess: () => {
            queryClient.invalidateQueries(['suggestions', createQueryString('', { taskId: taskId })]);
            queryClient.invalidateQueries(['tasks/', taskId]);
        },
    });
}

export default useUpdateSuggestion;
