import React, { useEffect } from 'react';
import { Card, Container, Grid, Stack, Box, Typography, Chip } from '@mui/material';
import { useParams } from 'react-router';
import useTask from '../useTask';
import Form from '../../../components/Form';
import Page from '../../../components/Page';
import TaskDetailEditor from '../TaskDetailEditor';
import Task from '../../../models/Task';
import { TaskStatus, taskStatusOptions, extendedTaskStatusOptions } from '../../../enums/TaskStatus';
import SelectField from '../../../components/Form/fields/SelectField';
import { LoadingButton } from '@mui/lab';
import useUpdateTask from '../useUpdateTask';
import TaskInfo from '../TaskInfo';
import TextField from '../../../components/Form/fields/TextField';
import CustomerPricing from '../CustomerPricing';
import FileByType from '../FilesByType';
import { FileType } from '../../../enums/FileType';
import Modifications from '../Modifications';
import TranslatorPricing from '../TranslatorPricing';
import CommentsSection from '../CommentsSection';
import TaskLoading from '../TaskLoading';
import PostalAddressEditor from '../PostalAddressEditor/PostalAddressEditor';
import Customer from '../../../models/Customer';
import { useQuery } from 'react-query';
import SuggestionSection from '../Suggestions';
import useSeenTask from '../useSeenTask';
import { UserRole } from '../../../enums/UserRole';
import useGetTaskWidgets from '../../../hooks/useGetTaskWidgets';
import WidgetGuard from './WidgetGuard';
import { TaskWidgetSlug } from '../../../enums/TaskWidgetSlug';


type AdminEditorProps = {
    users: { userId: string, roomId: string, name: string, role: UserRole }[] | undefined;
};

function AdminEditor({ users }: AdminEditorProps) {
    const { id: taskId } = useParams();
    const { data: task } = useTask(taskId);
    const { data: customer } = useQuery<Customer>(['customers/', task?.customerId], { enabled: !!task?.customerId });
    const { data: widgetsRoles, isLoading: loadingWidgets } = useGetTaskWidgets();
    const { mutate: updateTask } = useUpdateTask(taskId);
    const { mutate: setSeen } = useSeenTask();

    useEffect(() => {
        setSeen({ id: taskId });
    }, [setSeen, taskId]);

    if (!task || loadingWidgets) {
        return <TaskLoading />;
    }

    function onKeyDown(keyEvent: React.KeyboardEvent<HTMLFormElement>) {
        if (keyEvent.key === 'Enter' || keyEvent.key === '13') {
            keyEvent.preventDefault();
        }
    }

    return (
        <Page title="WeTranslate" >
            <Form<Task>
                validationSchema={undefined} onSubmit={updateTask} initialValues={task} onKeyDown={onKeyDown}>
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <WidgetGuard
                                    role={UserRole.ADMIN}
                                    widgetSlug={TaskWidgetSlug.GENERAL_INFO}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}>
                                    <Card sx={{ p: 2 }}>
                                        <TaskInfo task={task} isCustomer={false} isAdmin={true} showCreator />
                                    </Card>
                                </WidgetGuard>
                                <WidgetGuard
                                    role={UserRole.ADMIN}
                                    widgetSlug={TaskWidgetSlug.AUTOTRANSLATION_FILE}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}>
                                    <FileByType
                                        fileType={FileType.AUTOTRANSLATION}
                                        taskId={taskId}
                                        taskStatus={task.status} />
                                </WidgetGuard>
                                <WidgetGuard
                                    role={UserRole.ADMIN}
                                    widgetSlug={TaskWidgetSlug.TRANSLATED_FILE}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}>
                                    <FileByType
                                        fileType={FileType.TRANSLATED}
                                        taskId={taskId}
                                        taskStatus={task.status} />
                                </WidgetGuard>
                                <WidgetGuard
                                    role={UserRole.ADMIN}
                                    widgetSlug={TaskWidgetSlug.ORIGINAL_FILE}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}>
                                    <FileByType
                                        fileType={FileType.ORIGINAL}
                                        taskId={taskId}
                                        taskStatus={task.status} />
                                </WidgetGuard>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <WidgetGuard
                                    role={UserRole.ADMIN}
                                    widgetSlug={TaskWidgetSlug.TASK_DETAILS}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}>
                                    <Card sx={{ p: 2, bgcolor: 'background.neutral' }}>
                                        <TaskDetailEditor showTranslationOptions />
                                    </Card>
                                </WidgetGuard>
                                {task.hardCopyRequired && (
                                    <WidgetGuard
                                        role={UserRole.ADMIN}
                                        widgetSlug={TaskWidgetSlug.POSTAL_ADDRESS}
                                        widgetRoles={widgetsRoles}
                                        withApproval={false}>
                                        <Card>
                                            <PostalAddressEditor />
                                        </Card>
                                    </WidgetGuard>
                                )}
                                <WidgetGuard
                                    role={UserRole.ADMIN}
                                    widgetSlug={TaskWidgetSlug.CUSTOMER_PRICING}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}>
                                    <Card sx={{ p: 2, bgcolor: 'background.neutral' }}>
                                        <CustomerPricing taskId={taskId} />
                                    </Card>
                                </WidgetGuard>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <WidgetGuard
                                    role={UserRole.ADMIN}
                                    widgetSlug={TaskWidgetSlug.STATUS}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}>
                                    <SelectField
                                        options={customer?.mustApprovePrice ? extendedTaskStatusOptions : taskStatusOptions}
                                        name={'status'}
                                        label={'Status'}
                                    />
                                </WidgetGuard>

                                <LoadingButton
                                    fullWidth
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    type={'submit'}>
                                    Save task
                                </LoadingButton>

                                <WidgetGuard
                                    role={UserRole.ADMIN}
                                    widgetSlug={TaskWidgetSlug.COMMENTS}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}>
                                    <CommentsSection />
                                </WidgetGuard>

                                <WidgetGuard
                                    role={UserRole.ADMIN}
                                    widgetSlug={TaskWidgetSlug.TRANSLATOR_PRICING}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}>
                                    <Card sx={{ p: 2 }}>
                                        <TranslatorPricing />
                                    </Card>
                                </WidgetGuard>

                                <WidgetGuard
                                    role={UserRole.ADMIN}
                                    widgetSlug={TaskWidgetSlug.MODIFICATIONS}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}>
                                    <Modifications taskId={taskId} />
                                </WidgetGuard>

                                <WidgetGuard
                                    role={UserRole.ADMIN}
                                    widgetSlug={TaskWidgetSlug.SUGGESTION}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}>
                                    <SuggestionSection />
                                </WidgetGuard>

                                {task.hardCopyRequired && (
                                    <WidgetGuard
                                        role={UserRole.ADMIN}
                                        widgetSlug={TaskWidgetSlug.TRACKING_NUMBER}
                                        widgetRoles={widgetsRoles}
                                        withApproval={false}>
                                        <Card sx={{ p: 2 }}>
                                            <TextField name={'trackingNumber'} label={'Tracking number'} />
                                        </Card>
                                    </WidgetGuard>
                                )}

                                <WidgetGuard
                                    role={UserRole.ADMIN}
                                    widgetSlug={TaskWidgetSlug.MERCHANT_ID}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}>
                                    <Card sx={{ p: 2 }}>
                                        <TextField name={'merchantId'} label={'Merchant ID'} />
                                    </Card>
                                </WidgetGuard>

                                {(task.status === TaskStatus.PRICE_DECLINED || task.status === TaskStatus.INCORRECT_TRANSLATION) && task.explanation ? (
                                    <WidgetGuard
                                        role={UserRole.ADMIN}
                                        widgetSlug={TaskWidgetSlug.CUSTOMER_EXPLANATION}
                                        widgetRoles={widgetsRoles}
                                        withApproval={false}>
                                        <Card sx={{ p: 2 }}>
                                            <Box sx={{ m: 2 }}>
                                                <Typography variant='subtitle2'
                                                    color='text.secondary'
                                                    gutterBottom>Customer explanation for declined Task:</Typography>
                                                <Typography variant='subtitle2' sx={{ ml: 1 }}>{task.explanation}</Typography>
                                            </Box>
                                        </Card>
                                    </WidgetGuard>
                                ) : null}

                                <WidgetGuard
                                    role={UserRole.ADMIN}
                                    widgetSlug={TaskWidgetSlug.CURRENTLY_WATCHING}
                                    widgetRoles={widgetsRoles}
                                    withApproval={false}>
                                    <Card sx={{ p: 2 }}>
                                        <Stack>
                                            <Typography alignSelf={'flex-start'} variant='subtitle2' color='text.secondary' gutterBottom>Currently watching</Typography>
                                            <Grid container spacing={1}>
                                                {users && users.map((user) => (
                                                    <Grid item xs={6} key={user.userId}>
                                                        <Stack direction={'row'} gap={1}>
                                                            <Chip
                                                                color="info"
                                                                size="small"
                                                                label={`${user.name} (${user.role})`}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Stack>
                                    </Card>
                                </WidgetGuard>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Form>
        </Page>
    );
}

export default AdminEditor;
