import SelectField from '../../../Form/fields/SelectField';
import { taskTypes } from '../../../../enums/TranslationStyle';
import { Card, Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { UserRole } from '../../../../enums/UserRole';
import ProfileEditorForm from '../ProfileEditorForm';

const TaskType: React.FC = () => {
    const { values } = useFormikContext<ProfileEditorForm>();

    const isUserTranslator = values?.role === UserRole.TRANSLATOR;

    if (!isUserTranslator) {
        return null;
    }

    return <>
        <Grid item xs={12} >
            <Card sx={{ p: 2 }}>
                <Typography mb={2} variant={'h5'}>Task type</Typography>
                <SelectField name={'style'} label={'Task type'} options={taskTypes.map((type) => ({ id: type, name: type }))} />
            </Card>
        </Grid>
    </>;
};

export default TaskType;
