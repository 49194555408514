import {
    Box, Collapse, IconButton, Table, TableBody,
    TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDown from '@mui/icons-material/ArrowDropDown';
import ArrowUp from '@mui/icons-material/ArrowDropUp';
import moment from 'moment';
import React, { useState } from 'react';
import Task from '../../models/Task';
import Label from '../Label';
import SummaryListCollapseRow from './SummaryListCollapseRow';

type RowProps = {
    task: Task;
    isLight: boolean;
}

const StyledTableCell = styled(TableCell)(() => ({
    textAlign: 'center',
    minWidth: 80,
}));

const StyledTotalCell = styled(TableCell)(({ theme }) => ({
    color: theme.palette.primary.main,
    textAlign: 'center',
    minWidth: 80,
}));

export default function SummaryListRow({ task, isLight }: RowProps): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <TableRow key={task.id} onClick={() => setOpen((prev) => !prev)}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                    >
                        {open ? <ArrowUp /> : <ArrowDown/>}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {task.taskId}
                </TableCell>
                <TableCell>
                    {moment(task.created).format('DD/MM/YY HH:mm')}
                </TableCell>
                <TableCell>
                    {task.priority?.name}
                </TableCell>
                <TableCell>
                    {task.style}
                </TableCell>
                <TableCell>
                    {task.priceApprovedAt ?
                        moment(task.priceApprovedAt)?.format('MM/DD/YY hh:mm A') :
                        'No need to approve'}
                </TableCell>
                <TableCell>
                    {moment(task.finishedAt)?.format('MM/DD/YY hh:mm A')}
                </TableCell>
                <TableCell>
                    {moment(task.approvedAt)?.format('MM/DD/YY hh:mm A')}
                </TableCell>
                <TableCell>
                    {task.price ? task.price + ' €' : '-'}
                </TableCell>
                <TableCell>
                    {task.translatorPrice ? task.translatorPrice + ' €' : '-'}
                </TableCell>
                <TableCell>
                    <Label
                        variant={isLight ? 'ghost' : 'filled'}
                        taskStatus={task.status}
                    >
                        {task.status?.split('_').join(' ')}
                    </Label>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
                    <Collapse in={open} timeout='auto' sx={{ width: '100%' }}>
                        <Box sx={{ margin: 1 }}>
                            <Table size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Source language</StyledTableCell>
                                        <StyledTableCell>Target language</StyledTableCell>
                                        <StyledTableCell>Low page number</StyledTableCell>
                                        <StyledTableCell>High page number</StyledTableCell>
                                        <StyledTableCell>Low page price</StyledTableCell>
                                        <StyledTableCell>High page price</StyledTableCell>
                                        <StyledTotalCell>Total price</StyledTotalCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <SummaryListCollapseRow
                                        task={task}
                                    />
                                </TableBody>

                                {task.currentBonusAmount && (
                                    <>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Bonus (%)</StyledTableCell>
                                                <StyledTableCell>Bonus (€)</StyledTableCell>
                                                <StyledTableCell>Total</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <StyledTableCell>{task.currentBonusPercent} %</StyledTableCell>
                                                <StyledTableCell>{task.currentBonusAmount} €</StyledTableCell>
                                                <StyledTableCell>
                                                    {(task.price ?? 0) + Number(task.currentBonusAmount)} €
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </>
                                )}


                                {task.currentPenaltyAmount && (
                                    <>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Penalty (%)</StyledTableCell>
                                                <StyledTableCell>Penalty (€)</StyledTableCell>
                                                <StyledTableCell>Total</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <StyledTableCell>{task.currentPenaltyPercent ?? '-'} %</StyledTableCell>
                                                <StyledTableCell>{task.currentPenaltyAmount} €</StyledTableCell>
                                                <StyledTableCell>
                                                    {(task.price ?? 0) - Number(task.currentPenaltyAmount)} €
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </>
                                )}

                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Discount (%)</StyledTableCell>
                                        <StyledTableCell>Discount (€)</StyledTableCell>
                                        <StyledTableCell>Total</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell>{task.currentDiscountPercent ?? '-'} %</StyledTableCell>
                                        <StyledTableCell>{task.currentDiscountAmount ?? '-'} €</StyledTableCell>
                                        <StyledTableCell>{task.price ?? '-'} €</StyledTableCell>
                                    </TableRow>
                                    <TableRow><StyledTableCell/></TableRow>
                                    <TableRow>
                                        <StyledTableCell>
                                            <Typography variant='subtitle1' >
                                                Total price
                                            </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Typography variant='subtitle1'>
                                                {task.price ?? '-'} €
                                            </Typography>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
