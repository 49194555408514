import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';

// ----------------------------------------------------------------------

type NewPasswordFormProps = {
    setNewPassword: VoidFunction,
    handlePassword: (value: string) => void,
    handlePasswordAgain: (value: string) => void,
}

export default function ResetPasswordForm(props: NewPasswordFormProps): JSX.Element {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const handleShowPasswordConfirm = () => {
        setShowPasswordConfirm((show) => !show);
    };

    return (
        <form>
            <Stack spacing={3}>

                <TextField
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label="New password"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword} edge="end">
                                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => props.handlePassword(e.target.value)}
                />

                <TextField
                    fullWidth
                    type={showPasswordConfirm ? 'text' : 'password'}
                    label="New password again"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleShowPasswordConfirm} edge="end">
                                    <Icon icon={showPasswordConfirm ? eyeFill : eyeOffFill} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => props.handlePasswordAgain(e.target.value)}
                />

                <LoadingButton
                    sx={{ textTransform: 'none' }}
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={props.setNewPassword}
                >
                    Setup password
                </LoadingButton>
            </Stack>
        </form>
    );
}
