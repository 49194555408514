/* eslint-disable camelcase */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { makeUseAxios, UseAxios } from 'axios-hooks';
import { StatusCodes } from 'http-status-codes';
import config from '../config';
import TokenResponse from '../models/TokenResponse';
import TokenHelper, { Token } from '../utils/TokenHelper';

export type PasswordTokenRequest = {
    grant_type: string;
    username: string;
    password: string;
}

export type RefreshTokenRequest = {
    grant_type: string;
    refresh_token?: string;
}

export const ApiURL =
    config.api.port != 80 && config.api.port != 443 ?
        `${config.api.protocol}://${config.api.baseUrl}:${config.api.port}` :
        `${config.api.protocol}://${config.api.baseUrl}`;

// Instantiate axios object
export const API: AxiosInstance = axios.create({
    baseURL: ApiURL,
    timeout: config.timeout,
});


// Attach access token to all requests
API.interceptors.request.use(
    (requestConfig) => {
        if (!config.allowedEndpoints.includes(requestConfig.url ?? '')) {
            // Cancel all API requests if there's no access_token
            if (!TokenHelper.getToken(Token.ACCESS)) {
                console.log(`Redirected due to this response: ${!TokenHelper.getToken(Token.ACCESS)}`);
                throw new axios.Cancel();
            }
        }
        return setHeaders(requestConfig);
    },
    (error) => Promise.reject(error));


export const setHeaders = (requestConfig: AxiosRequestConfig): AxiosRequestConfig => {
    requestConfig.headers = {
        'Authorization': `Bearer ${TokenHelper.getToken(Token.ACCESS)}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return requestConfig;
};


// Redirect User to Login when receiving 401 Unauthorized response
API.interceptors.response.use(
    (result) => Promise.resolve(result),
    async (error) => {
        // Handle 401s
        if (error.config && error.response?.status === StatusCodes.UNAUTHORIZED && !error.config.__isRetry) {
            error.config.__isRetry = true;

            if (error.config.url !== '/oauth/token') {
                return await refreshToken()
                    .then(() => {
                        setHeaders(error.config);
                        return API.request(error.config);
                    })
                    .catch(() => {
                        TokenHelper.removeToken(Token.ACCESS);
                        TokenHelper.removeToken(Token.REFRESH);
                        localStorage.removeItem('user');
                        window.location.replace('/login');
                        throw new axios.Cancel();
                    });
            }
        // Handle 403s
        } else if (error.config && error.response?.status === StatusCodes.FORBIDDEN) {
            console.log('Access denied!');
            throw new axios.Cancel();
        }
        return Promise.reject(error.response);
    });

export const refreshToken = async (): Promise<boolean> => {
    const refreshToken = TokenHelper.getToken(Token.REFRESH);

    if (!refreshToken) {
        console.log(new Error('Refresh token not found.'));
        Promise.resolve(false);
    }

    const payload: RefreshTokenRequest = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
    };

    // Get new tokens
    return await API.post<TokenResponse>('/oauth/token', payload)
        .then(async (res) => {
            TokenHelper.setBoth(res.data);
            return Promise.resolve(true);
        })

        .catch((err) => {
            return Promise.reject(err);
        });
};


const useAPI: UseAxios = makeUseAxios();
useAPI.configure({ axios: API });

export default useAPI;
