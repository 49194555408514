import React from 'react';
import { Button, Grid } from '@mui/material';
import filter from '@iconify/icons-ant-design/filter-outline';
import { Icon } from '@iconify/react';
import User from '../../../models/User';
import useQueryString from '../../../hooks/useQueryString';
import Form from '../../../components/Form';
import AutoComplete from '../../../components/Form/fields/AutoComplete';
import YearMonthPicker from '../../../components/Form/fields/YearMonthPicker';
import useMe from '../../../hooks/useMe';


type ReportFilterForm = {
    userId?: string
    year?: string
    month?: string
}

const emptyFilters: ReportFilterForm = {
    userId: '',
    year: '',
    month: '',
};


function ReportFilters(): JSX.Element {
    const { data: me } = useMe();
    const [query, setQuery] = useQueryString();

    const onSubmit = (form: ReportFilterForm) => {
        setQuery({ ...emptyFilters, ...form });
    };

    const createFullName = (first?: string, last?: string) => {
        if (first && last) {
            return (`${first} ${last}`);
        }
        return 'Missing name';
    };

    return (
        <Form<ReportFilterForm>
            validationSchema={undefined}
            onSubmit={onSubmit}
            initialValues={{
                ...emptyFilters,
                ...query,
            }}
        >
            <Grid container spacing={1}>
                {me?.isAdmin && (
                    <Grid item xs={12} md={4}>
                        <AutoComplete name={'userId'} label={'Translator'} queryKey={'users?canLogin=true&role=translator'} queryOptions={{ select: (res: any) => res.users.map((user: User) => ({
                            id: user.id,
                            name: createFullName(user.firstname, user.lastname),
                        })) }} />
                    </Grid>
                )}
                <Grid item xs={12} md={4}>
                    <YearMonthPicker label={'Date'} monthProp={'month'} yearProp={'year'} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button sx={{ height: '100%' }} startIcon={<Icon icon={filter} />} fullWidth variant={'contained'} type={'submit'}>Filter</Button>
                </Grid>
            </Grid>
        </Form>
    );
}

export default ReportFilters;
