import { useQuery } from 'react-query';
import { TranslationOfferWithPrices } from './Prices';
import { API } from '../../../utils/ApiClient';
import TranslationOffer from '../../../models/TranslationOffer';
import Price from '../../../models/Price';

function useTranslatorOffers(id?: string) {
    return useQuery<TranslationOfferWithPrices[], unknown, TranslationOfferWithPrices[]>(['translationoffers', '?customerId=', id], async () => {
        const offers: { data: TranslationOffer[] } = await API.get('translationoffers?customerId=' + id);
        const pricesData: { data: Price[] } = await API.get('prices');
        return offers.data.map((offer) => {
            const prices = pricesData.data
                .filter((p) => p.translationOfferId === offer.id)
                .map((p) => ({ ...p, formType: 'original' }));
            return {
                ...offer,
                prices,
                formType: 'original',
            };
        });
    }, { enabled: !!id });
}

export default useTranslatorOffers;
