import React, { useState } from 'react';
import lodash from 'lodash';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import Task from '../models/Task';
import { UserRole } from '../enums/UserRole';
import useAPI from './ApiClient';
import { TaskStatus } from '../enums/TaskStatus';
import { MIconButton } from '../components/@material-extend';
import { LoadingButton } from '@mui/lab';
import PriceRejectionDialog from '../components/PriceRejectionDialog';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { useFormikContext } from 'formik';
import { FileType } from '../enums/FileType';
import useFilesByTask from '../pages/TaskEditor/FilesByType/useFilesByTask';

type StatusHandlerButtonsProps = {
    task: Task;
    taskId?: string;
    shouldApprovePrice: boolean;
    userId: string | undefined,
    role: UserRole;
    size?: 'small' | 'large';
    variant?: 'contained' | 'text';
}


// TEMPORARLY REPLACEMENT FOR STATUS BUTTONS
export default function StatusHandlerButtons({
    task,
    role,
    userId,
    shouldApprovePrice,
    size = 'large',
    variant = 'contained',
}: StatusHandlerButtonsProps): JSX.Element {
    const [, updateTask] = useAPI({ method: 'PUT' }, { manual: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { values: taskData } = useFormikContext<Task>();
    const { data: files } = useFilesByTask(FileType.TRANSLATED, task.id);

    const [open, setOpen] = useState(false);

    const handleCloseRejection = () => {
        setOpen(false);
    };

    const handleUpdate = (status: TaskStatus, explanation?: string) => {
        let ErrorMessage = '';

        if (!taskData.userId && status === TaskStatus.UNDER_TRANSLATION) {
            ErrorMessage = 'Please assign a translator';
        }
        if (status !== TaskStatus.INVALID_SOURCE_FILE && status !== TaskStatus.INVALID_LANGUAGE &&
            status !== TaskStatus.NEW_TASK) {
            if ((role === UserRole.ADMIN || role === UserRole.LINGUIST) &&
                status !== task.status && !taskData.pages) {
                ErrorMessage = 'Pages required';
            }
        }
        if (task.hardCopyRequired && status === TaskStatus.TRANSLATION_READY &&
            !taskData.trackingNumber) {
            ErrorMessage = 'Tracking number required';
        }
        if (files?.length === 0 && status === TaskStatus.TRANSLATION_READY &&
            !taskData.trackingNumber) {
            ErrorMessage = 'Please upload translation!';
        }
        if (ErrorMessage !== '') {
            enqueueSnackbar(ErrorMessage, {
                variant: 'error',
                action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                ),
            });
        } else {
            task.status = status;
            task.userId = userId;
            task.pages = taskData.pages;
            task.trackingNumber = taskData.trackingNumber;
            if (explanation) {
                task.explanation = explanation;
            }
            updateTask({ url: `/tasks/${task.id}`, data: task })
                .then(() => {
                    enqueueSnackbar('Task updated', {
                        variant: 'success',
                        action: (key) => (
                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        ),
                    });
                    navigate('/tasks');
                }).catch((e) => {
                    enqueueSnackbar(e.data?.error?.message ?? 'Failed to update task', {
                        variant: 'error',
                        action: (key) => (
                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        ),
                    });
                });
        }
    };

    // Get Buttons

    const getAdminButtons = () => {
        switch (task && task.status) {
        case TaskStatus.NEW_TASK:
            return (
                <>
                    {getButton('primary', shouldApprovePrice ?
                        TaskStatus.WAITING_FOR_PRICE_APPROVAL :
                        TaskStatus.UNDER_TRANSLATION)}
                    {getButton('error', TaskStatus.INVALID_SOURCE_FILE)}
                </>
            );

        case TaskStatus.WAITING_FOR_PRICE_APPROVAL:
            return (
                <>
                    {getButton('primary', task?.userId ?
                        TaskStatus.UNDER_TRANSLATION :
                        TaskStatus.READY_TO_TRANSLATE)}
                    {getButton('error', TaskStatus.PRICE_DECLINED)}
                </>
            );

        case TaskStatus.READY_TO_TRANSLATE:
            return (
                <>
                    {getButton('primary', TaskStatus.UNDER_TRANSLATION)}
                </>
            );

        case TaskStatus.UNDER_TRANSLATION:
            return (
                <>
                    {getButton('primary', TaskStatus.TRANSLATION_READY)}
                    {getButton('error', TaskStatus.INVALID_LANGUAGE)}
                </>
            );

        case TaskStatus.TRANSLATION_READY:
            return (
                <>
                    {getButton('primary', TaskStatus.APPROVED)}
                    {getButton('error', TaskStatus.INCORRECT_TRANSLATION)}
                </>
            );

        case TaskStatus.INVALID_SOURCE_FILE:
            return (
                <>
                    {getButton('primary', TaskStatus.NEW_TASK)}
                </>
            );

        case TaskStatus.INVALID_LANGUAGE:
            return (
                <>
                    {getButton('primary', TaskStatus.NEW_TASK)}
                </>
            );

        case TaskStatus.INCORRECT_TRANSLATION:
            return (
                <>
                    {getButton('primary', TaskStatus.NEW_TASK)}
                </>
            );

        case TaskStatus.PRICE_DECLINED:
            return (
                <>
                    {getButton('primary', TaskStatus.WAITING_FOR_PRICE_APPROVAL)}
                </>
            );

        default:
            return <></>;
        }
    };

    const getCustomerButtons = () => {
        switch (task && task.status) {
        case TaskStatus.WAITING_FOR_PRICE_APPROVAL:
            return (
                <>
                    {getButton('primary', TaskStatus.READY_TO_TRANSLATE)}
                    {getButton('error', TaskStatus.PRICE_DECLINED)}
                </>
            );

        case TaskStatus.INVALID_SOURCE_FILE:
            return (
                <>
                    {getButton('primary', TaskStatus.NEW_TASK)}
                </>
            );

        case TaskStatus.TRANSLATION_READY:
            return (
                <>
                    {getButton('primary', TaskStatus.APPROVED)}
                    {getButton('error', TaskStatus.INCORRECT_TRANSLATION)}
                </>
            );

        default:
            return <></>;
        }
    };

    const getLinguistButtons = () => {
        switch (task && task.status) {
        case TaskStatus.NEW_TASK:
            return (
                <>
                    {getButton('primary', shouldApprovePrice ?
                        TaskStatus.WAITING_FOR_PRICE_APPROVAL :
                        TaskStatus.UNDER_TRANSLATION)}
                    {getButton('error', TaskStatus.INVALID_SOURCE_FILE)}
                </>
            );

        case TaskStatus.UNDER_TRANSLATION:
            return (
                <>
                    {getButton('primary', TaskStatus.TRANSLATION_READY)}
                </>
            );

        case TaskStatus.READY_TO_TRANSLATE:
            return (
                <>
                    {getButton('primary', TaskStatus.UNDER_TRANSLATION)}
                </>
            );

        case TaskStatus.INCORRECT_TRANSLATION:
            return (
                <>
                    {getButton('primary', TaskStatus.TRANSLATION_READY)}
                </>
            );

        default:
            return <></>;
        }
    };

    const getTranslatorButtons = () => {
        switch (task && task.status) {
        case TaskStatus.UNDER_TRANSLATION:
            return (
                <>
                    {getButton('primary', TaskStatus.TRANSLATION_READY)}
                    {getButton('error', TaskStatus.INVALID_LANGUAGE)}
                </>
            );

        default:
            return <></>;
        }
    };

    const getButton = (color: 'primary' | 'error', status: TaskStatus) => {
        return (
            <Stack>
                <LoadingButton
                    fullWidth
                    variant={variant}
                    size={size}
                    color={color}
                    onClick={() => {
                        handleUpdate(status);
                    }}
                    sx={{ textAlign: 'center' }}
                >
                    Move to {lodash.capitalize(status.replaceAll('_', ' '))}
                </LoadingButton>
                <PriceRejectionDialog
                    open={open}
                    onClose={handleCloseRejection}
                    task={task}
                    onSubmit={handleUpdate}
                    status={status}
                />
            </Stack>
        );
    };

    const getTaskHandlerButtons = () => {
        switch (role) {
        case UserRole.ADMIN:
            return getAdminButtons();
        case UserRole.AGENT:
        case UserRole.USER:
            return getCustomerButtons();
        case UserRole.LINGUIST:
            return getLinguistButtons();
        case UserRole.TRANSLATOR:
            return getTranslatorButtons();
        default:
            return <></>;
        }
    };

    return getTaskHandlerButtons();
}

