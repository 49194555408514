import React, { useState } from 'react';
import { Card, Container, Grid, Stack, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Form from '../../../components/Form';
import Page from '../../../components/Page';
import Task from '../../../models/Task';
import { FileType } from '../../../enums/FileType';
import { LoadingButton } from '@mui/lab';
import CustomerSelector from '../CustomerSelector';
import TextField from '../../../components/Form/fields/TextField';
import PostalAddress from '../PostalAddress';
import TranslationTask from '../TranslationTask';
import Options from '../Options';
import CustomFile from '../../../interfaces/CustomFile';
import FileToTranslate from '../FileToTranslate';
import useCreateTask from '../useCreateTask';
import NewTaskSchema from '../NewTaskSchema';
import TaskLoading from '../../TaskEditor/TaskLoading';
import { useSnackbar } from 'notistack';

const AdminNewTask: React.FC = () => {
    const [files, setFiles] = useState<CustomFile[]>([]);
    const { mutate: createTask, isLoading } = useCreateTask(files);
    const { enqueueSnackbar } = useSnackbar();

    const onUpdateFiles = (files: File[]) => {
        setFiles(
            files.map<CustomFile>((f) => {
                return { id: uuidv4(), file: f, type: FileType.ORIGINAL };
            }),
        );
    };

    const onSubmit = (values: Task) => {
        if (files.length < 1) {
            enqueueSnackbar('At least one file must be selected', { variant: 'error' });
            return false;
        }

        createTask(values);
    };

    if (isLoading) {
        return <TaskLoading />;
    }

    return (
        <Page title='New Task | WeTranslate'>
            <Form<Task>
                validationSchema={NewTaskSchema}
                onSubmit={onSubmit}
                initialValues={new Task()}
            >
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <CustomerSelector />
                                <TranslationTask />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <Options />
                                <FileToTranslate
                                    files={files}
                                    onUpload={(files) => {
                                        onUpdateFiles(files);
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <PostalAddress />
                                <Card sx={{ p: 2 }}>
                                    <Stack gap={2}>
                                        <Typography variant='subtitle2' gutterBottom>
                                            Leave note
                                        </Typography>
                                        <TextField name={'note'} label={'Note about the task...'} />
                                    </Stack>
                                </Card>
                                <Card sx={{ p: 2 }}>
                                    <Stack gap={2}>
                                        <Typography variant='subtitle2' gutterBottom>
                                            Merchant ID
                                        </Typography>
                                        <TextField
                                            name={'merchantId'}
                                            label={'Set Merchant ID...'}
                                        />
                                    </Stack>
                                </Card>
                                <LoadingButton
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                    size='small'
                                >
                                    Save
                                </LoadingButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Form>
        </Page>
    );
};

export default AdminNewTask;
