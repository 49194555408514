/* eslint-disable react-hooks/exhaustive-deps */
import { AnnotatedBlock } from './AnnotationTypes';
import Draggable, { DraggableData } from 'react-draggable';
import useEditor from './useEditor';
import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { unescape } from 'lodash';
import getFontFamily from '../../utils/getFontFamily';

type PropTypes = {
    block: AnnotatedBlock;
    docSize: {
        width: number;
        height: number;
    };
    ratio: number;
};

const TranslatedText = ({ block, docSize, ratio }: PropTypes) => {
    const theme = useTheme();
    const [dragPosition, setDragPosition] = useState<any>(undefined);
    const {
        setSelectedBlockId,
        setSelectedScrollToId,
        setSelectedPageData,
        editorState: { selectedPageData, selectedBlockId },
    } = useEditor();
    const fontFamily = useMemo(() => {
        return getFontFamily(selectedPageData?.language || '');
    }, [selectedPageData?.language]);

    const { x: left, y: top } = block.axis[0];

    const handleClick = () => {
        setSelectedScrollToId(block.id);
        setSelectedBlockId(block.id);
    };

    const saveNewPosition = (e: any, data: DraggableData) => {
        if (!selectedPageData || (data.x === 0 && data.y === 0)) {
            return;
        }

        setDragPosition({ x: 0, y: 0 });

        const newBlocks: AnnotatedBlock[] = selectedPageData.blocks.map((_block) => {
            if (block.id === _block.id) {
                const newPosY = Math.floor((e?.target?.offsetTop + data.y) / ratio);
                const newPosX = Math.floor((e?.target?.offsetLeft + data.x) / ratio);

                const returnToOriginalPos = newPosY < 0 || newPosX < 0;

                return {
                    ...block,
                    axis: [
                        {
                            y: returnToOriginalPos ? _block.axis[0].y : newPosY,
                            x: returnToOriginalPos ? _block.axis[0].x : newPosX,
                        },
                        { ...block.axis[1] },
                        { ...block.axis[2] },
                        { ...block.axis[3] },
                    ],
                } as AnnotatedBlock;
            }
            return _block;
        });

        setSelectedPageData({
            ...selectedPageData,
            blocks: [...newBlocks],
        });
    };

    return (
        <Draggable bounds="parent" onStop={(e, data) => saveNewPosition(e, data)} position={dragPosition}>
            <Box
                className={`draggable-text ${selectedBlockId === block.id ? 'marked' : ''}`}
                onClick={handleClick}
                style={{
                    cursor: 'grab',
                    display: 'inline-block',
                    position: 'absolute',
                    top: Math.ceil(top * ratio),
                    left: Math.ceil(left * ratio),
                    width: Math.ceil(ratio * block.width),
                    overflow: 'hidden',
                    borderColor: theme.palette.primary.main,
                    lineHeight: 1.3,
                    fontFamily: fontFamily,
                    letterSpacing: 'normal',
                    fontSize: ratio * block.fontSize,
                    textAlign: block.textAlign,
                    fontWeight: block.fontWeight,
                    fontStyle: block.fontStyle,
                }}
            >
                {unescape(block.textTranslated || '')}
            </Box>
        </Draggable>
    );
};

export default TranslatedText;
