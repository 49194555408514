import React, { ReactElement, ReactNode } from 'react';
import baselineEuro from '@iconify/icons-ic/baseline-euro';
import baselinePenalty from '@iconify/icons-ic/baseline-fmd-bad';
import baselineImportant from '@iconify/icons-ic/baseline-label-important';
import baselineList from '@iconify/icons-ic/baseline-list';
import baselineDiscount from '@iconify/icons-ic/baseline-percentage';
import baselineExtra from '@iconify/icons-ic/baseline-star';
import baselineUser from '@iconify/icons-ic/baseline-supervised-user-circle';
import { Icon } from '@iconify/react';
import Bonuses from '../../components/customer/Bonuses/Bonuses';
import Details from '../../components/customer/Details/Details';
import Discounts from '../../components/customer/Discounts/Discounts';
import Employees from '../../components/customer/employees/Employees';
import Penalty from '../../components/customer/Penalty/Penalty';
import Prices from '../../components/customer/Prices/Prices';
import Priorities from '../../components/customer/Priorities/Priorities';
import Customer from '../../models/Customer';

type CustomerTab = {
    value: string
    icon: ReactElement
    component: ReactNode
}

function getCustomerTabs(customer?: Customer): CustomerTab[] {
    const CUSTOMER_TABS = [
        {
            value: 'Details',
            icon: <Icon icon={baselineList} width={20} height={20} />,
            component: <Details customer={customer} />,
        },
        {
            value: 'Employees',
            icon: <Icon icon={baselineUser} width={20} height={20} />,
            component: <Employees />,
        },
        {
            value: 'Priorities',
            icon: <Icon icon={baselineImportant} width={20} height={20} />,
            component: <Priorities />,
        },
        {
            value: 'Prices',
            icon: <Icon icon={baselineEuro} width={20} height={20} />,
            component: <Prices />,
        },
        {
            value: 'Discounts',
            icon: <Icon icon={baselineDiscount} width={20} height={20} />,
            component: <Discounts customer={customer} />,
        },
        {
            value: 'Extras',
            icon: <Icon icon={baselineExtra} width={20} height={20} />,
            component: <Bonuses />,
        },
        {
            value: 'Penalty',
            icon: <Icon icon={baselinePenalty} width={20} height={20} />,
            component: <Penalty customer={customer} />,
        },
    ];

    return CUSTOMER_TABS;
}

export default getCustomerTabs;
