import { API } from '../utils/ApiClient';
import { useSnackbar } from 'notistack';

function useDownloadFile(url: string): () => void {
    const { enqueueSnackbar } = useSnackbar();

    const downloadFile = async () => {
        try {
            const file = await API.get(url);
            location.href = file.data;
        } catch (e) {
            enqueueSnackbar('Could not download file', { variant: 'error' });
        }
    };

    return downloadFile;
}

export default useDownloadFile;
