import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import Priority from '../../../models/Priority';
import { API } from '../../../utils/ApiClient';


function useSavePriorities() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ priorities }: {priorities: Priority[]}) => API.post('/priorities/bulkcreate', priorities),
        onSuccess() {
            enqueueSnackbar('Priorities saved', {
                variant: 'success',
            });
            queryClient.invalidateQueries(`priorities`);
        },
    });
}


export default useSavePriorities;
