import { useQuery } from 'react-query';
import User from '../models/User';
import { UserRole } from '../enums/UserRole';


type RoleInfo = {
    isAdmin: boolean
    isAgent: boolean
    isTranslator: boolean
    isLinguist: boolean
    isAdminOrCustomer: boolean
    isUser: boolean
    role: UserRole
}

function useMe() {
    return useQuery<User, unknown, User & RoleInfo>('me', {
        staleTime: Infinity,
        select(res) {
            return {
                ...res,
                isAdmin: res.role === UserRole.ADMIN,
                isAgent: res.role === UserRole.AGENT,
                isTranslator: res.role === UserRole.TRANSLATOR,
                isLinguist: res.role === UserRole.LINGUIST,
                isUser: res.role === UserRole.USER,
                isAdminOrCustomer: res.role !== UserRole.LINGUIST && res.role !== UserRole.TRANSLATOR,
                role: res.role ?? UserRole.USER,
            };
        },
    });
}

export default useMe;
