import { Accordion, AccordionDetails, AccordionSummary, Card, Stack, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import TextField from '../../../components/Form/fields/TextField';
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import Task from '../../../models/Task';
import Customer from '../../../models/Customer';
import { useQuery } from 'react-query';

const PostalAddress: React.FC = () => {
    const { values: task, setFieldValue } = useFormikContext<Task>();
    const { data: customer } = useQuery<Customer>(['customers/', task?.customerId], { enabled: !!task?.customerId });

    useEffect(() => {
        if (!customer) return;
        setFieldValue('postalAddress.name', customer?.postalAddress?.name );
        setFieldValue('postalAddress.zip', customer?.postalAddress?.zip );
        setFieldValue('postalAddress.country', customer?.postalAddress?.country );
        setFieldValue('postalAddress.state', customer?.postalAddress?.state );
        setFieldValue('postalAddress.city', customer?.postalAddress?.city );
        setFieldValue('postalAddress.address', customer?.postalAddress?.address );
    }, [task.customerId, customer?.postalAddress, customer, setFieldValue]);

    if (!task.hardCopyRequired) return <></>;

    return (
        <Card>
            <Accordion>
                <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}>
                    <Typography variant='subtitle2'>Postal Address</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={2}>
                        <TextField label={'Name'} name={'postalAddress.name'} />
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                            <TextField label={'ZIP'} name={'postalAddress.zip'} />
                            <TextField label={'Country'} name={'postalAddress.country'} />
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                            <TextField label={'State'} name={'postalAddress.state'} />
                            <TextField label={'City'} name={'postalAddress.city'} />
                        </Stack>
                        <TextField label={'Address'} name={'postalAddress.address'} />
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

export default PostalAddress;
