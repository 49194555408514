import * as Yup from 'yup';

const NewTaskSchema = Yup.object().shape({
    customerId: Yup.string().required('Required'),
    sourceLanguageId: Yup.string().required('Required'),
    targetLanguageId: Yup.string().required('Required'),
    sureness: Yup.string().required('Required'),
    style: Yup.string().required('Required'),
    priorityId: Yup.string().required('Required'),
});

export default NewTaskSchema;
