import React, { ReactNode, createContext, useState, useEffect, useContext } from 'react';
import { io, Socket } from 'socket.io-client';
import config from '../config';
import getAccessToken from '../utils/getAccessToken';
import useMe from '../hooks/useMe';
import useTaskParseUpdate from '../hooks/useTaskParseUpdate';
import useMessagingSubscription from '../hooks/useMessagingSubscription';
import useAutomaticPriceCalculationUpdate from '../hooks/useAutomaticPriceCalculationUpdate';


const SocketContext = createContext<Socket | null>(null);

type SocketProviderProps = {
    children: ReactNode;
};

const useSocket = () => useContext(SocketContext);
export default useSocket;

function SocketProvider({ children }: SocketProviderProps): JSX.Element {
    const [socket, setSocket] = useState<Socket | null>(null);
    const { data } = useMe();

    // Add top level socket listeners
    useTaskParseUpdate(socket);
    useAutomaticPriceCalculationUpdate(socket);
    useMessagingSubscription(socket);

    // Initialize socket connection
    useEffect(() => {
        if (!data) {
            return;
        }

        const token = getAccessToken();
        const socket = io(`${config.api.socketProtocol}://${config.api.baseUrl}:${config.api.port}`, {
            transports: ['websocket'],
            query: { token },
        });
        setSocket(socket);
    }, [data]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
}

export { SocketProvider, SocketContext };
