import React from 'react';
import { Button, Grid } from '@mui/material';
import filter from '@iconify/icons-ant-design/filter-outline';
import { Icon } from '@iconify/react';
import useQueryString from '../../../hooks/useQueryString';
import TextField from '../../../components/Form/fields/TextField';
import Form from '../../../components/Form';


type FilterForm = {
    name: string
}

const emptyFilters: FilterForm = {
    name: '',
};


const Filters: React.FC = () => {
    const [query, setQuery] = useQueryString();

    const onSubmit = (form: FilterForm) => {
        setQuery({ ...emptyFilters, ...form });
    };

    return (
        <Form<FilterForm>
            onSubmit={onSubmit}
            initialValues={{
                ...emptyFilters,
                ...query,
            }}
        >
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={6} />
                <Grid item xs={3}>
                    <TextField label={'Name'} name={'name'} />
                </Grid>
                <Grid item xs={3}>
                    <Button sx={{ height: '100%' }} startIcon={<Icon icon={filter} />} fullWidth variant={'contained'} type={'submit'}>Filter</Button>
                </Grid>
            </Grid>
        </Form>
    );
};

export default Filters;
