import { Badge, Box, Tab, Tabs as MuiTabs, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Icon } from '@iconify/react';
import pendingActions from '@iconify/icons-ic/baseline-pending-actions';
import baselinePending from '@iconify/icons-ic/baseline-pending';
import baselineDone from '@iconify/icons-ic/baseline-done';
import baselineStop from '@iconify/icons-ic/baseline-stop-circle';
import useQueryString from '../../hooks/useQueryString';
import newRelease from '@iconify/icons-ic/outline-new-releases';
import { useQuery } from 'react-query';
import { UserRole } from '../../enums/UserRole';
import useMe from '../../hooks/useMe';
import { useNavigate } from 'react-router';


const getTabs = (role: UserRole) => [
    {
        label: 'Action needed',
        icon: <Icon icon={pendingActions} width={20} height={20} />,
        value: 'actionNeeded',
        seenProp: true,
    },
    {
        label: 'New tasks',
        icon: <Icon icon={newRelease} width={20} height={20} />,
        value: 'newTask',
        seenProp: false,
        isHidden: [UserRole.TRANSLATOR, UserRole.LINGUIST].includes(role),
    },
    {
        label: 'In progress',
        icon: <Icon icon={baselinePending} width={20} height={20} />,
        value: 'inProgress',
        seenProp: false,
    },
    {
        label: 'Finished',
        icon: <Icon icon={baselineDone} width={20} height={20} />,
        value: 'finished',
        seenProp: false,
    },
    {
        label: 'On hold',
        icon: <Icon icon={baselineStop} width={20} height={20} />,
        value: 'onHold',
        seenProp: false,
    },
    {
        label: 'All',
        icon: <Icon icon={'bi:list-check'} width={20} height={20} />,
        value: '',
        seenProp: false,
    },
];

const Tabs: React.FC = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useQueryString();
    const { data: me } = useMe();

    useEffect(() => {
        if (query['section'] === undefined) {
            setQuery({ section: 'actionNeeded' });
        }
    }, [navigate, query, setQuery]);

    const { data } = useQuery<{ actionNeeded: number }>('tasks/list/unseen', { refetchInterval: 10000 });

    const tabs = useMemo(() => {
        if (!me?.role) {
            return [];
        }
        return getTabs(me?.role);
    }, [me?.role]);

    return <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <MuiTabs
                sx={{ backgroundColor: (t) => t.palette.background.neutral, px: 1 }}
                value={query['section'] ?? tabs[0]?.value}
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
                onChange={(e, value) => setQuery({ 'section': value })}
            >
                {tabs.filter((t) => !t.isHidden).map((tab) => (
                    <Tab
                        key={tab.value}
                        sx={{ p: 1 }}
                        disableRipple
                        label={
                            tab.seenProp ? (
                                <Badge
                                    variant={'standard'}
                                    badgeContent={data?.actionNeeded}
                                    color="error">
                                    <Typography fontSize={12} fontWeight={700}>{tab.label}</Typography>
                                </Badge>
                            ) : (
                                <Typography fontSize={12} fontWeight={700}>{tab.label}</Typography>
                            )
                        }
                        icon={tab.icon}
                        value={tab.value}
                    />
                ))}

            </MuiTabs>
        </Box>
    </>;
};

export default Tabs;
