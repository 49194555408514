import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';

function useSeenConversation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }: { id?: string }) => API.put(`/conversations/${id}/seen`),
        onSuccess() {
            queryClient.invalidateQueries('messages');
            queryClient.invalidateQueries('conversations?name=');
            queryClient.invalidateQueries('conversations/number-of-unseen');
        },
    });
}

export default useSeenConversation;
