import { Grid } from '@mui/material';
import KeyFigure from './widgets/KeyFigure';
import { ColorSchema } from '../../../@types/theme';
import useCustomers from '../../../hooks/useCustomers';
import { useQuery } from 'react-query';


type KeyFigureType = {
    title: string;
    total: number | string;
    icon: string;
    color?: ColorSchema;
}

const KeyFigures: React.FC = () => {
    const { data: taskData } = useQuery<{ totalCount: number }>('tasks');
    const { data: customers } = useCustomers();
    const { data: users } = useQuery<{ totalCount: number }>('users?role=translator');

    const keyFigures: KeyFigureType[] = [
        { total: taskData?.totalCount ?? '-', title: 'Tasks in the system', icon: 'fluent:clipboard-task-list-rtl-20-filled', color: 'success' },
        { total: customers?.length ?? '-', title: 'Customers in the system', icon: 'bi:people-fill', color: 'info' },
        { total: users?.totalCount ?? '-', title: 'Translators in the system', icon: 'bi:people-fill', color: 'warning' },
    ];

    return <>
        {keyFigures.map((keyFigure) => (
            <Grid item xs={12} sm={6} md={4} key={keyFigure.title}>
                <KeyFigure
                    total={keyFigure.total}
                    title={keyFigure.title}
                    icon={keyFigure.icon}
                    color={keyFigure.color} />
            </Grid>
        ))}
    </>;
};

export default KeyFigures;
