/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useField } from 'formik';


type SelectMultipleProps = {
    name: string;
    label: string;
    options: string[]
}

const SelectMultiple: React.FC<SelectMultipleProps> = ({ name, label, options }) => {
    const [field] = useField(name);

    const value = typeof field.value === 'string' ? [field.value] : field.value;

    return useMemo(() => {
        return (
            <Autocomplete
                size={'small'}
                onChange={(e, value) => {
                    field.onChange({
                        target: {
                            name,
                            value,
                        },
                    });
                }}
                value={value}
                multiple
                id="tags-standard"
                options={options}
                renderInput={({ size, ...params }) => (
                    <TextField
                        size={'small'}
                        {...params}
                        variant="outlined"
                        label={label}
                    />
                )}
            />
        );
    }, [field.value, field.onChange, label, name, options]);
};

export default SelectMultiple;
