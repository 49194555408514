export default function TablePagination() {
    return {
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    borderTop: '1px solid transparent',
                },
            },
        },
    };
}
