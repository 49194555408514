/* eslint-disable quote-props */
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Button, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useEditor from './useEditor';
import useUpdateAnnotation from './useUpdateAnnotation';
import settingFilled from '@iconify/icons-ant-design/setting-filled';
import closeFill from '@iconify/icons-eva/close-fill';
import { MIconButton } from '../../components/@material-extend';
import { Icon } from '@iconify/react';

type PropTypes = {
    open: boolean;
    handleOpen: () => void;
    handleClose: () => void;
};

const Actions = ({ open, handleOpen, handleClose }: PropTypes) => {
    const {
        editorState: { transformedData },
    } = useEditor();
    const navigate = useNavigate();
    const { id: taskId, fileId } = useParams();
    const { mutateAsync } = useUpdateAnnotation(taskId as string, fileId as string);

    const onCancel = () => {
        navigate(`/tasks/${taskId}/edit`);
    };

    const onSave = async () => {
        await mutateAsync(transformedData);
    };

    const handleClick = () => {
        if (open) {
            handleClose();
        } else {
            handleOpen();
        }
    };

    return (
        <Stack
            width={'100%'}
            direction={'row'}
            alignItems='center'
            justifyContent='space-between'
            gap={2}
            flex={'flex'}
            py={2}
            px={2}
            sx={{
                position: 'sticky',
                top: 0,
                background: 'white',
                zIndex: 10,
                borderBottom: '1px solid #efefef',
            }}
        >
            <Box
                sx={{
                    p: 0.5,
                    px: '4px',
                    color: 'grey.800',
                    zIndex: 100,
                    backgroundColor: '#b4b4b473',
                    borderRadius: '16px 24px 16px 24px',
                    boxShadow: (theme) => theme.customShadows.z12,
                }}
            >
                <MIconButton
                    color='inherit'
                    onClick={handleClick}
                    sx={{
                        p: 0,
                        width: 40,
                        height: 40,
                        transition: (theme) => theme.transitions.create('all'),
                        '&:hover': { color: 'primary.main', bgcolor: 'transparent' },
                    }}
                >
                    <Icon icon={open ? closeFill : settingFilled} width={20} height={20} />
                </MIconButton>
            </Box>
            <Box>
                <Button onClick={onCancel} color='primary'>
                    Cancel
                </Button>
                <Button onClick={onSave} sx={{ ml: 3 }} variant='contained' color='primary'>
                    Save
                </Button>
            </Box>
        </Stack>
    );
};

export default Actions;
