import AreaChartMonthlyBreakDown from '../../../../../components/charts/AreaChartMonthlyBreakDown';
import { Card, Skeleton } from '@mui/material';
import { useQuery } from 'react-query';
import ChartData from '../../../../../components/charts/ChartData';
import moment from 'moment';
import config from '../../../../../config';
import numeral from 'numeral';

const RevenueAndExpenditure: React.FC = () => {
    const nowDate = moment().add(1, 'days').format('YYYY-MM-DD');
    const { data } = useQuery<ChartData>(`/dashboard/revenue-and-expenditure?after=${config.statisticsStartDate}&before=${nowDate}`);

    if (!data) {
        return <Card>
            <Skeleton height={400} />
        </Card>;
    }

    return <Card>
        <AreaChartMonthlyBreakDown
            valueFormatter={function(value: number) {
                return '€ ' + numeral(value / 1000).format('0,0[.]00') + 'k';
            }}
            subTitle={'in Thousand EUR'}
            title={'Revenue & Expenditure'}
            chartData={data}
            customLegendItems={['Revenue', 'Expenditure']} />
    </Card>;
};

export default RevenueAndExpenditure;
