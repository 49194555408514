import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { API } from '../../../utils/ApiClient';
import Customer from '../../../models/Customer';
import TranslationPenalty from '../../../models/TranslationPenalty';


function useSavePenalty(customer?: Customer) {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation<{ status: number }, unknown, TranslationPenalty, unknown>({
        mutationFn: (form) => API.put(`/penalties/${customer?.translationPenaltyId}`, form),
        onSuccess() {
            queryClient.invalidateQueries(`/penalties/${customer?.translationPenaltyId}}`);
            enqueueSnackbar('Settings saved', {
                variant: 'success',
            });
        },
    });
}


export default useSavePenalty;
