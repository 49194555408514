import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import getBaseOptionChart from '../../theme/getBaseOptionChart';


type TreeMapMonoChromeType = {
    chartData: {
       y: number
       x: string
    }[]
    title: string
  valueFormatter?: (value: number) => string

}

const TreeMapMonoChrome: React.FC<TreeMapMonoChromeType> = ({ chartData, valueFormatter, title }) => {
    const theme = useTheme();
    const options: ApexOptions = {
        ...getBaseOptionChart(theme),
        legend: {
            show: true,
        },
        dataLabels: { enabled: true },
        chart: {
            redrawOnParentResize: true,
            toolbar: {
                show: false,
            },
            height: 350,
            type: 'treemap',
        },
        yaxis: {
            show: false,
            labels: {
                formatter: valueFormatter,
            },
        },
        plotOptions: {
            ...getBaseOptionChart(theme).plotOptions,
            treemap: {
                distributed: false,
                enableShades: true,
                colorScale: {
                    ranges: [
                        {
                            from: -6,
                            to: 0,
                        },
                        {
                            from: 0.001,
                            to: 6,
                        },
                    ],
                },
            },
        },
    };


    return <>
        <Typography variant={'h6'} ml={2} mt={2}>{title}</Typography>
        <Stack mb={'-16px'} sx={{
            '& rect': {
                stroke: 'transparent',
            },
            '& .apexcharts-series.apexcharts-treemap-series': {
                transform: 'scaleX(1.05)',
            },
            '& .apexcharts-tooltip.apexcharts-theme-light': {
                backgroundColor: theme.palette.background.paper,
                transform: 'translateY(-32px)',
            },
        }}>
            <ReactApexChart options={options} series={[{ data: chartData }]} type="treemap" height={330} />
        </Stack>
    </>;
};

export default TreeMapMonoChrome;
