import UserEvent from '../../../models/UserEvent';
import { ListItem, ListItemIcon, Stack, Tooltip, Typography } from '@mui/material';
import lodash from 'lodash';
import moment from 'moment';
import React from 'react';
import { EventAvailableOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import EventLogTooltip from './EventLogTooltip';
import { motion } from 'framer-motion';


const EventLog: React.FC<{ event: UserEvent & { isNew: boolean } }> = ({ event }) => {
    const date = moment(event.created).format('MM/DD/YY HH:mm');
    const navigate = useNavigate();


    const navigateToTask = (taskId?: string) => {
        navigate('/tasks/' + taskId + '/edit');
    };


    return (
        <Tooltip placement={'right-start'} title={<EventLogTooltip event={event} />}>
            <motion.div initial={{ opacity: event.isNew ? 0 : 1 }} animate={{ opacity: 1 }}>
                <ListItem
                    button onClick={() => navigateToTask(event.taskId)}>
                    <ListItemIcon>
                        <EventAvailableOutlined color={event.isNew ? 'primary' : undefined} fontSize={'small'} />
                    </ListItemIcon>
                    <Stack>
                        <Typography
                            color={event.isNew ? 'primary' : undefined}
                            variant={'caption'} fontWeight={700} fontSize={12}>
                            {lodash.startCase(lodash.toLower(event.action))} {' '}
                        </Typography>
                        <Typography variant={'caption'} color={'text.secondary'} fontSize={10}>{event.username}</Typography>
                        <Typography variant={'caption'} color={'text.secondary'} fontSize={10}>{event.task?.taskId}, {date}</Typography>
                    </Stack>
                </ListItem>
            </motion.div>
        </Tooltip>
    );
};


export default EventLog;
