import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';

function useDeleteBonus() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }: {id?: string}) => API.delete('/bonuses/' + id),
        onSuccess() {
            queryClient.invalidateQueries('bonuses');
        },
    });
}

export default useDeleteBonus;
