import Task from '../../../models/Task';
import { IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { UserRole } from '../../../enums/UserRole';
import { Icon as Iconify } from '@iconify/react';
import checkMarkIcon from '@iconify/icons-eva/checkmark-circle-2-outline';
import FormDialog from '../../../components/FormDialog';
import TextField from '../../../components/Form/fields/TextField';
import useUpdateTask from './useUpdateTask';
import { TaskStatus } from '../../../enums/TaskStatus';

const Price: React.FC<{ task: Task, role: UserRole }> = ({ task, role }) => {
    const { mutate: updateTask } = useUpdateTask();

    const showApproveOrDeclineAction = task.status === TaskStatus.WAITING_FOR_PRICE_APPROVAL && [UserRole.AGENT, UserRole.USER].includes(role);

    const declinePrice = (form?: { explanation: string }) => {
        updateTask({ ...task, status: TaskStatus.PRICE_DECLINED, explanation: form?.explanation });
    };

    const approvePrice = () => {
        const status = task.userId ? TaskStatus.UNDER_TRANSLATION : TaskStatus.READY_TO_TRANSLATE;
        updateTask({ ...task, status });
    };


    return <Stack direction={'row'} alignItems={'center'}>
        <Typography noWrap fontSize={12} mr={0.5}>{task.price ? task.price + ' €' : '-'}</Typography>
        {showApproveOrDeclineAction && (
            <>
                <IconButton size={'small'} onClick={approvePrice}>
                    <Iconify
                        fontSize={12}
                        style={{ color: 'green' }}
                        icon={checkMarkIcon}/>
                </IconButton>
                <FormDialog<{ explanation: string }>
                    submitLabel={'Send'}
                    trigger={(
                        <IconButton color="error" size={'small'}>
                            <Iconify fontSize={12} icon={'eva:close-circle-outline'}/>
                        </IconButton>
                    )}
                    initialValues={{ explanation: '' }}
                    title={'Are you sure?'}
                    content={
                        <>
                            <Typography>Please tell us why do you want to reject the Task?</Typography>
                            <TextField name={'explanation'} label={'Explanation'} />
                        </>

                    }
                    onSubmit={declinePrice}/>
            </>
        )}
    </Stack>;
};

export default Price;
