import edit2Fill from '@iconify/icons-eva/edit-2-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import {
    MenuItem,
    Typography, Stack, Grid,
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import useDeleteHoliday from '../useDeleteHoliday';
import { API } from '../../../../../utils/ApiClient';
import ControlledDialog from '../../../../ControlledDialog';
import Holiday from '../../../../../models/Holiday';
import FormDialog from '../../../../FormDialog';
import TextField from '../../../../Form/fields/TextField';
import DatePicker from '../../../../Form/fields/DatePicker';

type ActionsProps = {
    holiday: Holiday
};

const Actions = ({ holiday }: ActionsProps): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { mutate: updateHoliday } = useMutation({
        mutationFn: (holiday?: Holiday) => API.put('holidays/' + holiday?.id, holiday),
        onSuccess() {
            enqueueSnackbar('Holiday updated', { variant: 'success' });
            queryClient.invalidateQueries('holidays');
        },
    });
    const { mutate: deleteHoliday } = useDeleteHoliday();

    return (
        <Stack direction='row' gap={1}>
            <FormDialog
                content={<Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField name={'name'} label={'Name'} />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker name={'date'} label={'Date'} />
                    </Grid>
                </Grid>}
                onSubmit={(holiday) => updateHoliday(holiday)}
                title={'Update holiday'}
                initialValues={holiday}
                submitLabel={'Save'}
                trigger={
                    <MenuItem sx={{ color: 'primary.main' }}>
                        <Icon icon={edit2Fill} width={20} height={20} />
                        <Typography variant="body2" sx={{ ml: 2 }}>
                            Edit
                        </Typography>
                    </MenuItem>
                } />
            <ControlledDialog
                title={'Are you sure?'}
                content={'This action is irreversible.'}
                onSubmit={() => deleteHoliday({ id: holiday.id })} submitLabel={'Delete'} trigger={
                    <MenuItem sx={{ color: 'error.main' }}>
                        <Icon icon={trash2Outline} width={20} height={20} />
                        <Typography variant="body2" sx={{ ml: 2 }}>
                            Delete
                        </Typography>
                    </MenuItem>
                } />
        </Stack>
    );
};

export default Actions;
