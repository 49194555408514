import {
    Button,
    Card,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useMe from '../../../hooks/useMe';
import * as React from 'react';
import useDeleteHoliday from './useDeleteHoliday';
import useHolidaysByTranslatorId from './useHolidaysByTranslatorId';

const MyHolidaysTable: React.FC = () => {
    const { data: me } = useMe();
    const params = useParams();
    const { data } = useHolidaysByTranslatorId(params.id);
    const { mutate: deleteHoliday } = useDeleteHoliday();

    const noHolidayEntries = data && data.length === 0;

    return <Card sx={{ p: 2 }}>
        <Stack>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell align='right' />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((holiday) => (
                            <TableRow key={holiday.id}>
                                <TableCell>{moment(holiday.date).format('YYYY MMM DD')}</TableCell>
                                {me?.isTranslator && <TableCell>
                                    <Button onClick={() => deleteHoliday({ id: holiday.id })}>Delete</Button>
                                </TableCell>}
                            </TableRow>
                        ))}
                        {noHolidayEntries && <TableRow>
                            <TableCell colSpan={2}>
                                <Stack
                                    sx={{ p: 2 }}
                                    alignItems={'center'} justifyContent={'center'} gap={2}>
                                    <img width={160} alt="empty content"
                                        src="/images/illustration_empty_content.svg"/>
                                    <Typography color={'text.secondary'} variant={'h6'}>No holidays</Typography>
                                </Stack>
                            </TableCell>
                        </TableRow> }
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    </Card>;
};

export default MyHolidaysTable;
