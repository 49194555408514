import Form from '../../Form';
import { TranslationStyle } from '../../../enums/TranslationStyle';
import { UserRole } from '../../../enums/UserRole';
import { useParams } from 'react-router-dom';
import useUserById from '../../../hooks/useUserById';
import ProfilePanel from './sections/ProfilePanel';
import PersonalData from './sections/PersonalData';
import PostalAddress from './sections/PostalAddress';
import InvoiceAddress from './sections/InvoiceAddress';
import TaskType from './sections/TaskType';
import Languages from './sections/Languages';
import TranslatorCertificates from './sections/TranslatorCertificates';
import DeclareCorrectInformation from './sections/DeclareCorrectInformation';
import { Button, Card, Grid, Typography } from '@mui/material';
import Customer from './sections/Customer';
import useUpdateUser from './useUpdateUser';
import validationSchema from './validationSchema';
import useMe from '../../../hooks/useMe';
import ProfileEditorForm from './ProfileEditorForm';


const ProfileEditor: React.FC = () => {
    const { id: userId } = useParams();
    const { data: user } = useUserById(userId);
    const { mutate: updateUser } = useUpdateUser(userId);
    const { data: me } =useMe();

    if (!user) {
        return null;
    }

    const onSubmit = (form: ProfileEditorForm) => {
        const user = {
            ...form,
            invoiceAddress: form.isSameAsPostal ? form.postalAddress : form.invoiceAddress,
        };

        updateUser({ user });
    };

    return <Form<ProfileEditorForm>
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={{
            email: user.email || '',
            phone: user.phone || '',
            firstname: user.firstname || '',
            lastname: user.lastname || '',
            customerId: user.customerId,
            isSameAsPostal: false,
            postalAddress: user.postalAddress ?? {
                name: '',
                zip: '',
                country: '',
                state: '',
                city: '',
                address: '',
            },
            invoiceAddress: user.invoiceAddress ?? {
                name: '',
                zip: '',
                country: '',
                state: '',
                city: '',
                address: '',
                vatNumber: '',
            },
            languages: user.languages || [],
            style: user.style || TranslationStyle.SWORN,
            role: user.role,
            canLogin: user.canLogin,
            isDataCorrect: me?.role === UserRole.ADMIN,
        }}
    >
        <Grid container spacing={2}>
            <ProfilePanel/>

            <Customer/>

            <PersonalData/>

            <Grid item xs={12} >
                <Card sx={{ p: 2 }}>
                    <Typography mb={2} variant={'h5'}>Postal address</Typography>
                    <PostalAddress />
                </Card>
            </Grid>
            <Grid item xs={12} >
                <Card sx={{ p: 2 }}>
                    <Typography mb={2} variant={'h5'}>Invoice address</Typography>
                    <InvoiceAddress />
                </Card>
            </Grid>

            <TaskType />

            <Languages />

            <TranslatorCertificates/>

            <DeclareCorrectInformation />

            <Grid item xs={12}>
                <Button type={'submit'} variant={'contained'} fullWidth>Save</Button>
            </Grid>
        </Grid>
    </Form>;
};

export default ProfileEditor;
