import { UserRole } from '../../../enums/UserRole';
import TaskWidget from '../../../models/TaskWidget';

type PropTypes = {
    widgetSlug: string;
    role: UserRole;
    widgetRoles: TaskWidget[] | undefined;
    withApproval: boolean;
    children: JSX.Element;
};
const WidgetGuard = ({ widgetSlug, role, widgetRoles, withApproval, children }: PropTypes) => {
    if (!widgetRoles) return null;

    let _role: string = role;

    switch (role) {
    case UserRole.AGENT:
        _role = withApproval ? 'agent-with-approval' : 'agent-without-approval';
        break;
    case UserRole.USER:
        _role = withApproval ? 'user-with-approval' : 'user-without-approval';
        break;

    default:
        break;
    }

    const widgetIndex = widgetRoles.findIndex((widget) => widget.slug === widgetSlug);

    if (widgetIndex < 0) {
        return null;
    }

    const arrayOfRoles = widgetRoles[widgetIndex].roles;

    if (arrayOfRoles?.includes(_role)) {
        return children;
    }

    return null;
};

export default WidgetGuard;
