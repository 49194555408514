/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import moment from 'moment-with-locales-es6';
import TokenResponse from '../components/models/TokenResponse';
import config from '../config';

export enum Token {
  ACCESS = 'access_token',
  REFRESH = 'refresh_token',
}

const setBoth = (response: any): void => {
    const data = new TokenResponse();
    Object.assign<TokenResponse, any>(data, response);

    if (!data.access_token || !data.expires_in || !data.refresh_token) {
        throw new Error('Bad token response format');
    }

    setToken(Token.ACCESS, data.access_token, moment().add(data.expires_in, 'seconds'));
    setToken(Token.REFRESH, data.refresh_token, moment().add(config.refreshTokenExpiry, 'seconds'));
};


const setToken = (token: Token, value?: string, expiry?: number): void => {
    const combinedValue = JSON.stringify({ value, expiry });
    localStorage.setItem(token, combinedValue);
};

const getToken = (token: Token): string | undefined => {
    try {
        const combinedValue = localStorage.getItem(token);
        if (!combinedValue) return;

        return JSON.parse(combinedValue).value;
    } catch (err) {
        console.error(err);
        return;
    }
};

const removeToken = (token: Token): void => {
    localStorage.removeItem(token);
};

const isValid = (token: Token): boolean => {
    try {
        const combinedValue = localStorage.getItem(token);
        if (!combinedValue) return true;

        const expiry = JSON.parse(combinedValue).expiry;

        return expiry && Date.parse(expiry) > Date.now();
    } catch (err) {
        console.error(err);
        return true;
    }
};

export default {
    setBoth,
    getToken,
    setToken,
    removeToken,
    isValid,
};
