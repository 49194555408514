import { Box, CircularProgress, Container, Tab, Tabs, Typography } from '@mui/material';
import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../components/Page';
import useUserById from '../../hooks/useUserById';
import getTabs from './getTabs';
import useMe from '../../hooks/useMe';

export default function Profile(): JSX.Element {
    const [currentTab, setCurrentTab] = useState('Personal data');
    const { id: userId } = useParams();
    const { data: user } = useUserById(userId);
    const { data: me } = useMe();

    const accountTabs = useMemo(() => {
        if (!user || !me) {
            return;
        }

        return getTabs(me, user);
    }, [user, me]);

    return (
        <Page title="WeTranslate">
            <Container maxWidth={'lg'}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
                    <Typography variant="h4" gutterBottom >Profile</Typography>
                </Box>

                <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {accountTabs?.map((tab) => (
                        <Tab
                            disableRipple
                            key={tab.value}
                            label={tab.value}
                            icon={tab.icon}
                            value={tab.value}
                            disabled={tab.disabled}
                        />
                    ))}
                </Tabs>

                <Box sx={{ mb: 5 }} />

                {accountTabs ? (
                    accountTabs.map((tab) => {
                        const isMatched = tab.value === currentTab;
                        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                    })
                ) : (
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Container>
        </Page>
    );
}

