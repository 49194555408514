import { Card, Grid, Stack, Typography } from '@mui/material';
import { UploadSingleFile } from '../../../../upload';
import ListFiles from '../../../../ListFiles';
import React from 'react';
import useUploadTranslatorCertificate from './useUploadTranslatorCertificate';
import useTranslatorCertificates from './useTranslatorCertificates';
import { useParams } from 'react-router-dom';
import useDeleteTranslatorCertificate from './useDeleteTranslatorCertificate';
import { useFormikContext } from 'formik';
import { UserRole } from '../../../../../enums/UserRole';
import ProfileEditorForm from '../../ProfileEditorForm';

const TranslatorCertificates: React.FC = () => {
    const { values } = useFormikContext<ProfileEditorForm>();
    const { mutate: deleteCertById } = useDeleteTranslatorCertificate();
    const { mutate } = useUploadTranslatorCertificate();
    const { data: files } = useTranslatorCertificates();
    const { id } = useParams();

    const isUserTranslator = values.role === UserRole.TRANSLATOR;

    if (!isUserTranslator) {
        return null;
    }

    return <Grid item xs={12} >
        <Card sx={{ p: 2 }}>
            <Typography mb={2} variant={'h5'}>Translator certificates</Typography>
            <Typography mb={2} variant={'body1'}>Please upload your translation activity certificates here

            </Typography>

            <Stack spacing={3}>
                <UploadSingleFile
                    onUpload={(file: globalThis.File) => mutate({ file })}
                />
            </Stack>
            {files && <ListFiles
                files={files}
                onDelete={(id?: string) => deleteCertById({ fileId: id ?? '' })}
                downloadable={true}
                previewable={true}
                userId={id}
            />}
        </Card>
    </Grid>
    ;
};

export default TranslatorCertificates;
