import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import { TextField } from '@mui/material';
import { useField } from 'formik';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/en-gb';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';


const DatePicker: React.FC<{ name: string; label: string; disablePast?: boolean }> = ({ name, disablePast, label }) => {
    const [field] = useField(name);

    const value = field.value || null;
    const setValue = (nextDate: Date | null) => {
        field.onChange({
            target: {
                name: field.name,
                value: nextDate ? moment(nextDate).format('YYYY-MM-DD') : null,
            },
        });
    };

    return <LocalizationProvider dateAdapter={AdapterMoment} locale={'en-gb'}>
        <MuiDatePicker
            disablePast={disablePast}
            label={label}
            value={value}
            onChange={(e) => {
                setValue(e);
            }}
            renderInput={(params) => <TextField

                fullWidth size={'small'} {...params} />}
        />
    </LocalizationProvider>;
};

export default DatePicker;
