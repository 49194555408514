import React, { useMemo } from 'react';
import { FormControlLabel, FormGroup, FormHelperText, Switch } from '@mui/material';
import { useField } from 'formik';


const SwitcherFieldRevert: React.FC<{ name: string; label: string }> = ({ name, label }) => {
    const [field, meta] = useField(name);


    return useMemo(() => {
        const hasError = Boolean(meta.touched && meta.error);
        const helperText = hasError && meta.error;

        const onChange = () => {
            field.onChange({ target: { name, value: !field.value } });
        };

        if (field.value === undefined) {
            field.onChange({ target: { name, value: true } });
        }

        return (
            <FormGroup>
                <FormControlLabel control={<Switch onChange={onChange} checked={!field.value} />} label={label} />
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormGroup>
        );
    }, [field, meta, label, name]);
};

export default SwitcherFieldRevert;
