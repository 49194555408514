import Button from '@mui/material/Button';
import { useState } from 'react';

const AllowBrowserNotifications: React.FC = () => {
    const [isPermitted, setIsPermitted] = useState(Notification.permission === 'granted');

    const requestNotifications =async () => {
        await Notification.requestPermission(() => {
            setIsPermitted(true);
        });
    };

    if (!isPermitted) {
        return (
            <Button onClick={requestNotifications}>
                Allow browser notifications
            </Button>
        );
    }

    return null;
};

export default AllowBrowserNotifications;
