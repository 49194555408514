import { Box, Stack } from '@mui/material';

export const extendedRoles = [
    {
        name: 'admin',
        header: <Stack sx={{ py: 2 }}>Admin</Stack>,
    },
    {
        name: 'linguist',
        header: <Stack sx={{ py: 2 }}>Linguist</Stack>,
    },
    {
        name: 'translator',
        header: <Stack sx={{ py: 2 }}>Translator</Stack>,
    },
    {
        name: 'agent-with-approval',
        header: (
            <Stack sx={{ py: 2, lineHeight: 1.1 }}>
                <div>Agent</div>
                <Box fontSize={11} fontWeight={300}>
                    w/ price approval
                </Box>
            </Stack>
        ),
    },
    {
        name: 'agent-without-approval',
        header: (
            <Stack sx={{ py: 2, lineHeight: 1.1 }}>
                <div>Agent</div>
                <Box fontSize={11} fontWeight={300}>
                    w/o price approval
                </Box>
            </Stack>
        ),
    },
    {
        name: 'user-with-approval',
        header: (
            <Stack sx={{ py: 2, lineHeight: 1.1 }}>
                <div>User</div>
                <Box fontSize={11} fontWeight={300}>
                    w/ price approval
                </Box>
            </Stack>
        ),
    },
    {
        name: 'user-without-approval',
        header: (
            <Stack sx={{ py: 2, lineHeight: 1.1 }}>
                <div>User</div>
                <Box fontSize={11} fontWeight={300}>
                    w/o price approval
                </Box>
            </Stack>
        ),
    },
];
