import { Card, Skeleton } from '@mui/material';
import AreaChartMonthlyBreakDown from '../../../../../components/charts/AreaChartMonthlyBreakDown';
import { useQuery } from 'react-query';
import ChartData from '../../../../../components/charts/ChartData';
import moment from 'moment';
import config from '../../../../../config';
import numeral from 'numeral';

const TaskStatistics: React.FC = () => {
    const nowDate = moment().add(1, 'days').format('YYYY-MM-DD');
    const { data } = useQuery<ChartData>(`/dashboard/tasks-statistics?after=${config.statisticsStartDate}&before=${nowDate}`);

    if (!data) {
        return <Card>
            <Skeleton variant={'rectangular'} height={500}/>
        </Card>;
    }

    return (
        <Card>
            <AreaChartMonthlyBreakDown
                valueFormatter={function(value: number) {
                    return numeral(value).format('');
                }}
                title={'Finished tasks'}
                subTitle={'Total vs. finished on time'}
                chartData={data}
                customLegendItems={['Total', 'Finished on time']} />

        </Card>
    );
};

export default TaskStatistics;
