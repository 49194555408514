import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';

function useRefetchPriceCalculation(taskId: string) {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery(`tasks/${taskId}/pricecalculation/restart`, {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess() {
            enqueueSnackbar('Calculation Success', {
                variant: 'success',
            });
        },
    });
}

export default useRefetchPriceCalculation;
