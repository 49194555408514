import { Card, CardContent, CardHeader, Container, Stack, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import LanguagePair from '../../../../models/LanguagePair';
import Form from '../../../Form';
import LanguagePairEditor from './LanguagePairEditor';
import useSaveLanguagePairs from './useSaveLanguagePairs';


export type LanguagePairForm = {
    languagePairs: LanguagePair[]
}

export default function LanguagePairs(): JSX.Element {
    const { data: languagePairs } = useQuery<LanguagePair[]>('languagepairs');
    const { mutate } = useSaveLanguagePairs();

    const onSubmit = (form: LanguagePairForm) => {
        console.log(form.languagePairs);
        mutate({ languagePairs: form.languagePairs });
    };


    return (
        <Container>
            <Card sx={{ p: 2 }}>
                <CardHeader title="Direct To Admin" />
                <Stack spacing={2} sx={{ width: 1, px: 3.5 }}>
                    <Stack gap={1}>
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            Newly created tasks which have these language pairs will not be sent to the Linguist.
                        </Typography>
                    </Stack>
                </Stack>
                <CardContent>
                    <Stack spacing={3}>
                        <Form<LanguagePairForm> onSubmit={onSubmit} initialValues={{ languagePairs: languagePairs ?? [] }}>
                            <LanguagePairEditor />
                        </Form>
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    );
}

