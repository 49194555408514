import UserEvent from '../../../models/UserEvent';
import User from '../../../models/User';
import moment from 'moment';
import { UserRole } from '../../../enums/UserRole';
import lodash from 'lodash';
import parseJSON from '../../../utils/parseJSON';


/*
* This methods adds 'details' and 'general' fields to userEvent object for data display purposes
*/
const parseUserEvent = (userEvent: UserEvent, currentUser?: User): UserEvent & { general: string; details: string; } => {
    const date = moment(userEvent.created).format('MM/DD/YY HH:mm');

    const parsedDetails = parseJSON<Record<string, string>>(userEvent.details ?? '', { error: 'Missing or malformed details' });
    const keys = Object.keys(parsedDetails);

    const detailsMessage = keys.map((key) => `${key}: ${ parsedDetails[key] }`).join(', ');
    const actionName = lodash.capitalize(userEvent.action?.replaceAll('_', ' '));

    const augmentedUserEvent = {
        ...userEvent,
        general: date,
        details: `${actionName} - ${detailsMessage}`,
    };

    const shouldDisplayUserInfo = currentUser?.role === UserRole.ADMIN && userEvent.username;
    if (shouldDisplayUserInfo) {
        parsedDetails.general = `${date} - ${userEvent.username} (${userEvent.role})`;
    }

    return augmentedUserEvent;
};

export default parseUserEvent;
