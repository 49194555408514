/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from '@iconify/react';
import { IconButton } from '@mui/material';
import { Box, Stack } from '@mui/material';
import { createRef, RefObject, useEffect, useMemo } from 'react';
import Block from './Block';
import useEditor from './useEditor';
import plusCircleFill from '@iconify/icons-eva/plus-circle-fill';
import { AnnotatedBlock, AnnotatedPage, FormattedAnnotation } from './AnnotationTypes';
import { v4 as uuidv4 } from 'uuid';

type RefType = {
    [key: string]: RefObject<HTMLDivElement>;
};

const DocumentBlocks = () => {
    const {
        setSelectedBlockId,
        setSelectedPageData,
        setTransformedData,
        editorState: { selectedPageData, selectedScrollToId, selectedPage, transformedData },
    } = useEditor();
    const refs = useMemo(() => {
        return selectedPageData?.blocks.reduce((_refs: RefType, block) => {
            _refs[block.id] = createRef();
            return _refs;
        }, {});
    }, [selectedPageData?.blocks?.length, selectedPage]);

    const addNewBlock = () => {
        const newBlock: AnnotatedBlock = {
            axis: [
                { x: 10, y: 10 },
                { x: 0, y: 0 },
                { x: 0, y: 0 },
                { x: 0, y: 0 },
            ],
            characters: 0,
            fontSize: 16,
            id: uuidv4(),
            width: 200,
            textOriginal: '',
            textTranslated: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            direction: 'ltr',
            fontStyle: 'normal',
            fontWeight: 'normal',
            textAlign: 'left',
        };

        const newSelectedPageData: AnnotatedPage = {
            ...(selectedPageData as AnnotatedPage),
            blocks: [...(selectedPageData?.blocks || []), newBlock],
        };

        const newTransformedData: FormattedAnnotation = transformedData.map((data, index) => {
            if (selectedPage - 1 === index) {
                return newSelectedPageData;
            }
            return data;
        });

        setSelectedPageData(newSelectedPageData);
        setTransformedData(newTransformedData);
    };

    useEffect(() => {
        if (selectedScrollToId && refs) {
            refs[selectedScrollToId].current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [selectedScrollToId]);

    return (
        <Stack
            sx={{
                height: '100%',
                width: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: 2,
            }}
            spacing={2}
        >
            {selectedPageData?.blocks.map((block, i) => (
                <Box key={block.id} onClick={() => setSelectedBlockId(block.id)}>
                    <Block ref={refs && refs[block.id]} block={block} />
                </Box>
            ))}

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <IconButton color="primary" onClick={addNewBlock}>
                    <Icon icon={plusCircleFill} width="40" height="40" />
                </IconButton>
            </Box>
        </Stack>
    );
};

export default DocumentBlocks;
