import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import downloadFill from '@iconify/icons-eva/cloud-download-fill';
import File from '../../../../models/File';
import useDownloadFile from '../../../../hooks/useDownloadFile';

const InvoiceCell: React.FC<{ invoice?: File, reportId?: string }> = ({ invoice, reportId }) => {
    const downloadFile = useDownloadFile(`/translatorreports/${reportId}/files/${invoice?.id}/download`);

    if (!invoice) return <Icon icon={closeFill} color={'red'} width={20} height={20} />;

    return (
        <Tooltip title="Download File">
            <IconButton onClick={downloadFile}>
                <Icon color="inherit" icon={downloadFill} />
            </IconButton>
        </Tooltip>
    );
};

export default InvoiceCell;
