import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import SentIcon from '../../assets/icon_sent';
import { ResetPasswordForm } from '../../components/authentication/reset-password';
import Page from '../../components/Page';
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';


const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));


export default function ResetPassword(): JSX.Element {
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);

    return (
        <RootStyle title="WeTranslate">
            <LogoOnlyLayout />

            <Container>
                <Box sx={{ maxWidth: 480, mx: 'auto' }}>
                    {!sent ? (
                        <ResetPasswordForm
                            setSent={setSent}
                            setEmail={setEmail}
                        />
                    ) : (
                        <Box sx={{ textAlign: 'center' }}>
                            <SentIcon sx={{ mb: 5, mx: 'auto', height: 160 }} />

                            <Typography variant="h3" gutterBottom>
                                Request sent successfully
                            </Typography>
                            <Typography>
                                We have sent a confirmation email to &nbsp;
                                <strong>{email}</strong>
                                <br />
                                Please check your email.
                            </Typography>
                        </Box>
                    )}
                    <Stack alignItems="center">
                        <Button
                            fullWidth
                            size="large"
                            component={RouterLink}
                            to='/login'
                            sx={{ mt: 1, maxWidth: 100 }}
                        >
                            Back
                        </Button>
                    </Stack>
                </Box>
            </Container>
        </RootStyle>
    );
}
