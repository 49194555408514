import { Container } from '@mui/material';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import TranslationBonus from '../../../models/TranslationBonus';
import Form from '../../Form';
import BonusEditor from './BonusEditor';
import useSaveBonuses from './useSaveBonuses';

export type BonusForm = {
  bonuses: TranslationBonus[]
}

export default function Bonus(): JSX.Element {
    const { id: customerId } = useParams();
    const { data: bonuses } = useQuery<TranslationBonus[]>(['bonuses', '?', 'customerId=', customerId]);
    const { mutate } = useSaveBonuses();

    const onSubmit = (form: BonusForm) => {
        mutate({ bonuses: form.bonuses });
    };

    return (
        <Container>
            <Form<BonusForm> onSubmit={onSubmit} initialValues={{ bonuses: bonuses ?? [] }}>
                <BonusEditor />
            </Form>
        </Container>
    );
}
