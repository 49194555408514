import React from 'react';
import useMe from '../../hooks/useMe';
import { UserRole } from '../../enums/UserRole';
import TaskLoading from '../TaskEditor/TaskLoading';
import AdminNewTask from './NewTaskByRole/NewTask.admin';
import CustomerNewTask from './NewTaskByRole/NewTask.customer';


const NewTask = () => {
    const { data: me } = useMe();

    switch (me && me.role) {
    case UserRole.ADMIN:
        return <AdminNewTask />;
    case UserRole.AGENT:
    case UserRole.USER:
        return <CustomerNewTask />;
    default:
        return <TaskLoading />;
    }
};

export default NewTask;
