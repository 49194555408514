import { Collapse, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import Column from './Column';
import { Fragment } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

type RowsProps<T> = {
    rows: T[]
    columns: Column<T>[]
    onRowClick?: (row: T) => void
    idKey: keyof T
    expandedComponent?: (row: T) => React.ReactNode
}


function renderTableCell<T>(row: T, column: Column<T>) {
    if (column.getter) {
        const value = column.getter(row);
        if (typeof value === 'string') {
            return <Tooltip title={value}><Typography fontSize={12} noWrap>{value}</Typography></Tooltip>;
        } else {
            return value;
        }
    }

    return row[column.id];
}

function Rows<T>({ rows, columns, onRowClick, idKey, expandedComponent }: RowsProps<T>) {
    const [expanded, setExpanded] = React.useState<number | false>(false);


    return <>
        {rows?.map((row, index) => (
            <Fragment key={row[idKey] as unknown as string}>
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    {expandedComponent && <TableCell sx={{ width: 30, p: 0 }}>
                        <IconButton
                            size="small"
                            onClick={() => setExpanded(expanded === index + 1 ? false : index + 1)}
                        >
                            {expanded === index + 1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>}
                    {columns.filter((col) => !col.isHidden).map((column) => <TableCell
                        align={column.align ?? 'left'}
                        sx={{ width: column.width }}
                        key={column.id.toString()} component="td" scope="row">
                        {renderTableCell<T>(row, column)}
                    </TableCell>)}
                </TableRow>
                <TableRow sx={{ py: 1 }}>
                    <TableCell sx={{ pl: '30px' }} onClick={() => {
                        onRowClick?.(row);
                    }} colSpan={columns.length}>
                        <Collapse in={expanded === index + 1} timeout="auto" unmountOnExit>
                            {expandedComponent?.(row)}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </Fragment>
        ))}
    </>;
}

export default Rows;
