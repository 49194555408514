import { alpha } from '@mui/material/styles';
import { ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { Icon } from '@iconify/react';
import moment from 'moment';
import ConversationType from '../../../models/Conversation';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import useMe from '../../../hooks/useMe';
import SkeletonUser from '../SkeletonUser';

const Conversation: React.FC<{ conversation: ConversationType}> = ({ conversation }) => {
    const { userId } = useParams();
    const { data: me, isLoading } = useMe();
    const navigate = useNavigate();
    const participant = conversation.participantOneId === me?.id ? conversation.participantTwo : conversation.participantOne;
    const isSeen = conversation.participantOneId === me?.id ? conversation.participantOneHasSeen : conversation.participantTwoHasSeen;

    const selectConversation = () => {
        navigate('/chat/' + participant.id);
    };

    if (isLoading) {
        return <SkeletonUser />;
    }

    return <>
        <ListItem
            sx={{ backgroundColor: (theme) => userId === participant.id ? alpha(theme.palette.primary.light, 0.2) : 'transparent' }}
            button key={conversation.id}
            onClick={() => selectConversation()}
        >
            {userId === participant.id && <Stack
                layoutId={'selected-conversation'}
                component={motion.div}
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    width: 4,
                    height: '100%',
                    background: 'red',
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                    backgroundColor: (t) => t.palette.primary.main,
                }}/>}
            <ListItemIcon sx={{ fontSize: 54, color: (t) => t.palette.primary.main }}>
                <Icon icon={'carbon:user-avatar-filled'}/>
            </ListItemIcon>
            <ListItemText
                primary={
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography fontWeight={isSeen ? 400 : 700}>{participant.firstname + ' ' + participant.lastname}</Typography>
                        {!isSeen && <Stack height={6} width={6} borderRadius={'50%'} bgcolor={'info.main'}/>}
                    </Stack>
                }
                secondary={<Stack component={'span'} direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Typography component={'span'} lineHeight={1}>{participant.role}</Typography>
                    <Typography component={'span'} lineHeight={1} color={'text.secondary'} fontSize={12}>{moment(conversation.lastMessageSentAt).fromNow()}</Typography>
                </Stack>}
                primaryTypographyProps={{ noWrap: true, fontWeight: userId === conversation.id ? 700 : 400 }}/>
        </ListItem>
    </>;
};

export default Conversation;
