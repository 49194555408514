import { Box, Stack, useTheme } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

type LogoProps = {
    redirectTo: string;
}

const ImageStyle = styled('img')(() => ({
    width: 45,
    height: 45,
}));

export default function Logo(props: LogoProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box component={RouterLink} to={props.redirectTo} sx={{ display: 'inline-flex' }}>
                {theme.palette.mode === 'light' ? (
                    <ImageStyle src="/static/icons/logo_small_light.svg" alt="Wetranslate"/>
                ) : (
                    <ImageStyle src="/static/icons/logo_small_dark.svg" alt="Wetranslate"/>
                )}
            </Box>
        </Stack>
    );
}
