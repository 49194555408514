/* eslint-disable no-unused-vars */
export enum TaskStatus {
    NEW_TASK = 'new_task',
    WAITING_FOR_PRICE_APPROVAL = 'waiting_for_price_approval',
    READY_TO_TRANSLATE = 'ready_to_translate',
    PRICE_DECLINED = 'price_declined',
    INVALID_SOURCE_FILE = 'invalid_source_file',
    UNDER_TRANSLATION = 'under_translation',
    INVALID_LANGUAGE = 'invalid_language',
    TRANSLATION_READY = 'translation_ready',
    APPROVED = 'approved',
    ON_HOLD = 'on_hold',
    INCORRECT_TRANSLATION = 'incorrect_translation',
    CANCELLED = 'cancelled'
}

export const extendedTaskStatusOptions = [
    { name: 'New task', id: TaskStatus.NEW_TASK },
    { name: 'Waiting for price approval', id: TaskStatus.WAITING_FOR_PRICE_APPROVAL },
    { name: 'Ready to translate', id: TaskStatus.READY_TO_TRANSLATE },
    { name: 'Price declined', id: TaskStatus.PRICE_DECLINED },
    { name: 'Invalid source file', id: TaskStatus.INVALID_SOURCE_FILE },
    { name: 'Under translation', id: TaskStatus.UNDER_TRANSLATION },
    { name: 'Invalid language', id: TaskStatus.INVALID_LANGUAGE },
    { name: 'Translation ready', id: TaskStatus.TRANSLATION_READY },
    { name: 'Approved', id: TaskStatus.APPROVED },
    { name: 'On Hold', id: TaskStatus.ON_HOLD },
    { name: 'Incorrect translation', id: TaskStatus.INCORRECT_TRANSLATION },
    { name: 'Cancelled', id: TaskStatus.CANCELLED },
];

export const taskStatusOptions = [
    { name: 'New task', id: TaskStatus.NEW_TASK },
    { name: 'Invalid source file', id: TaskStatus.INVALID_SOURCE_FILE },
    { name: 'Under translation', id: TaskStatus.UNDER_TRANSLATION },
    { name: 'Invalid language', id: TaskStatus.INVALID_LANGUAGE },
    { name: 'Translation ready', id: TaskStatus.TRANSLATION_READY },
    { name: 'Approved', id: TaskStatus.APPROVED },
    { name: 'On Hold', id: TaskStatus.ON_HOLD },
    { name: 'Incorrect translation', id: TaskStatus.INCORRECT_TRANSLATION },
    { name: 'Cancelled', id: TaskStatus.CANCELLED },
];
