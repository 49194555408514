import { Badge, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import React from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import useMe from '../../../../hooks/useMe';
import useChatAdmin from '../../../../hooks/useChatAdmin';

const MessageNotification: React.FC = () => {
    const navigate = useNavigate();
    const { data: me } = useMe();
    const { data: chatAdmin } = useChatAdmin();

    const { data: nrOfUnseenConvo } = useQuery<{ count: number }>('conversations/number-of-unseen');

    const onClickNotifications = () => {
        if (me?.isAdmin) {
            navigate('/chat');
        } else if (chatAdmin) {
            navigate('/chat/' + chatAdmin.id);
        }
    };

    return <IconButton onClick={onClickNotifications}>
        <Badge color="error" badgeContent={nrOfUnseenConvo?.count}>
            <Icon icon={'bx:envelope'} />
        </Badge>
    </IconButton>;
};

export default MessageNotification;
