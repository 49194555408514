import lodash from 'lodash';

/* eslint-disable no-unused-vars */
export enum TranslationStyleType {
    SWORN = 'sworn',
    FREE = 'free',
}

export function parse(type: TranslationStyleType): { name: string } {
    return {
        name: lodash.capitalize(type),
    };
}


export const styleOptions = [
    { value: TranslationStyleType.SWORN, label: parse(TranslationStyleType.SWORN).name },
    { value: TranslationStyleType.FREE, label: parse(TranslationStyleType.FREE).name },
];
