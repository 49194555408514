import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';


function useAddCommentToTask(taskId?: string) {
    const queryClient = useQueryClient();

    return useMutation<Comment, unknown, string>({
        mutationFn: (text) => {
            const requestBody = {
                text,
                taskId,
            };
            return API.post('comments', requestBody);
        },
        onSuccess() {
            queryClient.invalidateQueries('comments/list/');
        },
    });
}

export default useAddCommentToTask;
