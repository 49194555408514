import { Card, Typography } from '@mui/material';
import { UploadMultipleFile } from '../../../components/upload';
import React from 'react';
import useUploadFileToTask from './useUploadFileToTask';
import { FileType } from '../../../enums/FileType';
import TaskLoading from '../TaskLoading';

const FileUpload: React.FC<{ taskId?: string, type: FileType }> = ({ taskId, type } ) => {
    const { mutate: uploadFile, isLoading } = useUploadFileToTask(taskId);

    const uploadFiles = (files: File[]) => {
        files.forEach((file) => uploadFile({ file, type: type }));
    };

    if (isLoading) {
        return <TaskLoading />;
    }

    return (
        <Card sx={{ p: 2 }}>
            <Typography variant='subtitle2' color='text.secondary' sx={{ textTransform: 'capitalize' }} gutterBottom>
                {type + ' file'}
            </Typography>
            <UploadMultipleFile onUpload={uploadFiles}/>
        </Card>
    );
};

export default FileUpload;
