import { useMutation, useQueryClient } from 'react-query';
import Task from '../../../models/Task';
import { API } from '../../../utils/ApiClient';
import { useSnackbar } from 'notistack';
import { UserRole } from '../../../enums/UserRole';
import useMe from '../../../hooks/useMe';
import setSeenStatus from './setSeenStatus';

function useUpdateTask() {
    const { data: me } = useMe();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation<unknown, { data: { error: { message: string } } }, Task>({
        mutationFn: async (task) => {
            const { ...taskToSend } = task;
            return API.put('tasks/' + task.id, setSeenStatus(taskToSend, me?.role ?? UserRole.USER));
        },
        onSuccess() {
            queryClient.invalidateQueries('tasks');
            enqueueSnackbar('Status updated');
        },
        onError(e) {
            enqueueSnackbar(e.data.error.message, { variant: 'error' });
        },
    });
}

export default useUpdateTask;
