import edit2Fill from '@iconify/icons-eva/edit-2-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import {
    Divider, IconButton, Menu, MenuItem,
    Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import Customer from '../../../models/Customer';
import ControlledDialog from '../../../components/ControlledDialog';
import { API } from '../../../utils/ApiClient';

type ActionsProps = {
    customer: Customer
};

const Actions = ({ customer }: ActionsProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const menuRef = useRef(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { mutate: deleteCustomer } = useMutation<unknown, unknown, { customerId?: string }>({
        mutationFn: ({ customerId }) => API.delete('customers/' + customerId),
        onSuccess() {
            setOpen(false);
            queryClient.invalidateQueries('customers');
            enqueueSnackbar('Customer deleted');
        },
    });

    return (
        <>
            <IconButton ref={menuRef} size="large" onClick={() => setOpen(true)}>
                <Icon icon={moreVerticalFill} width={20} height={20} />
            </IconButton>

            <Menu
                open={open}
                anchorEl={menuRef.current}
                onClose={() => setOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' },
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem onClick={() => navigate(`/customers/${customer.id}`)}>
                    <Icon icon={edit2Fill} width={20} height={20} />
                    <Typography variant="body2" sx={{ ml: 2 }}>
                        Edit
                    </Typography>
                </MenuItem>
                <Divider />
                <ControlledDialog
                    title={'Are you sure?'}
                    content={'This action is irreversible.'}
                    onSubmit={() => deleteCustomer({ customerId: customer.id })} submitLabel={'Delete'} trigger={<MenuItem sx={{ color: 'error.main' }}>
                        <Icon icon={trash2Outline} width={20} height={20} />
                        <Typography variant="body2" sx={{ ml: 2 }}>
                            Delete
                        </Typography>
                    </MenuItem>} />
            </Menu>
        </>
    );
};

export default Actions;
