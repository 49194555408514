import { FormattedAnnotation } from './AnnotationTypes';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { API } from '../../utils/ApiClient';

export default function useUpdateAnnotation(taskId: string, fileId: string) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    return useMutation<{ status: boolean }, unknown, FormattedAnnotation, unknown>({
        mutationFn: (annotation) =>
            API.put(`annotation-metadata/${taskId}/${fileId}`, {
                annotation,
            }),
        onSuccess() {
            enqueueSnackbar('Autotranslated file updated', {
                variant: 'success',
            });
            navigate(`/tasks/${taskId}/edit`);
        },
    });
}
