import React, { useMemo } from 'react';
import SelectField from '../../../components/Form/fields/SelectField';
import { useFormikContext } from 'formik';
import Task from '../../../models/Task';
import useTranslatorOptions from './useTranslatorOptions';
import { useQuery } from 'react-query';
import moment from 'moment';
import { Alert, Stack } from '@mui/material';

const TranslatorSelector: React.FC = () => {
    const { values } = useFormikContext<Task>();
    const { data: options } = useTranslatorOptions(values);

    const { data }= useQuery<any[]>(
        `translator-holidays/overlapping-holidays?fromDate=${moment().format('YYYY-MM-DD')}&toDate=${moment().add(7, 'days').format('YYYY-MM-DD')}&userId=${values.userId}`,
    );

    return useMemo(() => {
        return <Stack gap={1}>
            <SelectField options={options ?? []} name={'userId'} label={'Set Translator'}/>
            {data?.length ? <Alert severity={'warning'}>Translator will be on holiday over the next week</Alert> : null}
        </Stack>;
    }, [data?.length, options]);
};

export default TranslatorSelector;
