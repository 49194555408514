import React, { useEffect, useState, KeyboardEvent } from 'react';
import { useParams } from 'react-router-dom';
import useCommentsById from './useCommentsById';
import { Accordion, AccordionDetails, AccordionSummary, Card, Stack, TextField, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import useAddCommentToTask from './useAddCommentToTask';
import Comment from './Comment';
import ScrollToBottom from '../../../utils/ScrollToBottom';
import useUploadFileComment from './useUploadFileComment';
import { UploadFileIcon } from '../../../components/upload';

const CommentsSection: React.FC = () => {
    const { id: taskId } = useParams();
    const { data: comments } = useCommentsById(taskId);
    const { mutate, isLoading, isSuccess } = useAddCommentToTask(taskId);
    const [commentMessage, setCommentMessage] = useState('');
    const [commentMessageError, setCommentMessageError] = useState('');
    const { mutate: uploadFile } = useUploadFileComment(taskId);

    const submitComment = () => {
        mutate(commentMessage);
    };

    const onKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
        if (!commentMessage?.trim()) {
            setCommentMessageError('Required field');
            return;
        }

        if (commentMessage?.trim()) {
            setCommentMessageError('');
        }

        if (e.key === 'Enter' || e.key === '13') {
            setCommentMessageError('');
            submitComment();
        }
    };


    useEffect(() => {
        if (isSuccess) {
            setCommentMessage('');
        }
    }, [isSuccess]);


    return (
        <Card>
            <Accordion>
                <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20}/>}>
                    <Typography variant='subtitle2' color='text.secondary'>Comments ({comments?.length})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack gap={1.5} mb={1} height={250} overflow={'scroll'}>
                        {comments?.map((comment) => <Comment comment={comment} key={comment.id} />)}
                        <ScrollToBottom />
                    </Stack>
                    <Stack direction='row'>
                        <TextField
                            error={!!commentMessageError}
                            helperText={commentMessageError}
                            label={'Add comment'}
                            fullWidth
                            size={'small'}
                            disabled={isLoading}
                            onKeyUp={onKeyUp}
                            value={commentMessage}
                            onChange={(e) => setCommentMessage(e.target.value)}
                        />
                        <UploadFileIcon onUpload={(file: globalThis.File) => uploadFile({ file })} />
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

export default CommentsSection;
