import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../../../utils/ApiClient';
import { useParams } from 'react-router-dom';

function useDeleteTranslatorCertificate() {
    const { id: userId } = useParams();
    const queryClient = useQueryClient();

    return useMutation<unknown, unknown, { fileId: string }>({
        mutationFn: (params) => API.delete(`/users/${userId}/files/${params.fileId}`),
        onSuccess: () => {
            queryClient.invalidateQueries(`users/${userId}/files`);
        },
    });
}

export default useDeleteTranslatorCertificate;
