import { useQuery } from 'react-query';
import TaskWidget from '../models/TaskWidget';

const useGetTaskWidgets = () => {
    return useQuery<TaskWidget[]>('task-widgets', {
        staleTime: 0,
        cacheTime: 0,
    });
};

export default useGetTaskWidgets;
