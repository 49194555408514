/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import { Card, Container, Grid, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router';
import useTask from '../useTask';
import Form from '../../../components/Form';
import PostalAddressEditor from '../PostalAddressEditor';
import Page from '../../../components/Page';
import TaskDetailEditor from '../TaskDetailEditor';
import Task from '../../../models/Task';
import { TaskStatus } from '../../../enums/TaskStatus';
import useUpdateTask from '../useUpdateTask';
import TaskInfo from '../TaskInfo';
import FileByType from '../FilesByType';
import { FileType } from '../../../enums/FileType';
import Modifications from '../Modifications';
import CommentsSection from '../CommentsSection';
import TaskLoading from '../TaskLoading';
import PriceRejectionDialog from '../../../components/PriceRejectionDialog';
import { UserRole } from '../../../enums/UserRole';
import StatusHandlerButtons from '../../../utils/StatusHandler';
import { useQuery } from 'react-query';
import Customer from '../../../models/Customer';
import TextField from '../../../components/Form/fields/TextField';
import useSeenTask from '../useSeenTask';
import PricingComponent from '../CustomerPricing/PricingComponent';
import useGetTaskWidgets from '../../../hooks/useGetTaskWidgets';
import WidgetGuard from './WidgetGuard';
import { TaskWidgetSlug } from '../../../enums/TaskWidgetSlug';

const AgentEditor: React.FC = () => {
    const { id: taskId } = useParams();
    const { data: task } = useTask(taskId);
    const { data: customer, isLoading: loadingCustomer } = useQuery<Customer>(['customers/', task?.customerId], {
        enabled: !!task?.customerId,
    });
    const { data: widgetsRoles, isLoading: loadingWidgets } = useGetTaskWidgets();
    const { mutate: updateTask } = useUpdateTask(taskId);
    const [openPriceRejection, setOpenPriceRejection] = useState<boolean>(false);
    const { mutate: setSeen } = useSeenTask();

    useEffect(() => {
        setSeen({ id: taskId });
    }, [setSeen, taskId]);

    if (!task || loadingWidgets || loadingCustomer) {
        return <TaskLoading />;
    }

    const handleUpdateTask = () => {
        if (task.status === TaskStatus.INCORRECT_TRANSLATION || task.status === TaskStatus.PRICE_DECLINED) {
            setOpenPriceRejection(true);
        } else {
            updateTask;
        }
    };

    const handleCloseRejection = () => {
        setOpenPriceRejection(false);
    };

    return (
        <Page title='WeTranslate'>
            <Form<Task> validationSchema={undefined} onSubmit={handleUpdateTask} initialValues={task}>
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <WidgetGuard
                                    role={UserRole.AGENT}
                                    widgetSlug={TaskWidgetSlug.GENERAL_INFO}
                                    widgetRoles={widgetsRoles}
                                    withApproval={customer?.mustApprovePrice ?? false}
                                >
                                    <Card sx={{ p: 2 }}>
                                        <TaskInfo task={task} isCustomer showCreator />
                                    </Card>
                                </WidgetGuard>

                                <WidgetGuard
                                    role={UserRole.AGENT}
                                    widgetSlug={TaskWidgetSlug.TRANSLATED_FILE}
                                    widgetRoles={widgetsRoles}
                                    withApproval={customer?.mustApprovePrice ?? false}
                                >
                                    <FileByType fileType={FileType.TRANSLATED} taskId={taskId} taskStatus={task.status} />
                                </WidgetGuard>

                                <WidgetGuard
                                    role={UserRole.AGENT}
                                    widgetSlug={TaskWidgetSlug.ORIGINAL_FILE}
                                    widgetRoles={widgetsRoles}
                                    withApproval={customer?.mustApprovePrice ?? false}
                                >
                                    <FileByType fileType={FileType.ORIGINAL} taskId={taskId} taskStatus={task.status} />
                                </WidgetGuard>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <WidgetGuard
                                    role={UserRole.AGENT}
                                    widgetSlug={TaskWidgetSlug.TASK_DETAILS}
                                    widgetRoles={widgetsRoles}
                                    withApproval={customer?.mustApprovePrice ?? false}
                                >
                                    <Card sx={{ p: 2, bgcolor: 'background.neutral' }}>
                                        <TaskDetailEditor
                                            disabled={
                                                task.status === TaskStatus.NEW_TASK ||
                                                    task.status === TaskStatus.INVALID_LANGUAGE ? false : true
                                            }
                                        />
                                    </Card>
                                </WidgetGuard>

                                {task.hardCopyRequired && (
                                    <WidgetGuard
                                        role={UserRole.AGENT}
                                        widgetSlug={TaskWidgetSlug.POSTAL_ADDRESS}
                                        widgetRoles={widgetsRoles}
                                        withApproval={customer?.mustApprovePrice ?? false}
                                    >
                                        <Card>
                                            <PostalAddressEditor />
                                        </Card>
                                    </WidgetGuard>
                                )}

                                <WidgetGuard
                                    role={UserRole.AGENT}
                                    widgetSlug={TaskWidgetSlug.CUSTOMER_PRICING}
                                    widgetRoles={widgetsRoles}
                                    withApproval={customer?.mustApprovePrice ?? false}
                                >
                                    <Card sx={{ p: 2, bgcolor: 'background.neutral' }}>
                                        <Stack alignItems={'center'}>
                                            <Typography
                                                alignSelf={'flex-start'}
                                                variant='subtitle2'
                                                color='text.secondary'
                                                gutterBottom
                                            >
                                                Translation Calculation
                                            </Typography>
                                            <PricingComponent />
                                        </Stack>
                                    </Card>
                                </WidgetGuard>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <WidgetGuard
                                    role={UserRole.AGENT}
                                    widgetSlug={TaskWidgetSlug.STATUS}
                                    widgetRoles={widgetsRoles}
                                    withApproval={customer?.mustApprovePrice ?? false}
                                >
                                    <StatusHandlerButtons
                                        userId={task.userId}
                                        task={task!}
                                        role={UserRole.AGENT}
                                        size={'small'}
                                        shouldApprovePrice={customer?.mustApprovePrice ?? false}
                                    />
                                </WidgetGuard>

                                <WidgetGuard
                                    role={UserRole.AGENT}
                                    widgetSlug={TaskWidgetSlug.COMMENTS}
                                    widgetRoles={widgetsRoles}
                                    withApproval={customer?.mustApprovePrice ?? false}
                                >
                                    <CommentsSection />
                                </WidgetGuard>

                                <WidgetGuard
                                    role={UserRole.AGENT}
                                    widgetSlug={TaskWidgetSlug.MODIFICATIONS}
                                    widgetRoles={widgetsRoles}
                                    withApproval={customer?.mustApprovePrice ?? false}
                                >
                                    <Modifications taskId={taskId} />
                                </WidgetGuard>

                                <WidgetGuard
                                    role={UserRole.AGENT}
                                    widgetSlug={TaskWidgetSlug.MERCHANT_ID}
                                    widgetRoles={widgetsRoles}
                                    withApproval={customer?.mustApprovePrice ?? false}
                                >
                                    <Card sx={{ p: 2 }}>
                                        <TextField name={'merchantId'} label={'Merchant ID'} />
                                    </Card>
                                </WidgetGuard>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
                <PriceRejectionDialog
                    open={openPriceRejection}
                    onClose={handleCloseRejection}
                    task={task}
                    status={task.status!}
                    onEdit={true}
                />
            </Form>
        </Page>
    );
};

export default AgentEditor;
