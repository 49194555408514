import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { LanguageForm } from './Languages';
import checkMark2Outline from '@iconify/icons-eva/checkmark-circle-2-outline';
import { Icon } from '@iconify/react';
import { IconButton, Stack, TextField } from '@mui/material';


const DefaultCharactersEditor: React.FC = () => {
    const [defaultCharacter, setDefaultCharacter] = useState<string>('');
    const { setFieldValue, values } = useFormikContext<LanguageForm>();
    const setDefaultCharacters = () => {
        if (values.languages && defaultCharacter) {
            const newValues = values.languages;
            newValues.forEach((l) => l.charactersPerPage = parseInt(defaultCharacter));
            setFieldValue('languages', newValues);
        }
    };

    return (
        <Stack direction={'row-reverse'} sx={{ mb: 2 }} spacing={2}>
            <IconButton onClick={setDefaultCharacters}>
                <Icon icon={checkMark2Outline} width={20} height={20} />
            </IconButton>
            <TextField size={'small'} value={defaultCharacter} onInput={(e) => setDefaultCharacter((e.target as HTMLInputElement).value)} label={'Default Characters'} />
        </Stack>
    );
};

export default DefaultCharactersEditor;
