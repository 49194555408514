import { useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    FormControlLabel,
    Grid,
    Stack,
    Switch,
} from '@mui/material';
import Info from './Info';
import Form from '../../Form';
import Customer from '../../../models/Customer';
import TextField from '../../Form/fields/TextField';
import useUpdateCustomerDetails from './useUpdateCustomerDetails';
import AddressEditor from './AddressEditor';
import { useParams } from 'react-router-dom';
import useMe from '../../../hooks/useMe';
import SwitcherField from '../../Form/fields/SwitcherField';
import DetailsSchema from './DetailsSchema';
import { useSnackbar } from 'notistack';

type DetailsProps = {
    customer?: Customer;
};

export default function Details({ customer }: DetailsProps): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();
    const { id: customerId } = useParams();
    const { data: me } = useMe();
    const { mutate } = useUpdateCustomerDetails(customerId);
    const [checkedInvoice, setCheckedInvoice] = useState(true);
    const initFormValues: Customer = {
        ...customer,
        name: customer?.name || undefined,
        postalAddress: {
            name: customer?.postalAddress?.name || undefined,
            zip: customer?.postalAddress?.zip || undefined,
            country: customer?.postalAddress?.country || undefined,
            state: customer?.postalAddress?.state || undefined,
            city: customer?.postalAddress?.city || undefined,
            address: customer?.postalAddress?.address || undefined,
        },
        invoiceAddress: {
            name: customer?.invoiceAddress?.name || undefined,
            zip: customer?.invoiceAddress?.zip || undefined,
            country: customer?.invoiceAddress?.country || undefined,
            state: customer?.invoiceAddress?.state || undefined,
            city: customer?.invoiceAddress?.city || undefined,
            address: customer?.invoiceAddress?.address || undefined,
        },
    };

    const toggleCheckedInvoice = () => {
        setCheckedInvoice((prev) => !prev);
    };

    const onSubmit = (form: Customer) => {
        if (!form?.name?.trim()) {
            enqueueSnackbar('Name must be a valid string', { variant: 'error' });
            return false;
        }

        if (checkedInvoice) {
            form.invoiceAddress = form.postalAddress;
        }
        mutate(form);
    };

    return (
        <Container>
            <Form<Customer>
                onSubmit={onSubmit}
                initialValues={initFormValues ?? {}}
                validationSchema={DetailsSchema}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Stack spacing={3}>
                            <Info customerId={customerId} code={customer?.code} />
                            <Card sx={{ mb: 3, p: 2 }}>
                                <CardHeader title='Details' />
                                <CardContent>
                                    <Stack spacing={3}>
                                        <TextField name={'name'} label={'Name'} />
                                        {me?.isAdmin && (
                                            <SwitcherField
                                                label={'Customer price approval'}
                                                name={'mustApprovePrice'}
                                            />
                                        )}
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Card sx={{ mb: 3, p: 2 }}>
                            <CardHeader title='Postal address' />
                            <CardContent>
                                <AddressEditor type={'postal'} />
                            </CardContent>
                        </Card>
                        <Card sx={{ mb: 3, p: 2 }}>
                            <CardHeader title='Invoice address' />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={checkedInvoice}
                                        onChange={toggleCheckedInvoice}
                                    />
                                }
                                label='Same as Postal'
                                labelPlacement='end'
                                sx={{ mb: 1, mx: 2 }}
                            />
                            {!checkedInvoice && (
                                <CardContent>
                                    <AddressEditor type={'invoice'} />
                                </CardContent>
                            )}
                        </Card>
                    </Grid>
                </Grid>
                <Stack direction='column' alignItems='center'>
                    <Button type={'submit'} variant={'contained'}>
                        Save
                    </Button>
                </Stack>
            </Form>
        </Container>
    );
}
