
import { Card, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import Price from '../../../models/Price';
import TranslationOffer from '../../../models/TranslationOffer';
import Form from '../../Form';
import PriceEditors from './PriceEditor';
import useSaveTranslationOffers from './useSaveTranslationOffers';
import useTranslatorOffers from './useTranslationOffers';

export type TranslationOfferWithPrices = TranslationOffer & {
  prices: Price[]
  formType: string
};

export type PriceForm = {
  offers: TranslationOfferWithPrices[]
}

export default function Prices(): JSX.Element {
    const { id: customerId } = useParams();
    const { data: offers } = useTranslatorOffers(customerId);
    const { mutate } = useSaveTranslationOffers();

    const onSubmit = (form: PriceForm) => {
        mutate({ translationOffers: form.offers });
    };


    return (
        <Container>
            <Card sx={{ mb: 1, p: 2 }}>
                <Typography variant='subtitle2' sx={{ p: 1, color: 'text.secondary' }}>
                    The application calculates prices to your tasks according to given Priorities,
                    Source - Target languages and the number of Free/Sworn Low and High pages.
                </Typography>
            </Card>
            <Form<PriceForm> onSubmit={onSubmit} initialValues={{ offers: offers ?? [] }}>
                <PriceEditors />
            </Form>
        </Container>
    );
}
