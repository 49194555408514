import { useQuery } from 'react-query';

function useBinaryLogoByCustomer(customerId?: string) {
    const { data: logo } = useQuery< { id: string }>(`customers/${customerId}/logo`, { enabled: !!customerId });
    return useQuery< { mimetype: string, binary: string }, unknown, string>(`/customers/${customerId}/logo/${logo?.id}`, {
        select(res) {
            return `data:${res.mimetype};base64,` + res.binary;
        },
        enabled: !!logo?.id,
    });
}

export default useBinaryLogoByCustomer;
