import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';
import { useNavigate } from 'react-router';

function useLogout() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => API.post('logout'),
        onSuccess() {
            queryClient.clear();
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('user');
            navigate('/login');
        },
    });
}

export default useLogout;

