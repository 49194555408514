import { Card, Container, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useQuery } from 'react-query';
import User from '../../../../models/User';
import Form from '../../../Form';
import SelectField from '../../../Form/fields/SelectField';
import ChatOptionForm from './ChatOptionForm';
import useAdminOptions from './useAdminOptions';
import useChangeChatAdministrator from './useChangeChatAdministrator';

export default function ChatOptions(): JSX.Element {
    const { data: options } = useAdminOptions();
    const { data: currentRecipient } = useQuery<User>('users/getchatadministrator');
    const { mutate: changeChatAdministrator } = useChangeChatAdministrator();


    const onSubmit = (form: ChatOptionForm) => {
        changeChatAdministrator(form);
    };

    return (
        <Container>
            <Card sx={{ p: 3 }}>
                <Stack spacing={2} sx={{ width: 1 }}>
                    <Stack gap={1}>
                        <Typography variant="overline">
                            Message recipient
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            Users can start conversations with one Admin, please select which one.
                        </Typography>
                    </Stack>

                    <Stack spacing={1}>
                        <Form<ChatOptionForm>
                            onSubmit={onSubmit}
                            initialValues={{ userId: currentRecipient?.id ?? '' }}>
                            <Stack gap={1}>
                                <SelectField name={'userId'} label={'Selected Recipient'} options={options ?? []} />
                                <Stack spacing={3} alignItems="flex-end">
                                    <LoadingButton type="submit" variant="contained">
                                    Save Changes
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </Form>
                    </Stack>
                </Stack>
            </Card>
        </Container>
    );
}
