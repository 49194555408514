import { Stack, Typography } from '@mui/material';
import MessageEditor from '../MessageEditor';
import SearchUser from './SearchUser';

const NewConversation: React.FC = () => {
    return <Stack justifyContent={'space-between'} height={'100%'}>
        <Stack height={80} sx={{ borderBottom: (t) => `1px solid ${t.palette.divider}` }} direction={'row'} alignItems={'center'} gap={1} px={3}>
            <Typography>To: </Typography>
            <SearchUser />
        </Stack>
        <Stack>
            <MessageEditor />
        </Stack>
    </Stack>;
};

export default NewConversation;
