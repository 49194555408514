
import { Box, Card, CardContent, Container, Stack, Typography } from '@mui/material';
import lodash from 'lodash';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { UserRole } from '../../../enums/UserRole';
import useMe from '../../../hooks/useMe';
import Priority from '../../../models/Priority';
import Form from '../../Form';
import PriorityEditor from './PriorityEditor';
import useSavePriorities from './useSavePriorities';

export type PriorityForm = {
  priorities: Priority[]
}

export default function Priorities(): JSX.Element {
    const { id: customerId } = useParams();
    const { data: currentUser } = useMe();
    const { data: priorities } = useQuery<Priority[]>(['priorities', '?', 'customerId=', customerId]);
    const { mutate } = useSavePriorities();
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = (form: PriorityForm) => {
        let errorFlagName = false;
        let errorFlagHours = false;

        form.priorities.map((p) => {
            if (!p?.name?.trim()) {
                errorFlagName = true;
            }

            if (typeof p?.scannedHours !== 'number') {
                errorFlagHours = true;
            }
        });

        if (errorFlagName) {
            enqueueSnackbar('Missing field (name)', { variant: 'error' });
            return false;
        }

        if (errorFlagHours) {
            enqueueSnackbar('Missing field (hours)', { variant: 'error' });
            return false;
        }

        mutate({ priorities: form.priorities });
    };


    return (
        <Container>
            <Stack direction={currentUser?.role !== UserRole.ADMIN ? { xs: 'column', sm: 'row' } : 'column'}>
                {currentUser?.role === UserRole.ADMIN ? (
                    <Form<PriorityForm> onSubmit={onSubmit} initialValues={{ priorities: priorities ?? [] }}>
                        <PriorityEditor />
                    </Form>
                ) : (
                    <>
                        {priorities && priorities.map((priority) => (
                            <Card sx={{ display: 'flex', maxWidth: 200, alignItems: 'center', justifyContent: 'center', m: 1 }}
                                key={priority.id}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography component="div" variant="h5">
                                            {lodash.capitalize(priority.name)}
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            {priority.scannedHours} hours
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Card>
                        ))}
                    </>
                )}
            </Stack>
        </Container>
    );
}
