import React from 'react';
import DatePicker from '../DatePicker';
import { Grid } from '@mui/material';

const DateRangePicker: React.FC<{ lowerBoundName: string; upperBoundName: string; lowerBoundLabel: string; upperBoundLabel: string; disablePast?: boolean }> =
  ({ lowerBoundName, disablePast, upperBoundName, lowerBoundLabel, upperBoundLabel }) => {
      return <Grid container spacing={1}>
          <Grid item xs={6}>
              <DatePicker disablePast={disablePast} label={lowerBoundLabel} name={lowerBoundName} />
          </Grid>
          <Grid item xs={6}>
              <DatePicker label={upperBoundLabel} name={upperBoundName} />
          </Grid>
      </Grid>;
  };

export default DateRangePicker;
