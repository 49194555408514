import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../../../utils/ApiClient';
import { useParams } from 'react-router-dom';

function useUploadTranslatorCertificate() {
    const { id } = useParams();
    const queryClient = useQueryClient();
    return useMutation<unknown, unknown, { file: File }>({
        mutationFn: async (props) => {
            const { file } = props;
            const data = new FormData();
            data.append('certificate', file, file.name);
            await API.post(`/users/${id}/files`, data);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(`users/${id}/files`);
        },
    });
}

export default useUploadTranslatorCertificate;
