import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';
import createQueryString from '../../../utils/createQueryString';


function useAddSuggestionToTask(taskId?: string) {
    const queryClient = useQueryClient();

    return useMutation<Comment, unknown, string>({
        mutationFn: (amount) => {
            const requestBody = {
                amount,
                taskId,
            };
            return API.post('suggestions', requestBody);
        },
        onSuccess() {
            queryClient.invalidateQueries(['suggestions', createQueryString('', { taskId: taskId })]);
        },
    });
}

export default useAddSuggestionToTask;
