import { Typography } from '@mui/material';
import TranslatorReport from '../../../models/TranslatorReport';
import Column from '../../../components/ConnectedTable/Column';
import TotalPrice from './customReportCells/TotalPrice';
import InvoiceCell from './customReportCells/InvoiceCell';
import { Link } from 'react-router-dom';


const columns: Column<TranslatorReport>[] = [
    {
        id: 'user',
        label: 'Name',
        width: 150,
        getter(translatorReport) {
            return <Link to={`/reports/${translatorReport.id}`}>{translatorReport.user?.firstname} {translatorReport.user?.lastname}</Link>;
        },
    },
    {
        id: 'year',
        label: 'Year',
        width: 60,
        getter(translatorReport) {
            return <Typography fontSize={12} noWrap>{translatorReport.year}</Typography>;
        },
    },
    {
        id: 'month',
        label: 'Month',
        width: 60,
        getter(translatorReport) {
            return <Typography fontSize={12} noWrap>{translatorReport.month}</Typography>;
        },
    },
    {
        id: 'invoice',
        label: 'Invoice',
        width: 140,
        getter(translatorReport) {
            return <InvoiceCell invoice={translatorReport.invoice} reportId={translatorReport.id} />;
        },
    },
    {
        id: 'total',
        label: 'Total Price',
        width: 140,
        getter(translatorReport) {
            return <TotalPrice tasks={translatorReport.tasks ?? []}/>;
        },
    },
];


export default columns;
