/* eslint-disable no-unused-vars */
export enum TaskWidgetSlug {
    GENERAL_INFO = 'general-info',
    AUTOTRANSLATION_FILE = 'autotranslation-file',
    TRANSLATED_FILE = 'translated-file',
    ORIGINAL_FILE = 'original-file',
    TASK_DETAILS = 'task-details',
    CUSTOMER_PRICING = 'customer-pricing',
    POSTAL_ADDRESS = 'postal-address',
    STATUS = 'status',
    COMMENTS = 'comments',
    TRANSLATOR_PRICING = 'translator-pricing',
    MODIFICATIONS = 'modifications',
    SUGGESTION = 'suggestion',
    TRACKING_NUMBER = 'tracking-number',
    MERCHANT_ID = 'merchant-id',
    CUSTOMER_EXPLANATION = 'customer-explanation',
    CURRENTLY_WATCHING = 'currently-watching',
}
