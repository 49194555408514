import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
} from '@mui/material';
import File from '../../../models/File';
import { useQuery } from 'react-query';
import InvoiceUpload from '../InvoiceUpload';
import ShowFile from '../../../components/ShowFile';


type ReportProps = {
    reportId?: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function InvoiceDialog({ reportId, setOpen, open }: ReportProps): JSX.Element {
    const { data: file } = useQuery<File>([`translatorreports/${reportId}/invoice`], { enabled: !!reportId });


    const handleOpen = () => {
        setOpen(!open);
    };


    return (
        <Dialog open={open} onClose={handleOpen} maxWidth={'sm'} fullWidth>
            <DialogTitle>
                Manage Invoice
            </DialogTitle>
            <Stack sx={{ height: '100%' }}>
                <DialogContent>
                    {!file ? (
                        <InvoiceUpload reportId={reportId} />
                    ) : (
                        <ShowFile
                            file={file}
                            reportId={reportId}
                        />
                    )}
                </DialogContent>
                <DialogActions
                    sx={{
                        zIndex: 9,
                        padding: '12px !important',
                        boxShadow: (theme) => theme.customShadows.z8,
                    }}
                >
                    <Button color="inherit" onClick={handleOpen}>
                        Close
                    </Button>
                </DialogActions>
            </Stack>
        </Dialog>
    );
}
