import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email required'),
    firstname: Yup.string().required('First name required'),
    lastname: Yup.string().required('Last name required'),
    isDataCorrect: Yup.boolean().oneOf([true], 'Please check the box to confirm your data is correct').required('Please check the box to confirm your data is correct'),
});

export default validationSchema;
