import { ColorSchema } from '../../../../../@types/theme';
import { alpha } from '@mui/material/styles';
import { Card, Stack, Typography } from '@mui/material';
import { Icon } from '@iconify/react';


type Props = {
    title: string;
    total: number | string;
    icon: string;
    color?: ColorSchema;
};

export default function KeyFigure({ title, total, icon, color = 'primary' }: Props) {
    return (
        <Card
            sx={{
                color: (theme) => theme.palette[color].darker,
                bgcolor: (theme) => theme.palette[color].lighter,
            }}
        >
            <Stack direction={'row'} alignItems={'center'} p={2}>
                <Stack
                    sx={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mr: 2,
                        color: (theme) => theme.palette[color].dark,
                        backgroundImage: (theme) =>
                            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
                                theme.palette[color].dark,
                                0.24,
                            )} 100%)`,
                    }}
                >
                    <Icon icon={icon} width={24} height={24} />
                </Stack>
                <Stack>
                    <Typography variant="h3">{total}</Typography>
                    <Typography noWrap variant="subtitle2" sx={{ opacity: 0.72 }}>
                        {title}
                    </Typography>
                </Stack>
            </Stack>
        </Card>
    );
}

