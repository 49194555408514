import MyHolidaysTable from './MyHolidaysTable';
import AddNewHoliday from './AddNewHoliday';
import { Stack } from '@mui/material';
import PageHeader from '../../PageHeader';
import useMe from '../../../hooks/useMe';

const MyHolidays: React.FC = () => {
    const { data: me } = useMe();
    return <Stack gap={2}>
        <PageHeader title={'Holidays'} actions={me?.isTranslator ? <AddNewHoliday /> : null} />
        <MyHolidaysTable />
    </Stack>;
};

export default MyHolidays;
