import { useQuery } from 'react-query';
import useQueryString from '../../hooks/useQueryString';
import qs from 'querystring';


const defaultQuery = {
    offset: 0,
    limit: 25,
};

function useTableData<T>(queryKey: string, defaultFilters?: Record<string, string>) {
    const [urlQuery] = useQueryString();
    const offset = Number(urlQuery['offset'] ?? defaultQuery.offset) * Number(urlQuery['limit'] ?? defaultQuery.limit);

    const filterPrefix = queryKey.slice(0, -1) + '.';
    const query = {
        ...defaultFilters,
        ...defaultQuery,
        ...urlQuery,
        sort: urlQuery['sort'] ? (filterPrefix ?? '') + urlQuery['sort'] : '',
        offset,
    };

    return useQuery<{ totalCount: number} & any, unknown, { rows: T[], totalCount: number }>([queryKey, '?', qs.stringify(query)], {
        staleTime: 10000,
        select: (res) => {
            return {
                totalCount: res['totalCount'],
                rows: res[queryKey],
            };
        } } );
}

export default useTableData;
