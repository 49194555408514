import { v4 as uuidv4 } from 'uuid';
import AutoComplete from '../../../Form/fields/AutoComplete';
import React from 'react';
import CustomerType from '../../../../models/Customer';
import { UserRole } from '../../../../enums/UserRole';
import { Card, Grid, Typography } from '@mui/material';
import useMe from '../../../../hooks/useMe';
import { useFormikContext } from 'formik';
import ProfileEditorForm from '../ProfileEditorForm';

const Customer: React.FC = () => {
    const { data: me } = useMe();
    const { values } = useFormikContext<ProfileEditorForm>();

    if (!me?.isAdmin || !values.role || ![UserRole.USER, UserRole.AGENT].includes(values.role)) {
        return null;
    }

    return <>
        <Grid item xs={8}>
            <Card sx={{ p: 2, height: '100%' }}>
                <Typography mb={2} variant={'h5'}>Customer</Typography>
                <AutoComplete
                    name={'customerId'}
                    label={'Customer'}
                    queryKey={'customers'}
                    queryOptions={{ select: (res: any) => res.customers.map((cust: CustomerType) => ({
                        id: cust.id ?? uuidv4(),
                        name: cust.name ?? 'Missing name',
                    })) }} />
            </Card>
        </Grid>

    </>;
};

export default Customer;
