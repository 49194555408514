import { useQuery } from 'react-query';
import { UserRole } from '../../../enums/UserRole';
import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import User from '../../../../models/User';
import createQueryString from '../../../../utils/createQueryString';

function useAdminOptions() {
    return useQuery<{ users: User[] }, unknown, { id: string; name: ReactElement }[]>(['users', createQueryString('', {
        role: UserRole.ADMIN,
    })], {
        select(data) {
            return data.users.map((user) => ({
                id: user.id ?? 'id missing',
                name: <Typography>{user.firstname}{' '}{user.lastname}</Typography>,
            }));
        },
    });
}

export default useAdminOptions;
