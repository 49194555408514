import { useMutation } from 'react-query';
import { API } from '../../../utils/ApiClient';
import User from '../../../models/User';
import { useSnackbar } from 'notistack';

function useUpdateUser(userId?: string) {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation<unknown, unknown, { user: User }>(({
        mutationFn: (props) => API.put('/users/' + userId, props.user),
        onSuccess: () => {
            enqueueSnackbar('User updated', { variant: 'success' });
        },
    }));
}

export default useUpdateUser;
