import Page from '../../components/Page';
import { Box, CircularProgress, Container } from '@mui/material';
import LiveEditorWrapper from './LiveEditorWrapper';
import useAnnotation from './useAnnotation';
import { useParams } from 'react-router-dom';
import { EditorProvider } from './EditorContext';

const LiveEditor = () => {
    const { id: taskId, fileId } = useParams();
    const { data, isLoading, isError } = useAnnotation(taskId, fileId);

    if (isLoading) {
        return (
            <Box
                sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (!data || isError) {
        return (
            <Box
                sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                Resource not found
            </Box>
        );
    }

    return (
        <EditorProvider>
            <Page title='Live Editor | WeTranslate'>
                <LiveEditorWrapper annotations={data.annotations} />
            </Page>
        </EditorProvider>
    );
};

export default LiveEditor;
