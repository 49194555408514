import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { Box, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { useSnackbar } from 'notistack';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { MIconButton } from '../../components/@material-extend';
import config from '../../config';
import convertBytes from '../../utils/ByteConverter';
import attachIcon from '@iconify/icons-eva/attach-2-fill';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
    'display': 'flex',
    'textAlign': 'center',
    'position': 'relative',
    'alignItems': 'center',
    'paddingTop': theme.spacing(1),
    'border': `1px ${theme.palette.grey[500_32]}`,
    '&:hover': {
        opacity: 0.72,
        cursor: 'pointer',
    },
}));

// ----------------------------------------------------------------------

interface UploadFileIconProps extends DropzoneOptions {
    onUpload: (file: globalThis.File) => void;
    error?: boolean;
    sx?: SxProps<Theme>;
}

export default function UploadFileIcon({
    error = false,
    onUpload,
    ...other
}: UploadFileIconProps): JSX.Element {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        multiple: false,
        onDropAccepted: (file) => onUpload(file[0]),
        maxSize: config.uploadLimit,
        onDropRejected: (response) => {
            enqueueSnackbar(
                response[0].errors[0].code === 'file-too-large' ?
                    `Uploaded file is too large! Maximum allowed file size: ${convertBytes(config.uploadLimit)}, 
                    actual file size: ${convertBytes(response[0].file.size)}` :
                    'Unknown error occurred during file upload!',
                {
                    autoHideDuration: 5000,
                    variant: 'error',
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    ),
                });
        },
        ...other,
    });

    return (
        <Box>
            <DropZoneStyle
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...((isDragReject || error) && {
                        color: 'error.main',
                        borderColor: 'error.light',
                        bgcolor: 'error.lighter',
                    }),
                }}
            >
                <input {...getInputProps()} />
                <Icon icon={attachIcon} width={25} height={25} />
            </DropZoneStyle>
        </Box>
    );
}
