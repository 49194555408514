import { useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { useQueryClient } from 'react-query';

function useTaskParseUpdate(socket: Socket | null) {
    const queryClient = useQueryClient();

    useEffect(() => {
        socket?.on(
            'TRANSLATION_FILE_PARSE_STATUS_UPDATE',
            (message: {parseStatus: any, taskId: string, originalFileCharacterCount: number}) => {
                queryClient.invalidateQueries(`/tasks/${message.taskId}/files`);
            });

        return () => {
            socket?.off('TRANSLATION_FILE_PARSE_STATUS_UPDATE');
        };
    }, [queryClient, socket]);
}

export default useTaskParseUpdate;
