import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';
import { TranslationOfferWithPrices } from './Prices';
import { useSnackbar } from 'notistack';

function useSaveTranslationOffers() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: async ({ translationOffers }: {translationOffers: TranslationOfferWithPrices[]}) => {
            const translatorOffersWithoutPricesFields = translationOffers.map((translationOffer) => ({
                ...translationOffer,
                translatorPrices: undefined,
            }));
            await API.post('/translationoffers/bulkcreate', translatorOffersWithoutPricesFields);
            const prices = translationOffers.flatMap((to) => to.translatorPrices);
            await API.post('/translatorprices/bulkcreate', prices);
        },
        onSuccess() {
            queryClient.invalidateQueries('translationOffers');
            enqueueSnackbar('Prices saved', { variant: 'success' });
        },
    });
}

export default useSaveTranslationOffers;
