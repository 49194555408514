import edit2Fill from '@iconify/icons-eva/edit-2-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Icon } from '@iconify/react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import User from '../../../../models/User';

type ActionsProps = {
    user: User
};

const Actions = ({ user }: ActionsProps) => {
    const menuRef = useRef(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            <IconButton ref={menuRef} size="large" onClick={() => setOpen(true)}>
                <Icon icon={moreVerticalFill} width={20} height={20} />
            </IconButton>

            <Menu
                open={open}
                anchorEl={menuRef.current}
                onClose={() => setOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' },
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem onClick={() => navigate(`/profile/${user.id}`)}>
                    <Icon icon={edit2Fill} width={20} height={20} />
                    <Typography variant="body2" sx={{ ml: 2 }}>
                        Edit
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    );
};

export default Actions;
