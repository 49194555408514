import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import TaskWidget from '../../../../models/TaskWidget';
import { API } from '../../../../utils/ApiClient';

type WidgetsUpdateData = Partial<TaskWidget>[];

function useUpdateTaskWidgets() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (updateData: WidgetsUpdateData) =>
            API.put('task-widgets', { widgets: updateData }),
        onSuccess: () => {
            queryClient.invalidateQueries('task-widgets');
            enqueueSnackbar('Task widgets updated successfully', { variant: 'success' });
        },
        onError() {
            enqueueSnackbar('Error occurred', { variant: 'error' });
        },
    });
}

export default useUpdateTaskWidgets;
