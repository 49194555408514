import { QueryClient, QueryClientConfig } from 'react-query';
import defaultQueryFn from './defaultQueryFn';


const queryClient = (onError: (e: { data: { error: { message?: string, detail?: string }} }) => void): QueryClient => new QueryClient({
    defaultOptions: {
        queries: {
            queryFn: defaultQueryFn,
            retry: false,
            refetchIntervalInBackground: false,
            refetchOnReconnect: true,
            refetchOnWindowFocus: false,
            refetchInterval: false,
            onError,
        },
        mutations: {
            onError,
        },
    },
} as QueryClientConfig);


export default queryClient;
