import { MenuItem, MenuList, Stack, TextField, Typography } from '@mui/material';
import useAutocomplete from '../../../hooks/useAutocomplete';
import User from '../../../models/User';
import { useNavigate } from 'react-router';
import useMe from '../../../hooks/useMe';


function SearchUser() {
    const { data: me } = useMe();
    const navigate = useNavigate();
    const { options, hasNoOptions, onChange } = useAutocomplete<User>(`users?${ me?.isAdmin ? '' : 'role=admin&'}limit=4&offset=0&name=`, 'users', false);

    const onSelectUser = (userId: string) => {
        navigate('/chat/' + userId);
    };

    return (
        <Stack position={'relative'}>
            <TextField
                onBlur={() => {
                    onChange({ target: { value: '' } });
                }}
                size={'small'}
                label={'Search user'}
                onChange={onChange}
            />

            <Stack display={options ? 'flex' : 'none'} position={'absolute'} top={'calc(100% + 4px)'} width={'100%'}>
                <MenuList
                    sx={{
                        borderRadius: 1,
                        backgroundColor: (t) => t.palette.background.paper,
                        border: (t) => `1px solid ${t.palette.divider}`,
                    }}>
                    {hasNoOptions && <Typography px={1}>No options</Typography>}
                    {options?.map((option, index) => (
                        <MenuItem
                            disabled={option.id === me?.id}
                            onClick={() => {
                                onSelectUser(option.id ?? '');
                            }} key={index}>{option.firstname + ' ' + option.lastname}</MenuItem>
                    ))}
                </MenuList>
            </Stack>
        </Stack>
    );
}

export default SearchUser;
