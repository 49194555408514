import { isString } from 'lodash';
import React, { ReactNode } from 'react';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import roundAddAPhoto from '@iconify/icons-ic/round-add-a-photo';
import { Theme, styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useSnackbar } from 'notistack';
import config from '../../config';
import { MIconButton } from '../@material-extend';
import convertBytes from '../../utils/ByteConverter';
// import File from '../../models/File';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    width: 144,
    height: 144,
    margin: 'auto',
    borderRadius: '50%',
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled('div')({
    'zIndex': 0,
    'width': '100%',
    'height': '100%',
    'outline': 'none',
    'display': 'flex',
    'overflow': 'hidden',
    'borderRadius': '50%',
    'position': 'relative',
    'alignItems': 'center',
    'justifyContent': 'center',
    '& > *': { width: '100%', height: '100%' },
    '&:hover': {
        'cursor': 'pointer',
        '& .placeholder': {
            zIndex: 9,
        },
    },
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
    'display': 'flex',
    'position': 'absolute',
    'alignItems': 'center',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'color': theme.palette.text.secondary,
    'backgroundColor': theme.palette.background.neutral,
    'transition': theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

interface UploadAvatarProps extends DropzoneOptions {
  error?: boolean;
  file?: any,
  sx?: SxProps<Theme>;
  caption?: ReactNode;
  onUpload: (file: globalThis.File) => void;
}

export default function UploadAvatar({
    error = false,
    file,
    caption,
    sx,
    onUpload,
    ...other }: UploadAvatarProps): JSX.Element {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        multiple: false,
        onDropAccepted: (file) => onUpload(file[0]),
        maxSize: config.uploadLimit,
        onDropRejected: (response) => {
            enqueueSnackbar(
                response[0].errors[0].code === 'file-too-large' ?
                    `Uploaded file is too large! Maximum allowed file size: ${convertBytes(config.uploadLimit)}, 
                    actual file size: ${convertBytes(response[0].file.size)}` :
                    'Unknown error occurred during file upload!',
                {
                    autoHideDuration: 5000,
                    variant: 'error',
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    ),
                });
        },
        ...other,
    });

    return (
        <>
            <RootStyle sx={sx}>
                <DropZoneStyle
                    {...getRootProps()}
                    sx={{
                        ...(isDragActive && { opacity: 0.72 }),
                        ...((isDragReject || error) && {
                            color: 'error.main',
                            borderColor: 'error.light',
                            bgcolor: 'error.lighter',
                        }),
                    }}
                >
                    <input {...getInputProps()} />

                    {file && (
                        <Box
                            component="img"
                            alt=""
                            src={isString(file) ? file : URL.createObjectURL(file)}
                            sx={{ zIndex: 8, objectFit: 'cover' }}
                        />
                    )}

                    <PlaceholderStyle
                        className="placeholder"
                        sx={{
                            ...(file && {
                                'opacity': 0,
                                'color': 'common.white',
                                'bgcolor': 'grey.900',
                                '&:hover': { opacity: 0.72 },
                            }),
                        }}
                    >
                        <Box component={Icon} icon={roundAddAPhoto} sx={{ width: 24, height: 24, mb: 1 }} />
                        <Typography variant="caption">{file ? 'Update photo' : 'Upload photo'}</Typography>
                    </PlaceholderStyle>
                </DropZoneStyle>
            </RootStyle>
            {caption}
        </>
    );
}
