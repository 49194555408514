/* eslint-disable indent */
export default function getFontFamily(langCode: string): string {
    switch (langCode) {
        case 'zh-CN' || 'zh':
            return `'ZCOOL XiaoWei', serif`;

        case 'ko':
            return `'Noto Serif KR', serif`;

        case 'th':
            return `'Noto Sans Thai', sans-serif`;

        case 'ja':
            return `'Sawarabi Gothic', sans-serif`;

        case 'ar':
            return `'Noto Sans Arabic', sans-serif`;

        default:
            return `'Roboto', sans-serif`;
    }
}
