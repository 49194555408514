import { useFormikContext } from 'formik';
import { Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import SelectRemoteOptions from '../../../Form/fields/SelectRemoteOptions';
import React from 'react';
import Button from '@mui/material/Button';
import UserLanguage from '../../../../models/UserLanguage';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import { UserRole } from '../../../../enums/UserRole';
import ProfileEditorForm from '../ProfileEditorForm';

const Languages: React.FC = () => {
    const { values, setFieldValue } = useFormikContext<ProfileEditorForm>();

    const addLanugage = () => {
        const newLanguage: UserLanguage = {
            language: undefined, languageId: '', type: '',

        };
        setFieldValue('languages', values.languages ? [...values.languages, newLanguage] : [newLanguage]);
    };

    const deleteLanguageByIndex = (index: number) => {
        const newLanguages = values.languages ? [...values.languages] : [];
        setFieldValue('languages', newLanguages.filter((_, i) => i !== index));
    };

    const isUserTranslator = values.role === UserRole.TRANSLATOR;

    if (!isUserTranslator) {
        return null;
    }

    return <Grid item xs={12} >
        <Card sx={{ p: 2 }}>
            <Typography mb={2} variant={'h5'}>Languages</Typography>
            <Grid container spacing={2}>{values.languages?.map((lang, index) => (
                <Grid item xs={4} key={lang.languageId + '' + index}>
                    <Stack direction={'row'} gap={1}>
                        <IconButton onClick={() => deleteLanguageByIndex(index)}>
                            <Icon icon={trash2Outline} width={20} height={20} />
                        </IconButton>
                        <SelectRemoteOptions label={'Language'} name={`languages[${index}].id`} queryKey={'languages'}/>
                    </Stack>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Button onClick={addLanugage}>Add language</Button>
            </Grid>
            </Grid>
        </Card>
    </Grid>
    ;
};

export default Languages;
