import React, { useMemo } from 'react';
import SelectField from '../../../components/Form/fields/SelectField';
import { useFormikContext } from 'formik';
import Task from '../../../models/Task';
import usePriorityOptions from './usePriorityOptions';

const PrioritySelector: React.FC = () => {
    const { values } = useFormikContext<Task>();
    const { data: options } = usePriorityOptions(values);

    return useMemo(() => {
        return <SelectField options={options ?? []} name={'priorityId'} label={'How urgent is the Translation?'} />;
    }, [options]);
};

export default PrioritySelector;
