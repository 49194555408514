import { Button, Stack } from '@mui/material';
import { useMemo } from 'react';
import MessageType from '../../../../models/Messsage';
import Message from './Message';
import useMessages from './useMessages';


const Messages: React.FC = () => {
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useMessages();


    const messages: MessageType[] = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.pages.reduce((acc: any, curr) => {
            return [...acc, ...curr.data.messages];
        }, []);
    }, [data]);

    return (
        <Stack
            // justifyCozntent={'space-between'}
            pt={10} overflow={'auto'} height={'100%'} direction={'column-reverse'} px={2} gap={2} pb={2}>
            {messages?.map((message) => (
                <Message message={message} key={message.created} />
            ))}
            <Stack>
                {hasNextPage &&
                  <Button disabled={isFetchingNextPage} onClick={() => fetchNextPage()}>Load more</Button>}
            </Stack>
        </Stack>
    );
};

export default Messages;
