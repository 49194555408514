import User from '../../../../models/User';
import Label from '../../../../components/Label';
import React from 'react';
import { useTheme } from '@mui/material';

const Status: React.FC<{ user: User }> = ({ user }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const getLabel = () => {
        if (user.active) {
            return 'Active';
        }

        if (!user.canLogin) {
            return 'Cannot login';
        }

        return 'Inactive';
    };

    return <Label
        variant={isLight ? 'ghost' : 'filled'}
        color={user.active ? 'success' : 'warning'}
    >
        {getLabel()}
    </Label>;
};

export default Status;
