import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Task from '../models/Task';
import { TaskStatus } from '../enums/TaskStatus';

type DeadlineProps = {
    task: Task;
};

export default function Deadline({ task }: DeadlineProps): JSX.Element {
    if (task.status === TaskStatus.INVALID_SOURCE_FILE || task.status === TaskStatus.INVALID_LANGUAGE) {
        return (
            <Typography variant='caption'>Cannot be assigned</Typography>
        );
    }

    if (task.status === TaskStatus.APPROVED || task.status === TaskStatus.TRANSLATION_READY) {
        return (
            <Typography variant='caption'>Done</Typography>
        );
    }

    if (task.status === TaskStatus.CANCELLED) {
        return (
            <Typography variant='caption'>Cancelled</Typography>
        );
    }

    const getColor = (task: Task) => {
        if (task.remainingHours) {
            if (task.remainingHours >= 16) {
                return 'green';
            } else if (task.remainingHours < 16 && task.remainingHours >= 8) {
                return 'orange';
            } else if (task.remainingHours < 8 && task.remainingHours >= 0) {
                return 'red';
            } else return 'black';
        }
    };


    return (
        <Stack direction='row' sx={{ alignItems: 'center' }}>
            <Box component='span' sx={{
                width: 12, height: 12, mr: 0.5,
                backgroundColor: getColor(task), borderRadius: '50%',
            }} />
            <Typography variant='caption'>{task.remainingHours} hours</Typography>
        </Stack>
    );
}
