import { useInfiniteQuery } from 'react-query';
import { API } from '../../../../utils/ApiClient';
import { useParams } from 'react-router-dom';
import Message from '../../../../models/Messsage';

const LIMIT = 8;

function useMessages() {
    const { userId } = useParams();
    const fetchMessages = ({ pageParam }: { pageParam: number}) => API.get(`/conversations/${userId}/messages?offset=${pageParam ?? 0}&limit=${LIMIT}`);

    return useInfiniteQuery<any, unknown, { data: { messages: Message[] } }, any>({
        staleTime: 100000,
        queryKey: 'messages/' + userId,
        queryFn: fetchMessages,
        getNextPageParam: (lastPage: any, pages: any) => {
            const currentLength = pages.reduce((acc: any, curr: { data: { messages: Message[] } }) => {
                return [...acc, ...curr.data.messages];
            }, []).length;

            if (currentLength >= lastPage.data.totalCount) {
                return;
            }

            return pages.length * LIMIT;
        },
    });
}

export default useMessages;
