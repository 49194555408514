import { useMutation, useQueryClient } from 'react-query';
import { FileType } from '../../../enums/FileType';
import { API } from '../../../utils/ApiClient';

function useUploadInvoiceToReport(reportId?: string) {
    const queryClient = useQueryClient();

    return useMutation<{ status: number }, unknown, { file: File }>({
        mutationFn: ({ file }) => {
            const data = new FormData();
            const type = FileType.INVOICE;
            data.append(type, file, file.name);
            return API.post(`/translatorreports/${reportId}/invoice`, data);
        },
        onSuccess() {
            queryClient.invalidateQueries(`translatorreports/${reportId}/invoice`);
        },
    });
}

export default useUploadInvoiceToReport;
