import { Box, Paper, ScopedCssBaseline } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import TranslatedText from './TranslatedText';
import useEditor from './useEditor';

type PropTypes = {
    show: 'translated' | 'both' | 'original';
};

const TranslatedDocument = ({ show }: PropTypes) => {
    const docRef = useRef<HTMLDivElement>(null);
    const { setSelectedBlockId, setSelectedScrollToId } = useEditor();
    const [ratio, setRatio] = useState(0);
    const [docSize, setDocSize] = useState({
        width: 0,
        height: 0,
    });
    const {
        editorState: { selectedPageData, sidebarStatus },
    } = useEditor();

    const handlePaperClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.currentTarget === e.target) {
            setSelectedBlockId('');
            setSelectedScrollToId('');
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (docRef.current && selectedPageData) {
                const { width, height } = selectedPageData;
                const displayWidth = docRef.current.clientWidth;
                const _ratio = height / width;
                const displayHeight = Math.floor(displayWidth * _ratio);

                setDocSize({
                    width: displayWidth,
                    height: displayHeight,
                });
                setRatio(displayHeight / height);
            }
        }, 300);
    }, [selectedPageData, show, sidebarStatus]);

    if (!selectedPageData) return null;

    const { blocks } = selectedPageData;

    return (
        <Box sx={{ display: 'flex', width: '100%' }} justifyContent="center" alignItems="center">
            <ScopedCssBaseline style={{ width: '100%' }}>
                <Paper
                    sx={{
                        borderRadius: '3px',
                        height: `${docSize.height}px`,
                        width: `100%`,
                        backgroundColor: '#fafafa',
                        border: '1px dashed #a5a4a4',
                        borderColor: 'primary.main',
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                    ref={docRef}
                    elevation={0}
                    onClick={handlePaperClick}
                >
                    {blocks.map((block) => (
                        <TranslatedText ratio={ratio} docSize={docSize} block={block} key={block.id} />
                    ))}
                </Paper>
            </ScopedCssBaseline>
        </Box>
    );
};

export default TranslatedDocument;
