import { Icon } from '@iconify/react';
import upFill from '@iconify/icons-eva/arrow-up-fill';
import downFill from '@iconify/icons-eva/arrow-down-fill';
import * as React from 'react';

function getFilterIcon<T>(id: keyof T, currentSort?: keyof T, currentOrder?: 'ASC' | 'DESC') {
    if (currentSort !== id) return null;
    if (currentOrder === 'ASC') return <Icon icon={upFill}/>;
    if (currentOrder === 'DESC') return <Icon icon={downFill}/>;

    return null;
}

export default getFilterIcon;
