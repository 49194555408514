import React from 'react';
import SvgIconStyle from '../../components/SvgIconStyle';
import { UserRole } from '../../enums/UserRole';
import User from '../../models/User';
import LocalStorageManager from '../../utils/LocalStorageManager';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
    <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
    user: getIcon('ic_user'),
    components: getIcon('ic_components'),
    analytics: getIcon('ic_analytics'),
    kanban: getIcon('ic_kanban'),
    company: getIcon('ic_job'),
    banking: getIcon('ic_banking'),
};

export type Subheader = {
    subheader: string;
    items: SubheaderItem[];
    icon?: JSX.Element;
};

type SubheaderItem = {
    title: string;
    path: string;
    icon: JSX.Element;
    hidden?: boolean;
};

const getSidebarConfig = (): Subheader[] => {
    const currentUser = LocalStorageManager.getUser();

    if (!currentUser || typeof currentUser?.role === 'undefined') return [];

    // General
    const general: Subheader = {
        subheader: '',
        items: [
            {
                title: 'Translation Tasks',
                path: '/tasks',
                icon: ICONS.components,
            },
        ],
    };

    // Admin
    const admin: Subheader = {
        subheader: 'administration',
        items: [
            {
                title: 'Users',
                path: '/users',
                icon: ICONS.user,
            },
            {
                title: 'Platform settings',
                path: '/settings',
                icon: ICONS.kanban,
            },
            {
                title: 'Customers',
                path: '/customers',
                icon: ICONS.company,
            },
            {
                title: 'Pricing Summary',
                path: '/summary',
                icon: ICONS.banking,
            },
            // {
            //     title: 'Invoice History',
            //     path: '/invoice',
            //     icon: ICONS.banking,
            // },
        ],
    };

    // Agent
    const agent: Subheader = {
        subheader: 'Customer options',
        items: [
            {
                title: 'Manage',
                path: `/customers/${currentUser.customerId}`,
                icon: ICONS.kanban,
            },
            {
                title: 'Pricing Summary',
                path: '/customer-summary',
                icon: ICONS.banking,
            },
            // {
            //     title: 'Invoice History',
            //     path: '/customer-invoice',
            //     icon: ICONS.banking,
            // },
        ],
    };

    // Linguist
    const linguist: Subheader = {
        subheader: 'administration',
        items: [
            {
                title: 'Translators',
                path: '/users',
                icon: ICONS.user,
            },
        ],
    };

    // translator
    const translator: Subheader = {
        subheader: 'administration',
        items: [
            {
                title: 'Reports',
                path: '/reports',
                icon: ICONS.banking,
            },
        ],
    };

    return User.hasRole(currentUser, [UserRole.ADMIN]) ?
        [general, admin] : User.hasRole(currentUser, [UserRole.LINGUIST]) ?
            [general, linguist] : User.hasRole(currentUser, [UserRole.AGENT]) ?
                [general, agent] : User.hasRole(currentUser, [UserRole.TRANSLATOR]) ?
                    [general, translator] : [general];
};

export default getSidebarConfig;
