import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import User from '../models/User';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useSeenConversation from '../pages/Chat/Conversation/useSeenConversation';
import { useNavigate } from 'react-router';
import { Socket } from 'socket.io-client';


const useMessagingSubscription = (socket: Socket | null) => {
    const { mutate: seenConvo } = useSeenConversation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        socket?.on('NEW_MESSAGE', ({ sender }: { sender: User }) => {
            if (document.hidden && Notification.permission === 'granted') {
                const notification = new Notification(`${sender.firstname} ${sender.lastname} sent you a message!`, {
                    body: 'Click to open',
                });
                notification.onclick = () => {
                    window.focus();
                    notification.close();
                    navigate('/chat/' + sender.id);
                };
            }
            if (!location.pathname.includes('/chat')) {
                enqueueSnackbar(`${sender.firstname} ${sender.lastname} sent you a message`, {
                    variant: 'info',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
            }
            if (location.pathname === `/chat/${sender.id}`) {
                seenConvo({ id: sender.id });
            }
            queryClient.invalidateQueries('conversations/number-of-unseen');
            queryClient.invalidateQueries('messages/' + sender.id);
            queryClient.invalidateQueries('conversations?name=');
        });
        return () => {
            socket?.off('NEW_MESSAGE');
        };
    }, [enqueueSnackbar, location.pathname, navigate, queryClient, seenConvo, socket]);
};

export default useMessagingSubscription;
