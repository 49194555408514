function parseJSON<T>(raw: string, fallback: T): T {
    let result: T;

    try {
        result = JSON.parse(raw);
    } catch (e) {
        result = fallback;
    }

    return result;
}

export default parseJSON;
