/* eslint-disable no-unused-vars */
export enum UserRole {
    ADMIN = 'admin',
    AGENT = 'agent',
    USER = 'user',
    LINGUIST = 'linguist',
    TRANSLATOR = 'translator',
}

export function isAdmin(role: UserRole): boolean {
    return UserRole.ADMIN === role;
}

export const roles: UserRole[] = [UserRole.USER, UserRole.ADMIN, UserRole.LINGUIST, UserRole.TRANSLATOR, UserRole.AGENT];
