import React, { useMemo } from 'react';
import { useField } from 'formik';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';


type RadioFieldProps = {
  label: string;
  name: string;
  disabled?: boolean;
  options: {
    value: unknown;
    label: string
  }[]
}

const RadioField: React.FC<RadioFieldProps> = ({ label, name, options, disabled }) => {
    const [field, meta] = useField(name);
    const hasError = Boolean(meta.touched && meta.error);
    const helperText = hasError && meta.error;

    return useMemo(() => {
        return (
            <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
                <RadioGroup
                    name={name}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Select option"
                    value={field.value}
                    onChange={field.onChange}
                    sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                >
                    {options.map((option) => <FormControlLabel key={option.label} value={option.value} control={<Radio disabled={disabled}/>} label={option.label}/>)}
                </RadioGroup>
                {helperText && <FormHelperText sx={{ mt: -0.5 }} error>{helperText}</FormHelperText>}
            </FormControl>
        );
    }, [field, helperText, label, name, options, disabled]);
};

export default RadioField;
