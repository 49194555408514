import React from 'react';
import UserEvent from '../../../models/UserEvent';
import parseJSONObjectToKeyValueArray from '../../../utils/parseJSONObjectToKeyValueArray';
import useMe from '../../../hooks/useMe';
import { Stack, Typography } from '@mui/material';

const EventLogTooltip: React.FC<{ event: UserEvent }> = ({ event }) => {
    const details = parseJSONObjectToKeyValueArray(event.details);
    const { data: me } = useMe();

    return <>
        <Stack>
            <Typography
                sx={{ display: 'block', fontSize: 11 }}
                component="span"
                variant="caption"
            >
                <strong>{event.role}</strong>{me?.isAdmin && ` - ${event.username}`}
            </Typography>
            {details.map((detail) => (
                <Typography
                    key={detail.key}
                    sx={{ display: 'block', fontSize: 11 }}
                    component="span"
                    variant="caption"
                >
                    <strong>{detail.key}</strong> - {detail.value}
                </Typography>
            ),
            )}
        </Stack></>;
};

export default EventLogTooltip;
