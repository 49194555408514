import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import Customer from '../../../models/Customer';
import { API } from '../../../utils/ApiClient';

function useUpdateCustomerDetails(customerId?: string) {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation<{ status: number }, unknown, Customer, unknown>({
        mutationFn: (form) => API.put(`customers/${customerId}`, form ),
        onSuccess() {
            queryClient.invalidateQueries('customers');
            enqueueSnackbar('Customer updated', {
                variant: 'success',
            });
        },
    });
}

export default useUpdateCustomerDetails;
