import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { Box, Button, Container, MenuItem, FormControl, InputLabel, Select,
    Stack, TextField, Typography, LinearProgress, OutlinedInput, Checkbox, ListItemText } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { SelectChangeEvent } from '@mui/material/Select';
import fileDownload from 'js-file-download';
import lodash from 'lodash';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { MIconButton } from '../components/@material-extend';
import SummaryList from '../components/admin/SummaryList';
import Page from '../components/Page';
import { TaskStatus } from '../enums/TaskStatus';
import Task from '../models/Task';
import useAPI from '../utils/ApiClient';
import LocalStorageManager from '../utils/LocalStorageManager';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRange, DateRangePicker } from '@mui/lab';

type ParamsType = {
    [key: string]: string | number | null | undefined | boolean | string[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CustomerSummary(): JSX.Element {
    const currentUser = LocalStorageManager.getUser();

    const [, getTasks] = useAPI<{ tasks: Task[], totalCount: number, total?: number }>(
        { url: '/tasks', method: 'GET' },
        { manual: true },
    );

    const [, exportData] = useAPI({ url: '/export' }, { manual: true });

    const [tasks, setTasks] = useState<Task[]>();
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleExport = () => {
        const params: ParamsType = {};
        if (currentUser) {
            if (startDate && endDate) {
                params.after = startDate?.format('YYYY-MM-DD');
                params.before = endDate?.format('YYYY-MM-DD');
            }
            params.customerId = currentUser.customerId;
            params.status = selectedStatus;
            params.summary = true;


            exportData({ responseType: 'blob', params }).then((res) => {
                fileDownload(res.data, `WeTranslate_${moment().format('YYYYMMDDHHmm')}.xlsx`);
            });
        } else {
            enqueueSnackbar('Error occured!', {
                variant: 'error',
                action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                ),
            });
        }
    };

    const handleStatusChange = (event: SelectChangeEvent<typeof selectedStatus>) => {
        const {
            target: { value },
        } = event;
        setSelectedStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handlePageChange = (params: ParamsType) => {
        getTasks({ params }).then((res)=> {
            if (!res || !res.data) return;

            setTasks(res.data.tasks);
            setTotalCount(res.data.totalCount);
        });
    };

    const handleRowsChange = (params: ParamsType) => {
        getTasks({ params }).then((res) => {
            if (!res || !res.data) return;

            setTasks(res.data.tasks);
            setTotalCount(res.data.totalCount);
        }).catch((err) => console.error(err));
    };

    const handleDateSelect = async (ranges: DateRange<Moment>) => {
        setStartDate(ranges[0]);
        setEndDate(ranges[1]);
    };

    const handleSearch = (
        status: string[],
        startDate?: Moment | null,
        endDate?: Moment | null,
    ) => {
        const params: ParamsType = {};
        if (currentUser) {
            setLoading(true);
            if (startDate && endDate) {
                params.after = startDate?.format('YYYY-MM-DD');
                params.before = endDate?.format('YYYY-MM-DD');
            }
            params.customerId = currentUser.customerId;
            params.status = status;
            params.summary = true;

            getTasks({ params }).then((res) => {
                if (!res || !res.data) return;

                setTasks(res.data.tasks);
                setTotalCount(res.data.totalCount);
                setTotalPrice(res.data.total ?? 0);
                setLoading(false);
            }).catch((err) => console.error(err));
        } else {
            enqueueSnackbar('Error occured!', {
                variant: 'error',
                action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                ),
            });
        }
    };

    return (
        <Page>
            <Container maxWidth={'lg'}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 5, justifyContent: 'space-between' }}>
                    <Typography variant="h4" gutterBottom >Customer Summary</Typography>
                </Box>
                <Box sx={{ mb: 5 }} />

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ py: 2 }}>
                    <Stack sx={{ width: '20%', minWidth: 300 }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateRangePicker
                                label="Creation date"
                                value={[startDate, endDate]}
                                startText="Creation date"
                                onChange={(newRange: any) => handleDateSelect(newRange)}
                                renderInput={({ inputProps, ...startProps }: any, endProps: any) => {
                                    const startValue = inputProps?.value;
                                    delete inputProps?.value;
                                    return (
                                        <TextField
                                            {...startProps}
                                            sx={{ width: '100%', minWidth: 300 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <MIconButton size="small" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDateSelect([null, null]);
                                                    }}>
                                                        <Icon icon={closeFill} />
                                                    </MIconButton>
                                                ),
                                            }}
                                            inputProps={inputProps}
                                            value={
                                                startValue || endProps.inputProps?.value ?
                                                    `${startValue} - ${endProps.inputProps?.value}` :
                                                    ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </LocalizationProvider>
                    </Stack>
                    <Stack sx={{ width: '20%', minWidth: 300 }}>
                        <FormControl>
                            <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedStatus}
                                onChange={handleStatusChange}
                                input={<OutlinedInput label="Status" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {Object.values(TaskStatus).map((status) => (
                                    <MenuItem key={status} value={status}>
                                        <Checkbox checked={selectedStatus.indexOf(status) > -1} />
                                        <ListItemText primary={ lodash.capitalize(status.replaceAll('_', ' ')) } />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Button
                        variant="contained"
                        onClick={() => handleSearch(selectedStatus, startDate, endDate)}
                    >
                        Find
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleExport()}
                    >
                        Export
                    </Button>
                </Stack>

                {!loading ? (
                    <Box>
                        <SummaryList
                            tasks={tasks}
                            handlePageChange={handlePageChange}
                            handleRowsChange={handleRowsChange}
                            totalCount={totalCount}
                            totalPrice={totalPrice}
                            startDate={startDate}
                            endDate={endDate}
                            customerId={currentUser?.customerId}
                        />
                    </Box>
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                )}

            </Container>
        </Page>
    );
}
