import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';

function useDeletePriority() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }: {id?: string}) => API.delete('/priorities/' + id),
        onSuccess() {
            queryClient.invalidateQueries('priorities');
        },
    });
}

export default useDeletePriority;
