import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import * as React from 'react';

const NoItemsToShow: React.FC<{ numberOfColumns: number; numberOfRows: number }> = ({ numberOfColumns, numberOfRows }) => {
    return <TableRow>
        <TableCell sx={{ py: 3 }} align={'center'} colSpan={numberOfColumns} rowSpan={numberOfRows}>
            <Stack alignItems={'center'} justifyContent={'center'} gap={2}>
                <img width={160} alt="empty content"
                    src="/images/illustration_empty_content.svg"/>
                <Typography color={'text.secondary'} variant={'h6'}>No items to show</Typography>
            </Stack>
        </TableCell>
    </TableRow>;
};

export default NoItemsToShow;
