import { Page, View, Text, Document } from '@react-pdf/renderer';
import TranslatorReport from '../../../models/TranslatorReport';
import styles from './PDFStyle';

type ReportProps = {
    report?: TranslatorReport;
};

export default function ReportPDF({ report }: ReportProps): JSX.Element {
    const sum = report?.tasks?.reduce((sum, task) => {
        if (task.translatorPrice) return sum += +task.translatorPrice;
        return sum;
    }, 0);
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={[styles.gridContainer, styles.mb40]}>
                    <View style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
                        <Text style={styles.h3}>Monthly Report</Text>
                        <Text>{report?.month} / {report?.year}</Text>
                    </View>
                </View>

                <View style={[styles.gridContainer, styles.mb40]}>
                    <View style={styles.col6}>
                        <Text style={[styles.overline, styles.mb8]}>For</Text>
                        <Text style={styles.body1}>{report?.user?.firstname} {report?.user?.lastname}</Text>
                        <Text style={styles.body1}>{report?.user?.email}</Text>
                    </View>
                </View>

                <Text style={[styles.overline, styles.mb8]}>Details</Text>

                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell_1}>
                                <Text style={styles.subtitle2}>#</Text>
                            </View>

                            <View style={styles.tableCell_2}>
                                <Text style={styles.subtitle2}>Description</Text>
                            </View>

                            <View style={styles.tableCell_3}>
                                <Text style={styles.subtitle2}>Pages</Text>
                            </View>

                            <View style={styles.tableCell_3}>
                                <Text style={styles.subtitle2}>Price</Text>
                            </View>

                            <View style={[styles.tableCell_3, styles.alignRight]}>
                                <Text style={styles.subtitle2}>Total</Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.tableBody}>
                        {report && report.tasks && report.tasks.map((task, index) => (
                            <View style={styles.tableRow} key={task.id}>
                                <View style={styles.tableCell_1}>
                                    <Text>{index + 1}</Text>
                                </View>

                                <View style={styles.tableCell_2}>
                                    <Text style={styles.subtitle2}>{task.taskId}</Text>
                                </View>

                                <View style={styles.tableCell_3}>
                                    <Text>{task.pages}</Text>
                                </View>

                                <View style={styles.tableCell_3}>
                                    <Text>{task.customTranslatorPrice ? 'Custom' : task.price}</Text>
                                </View>

                                <View style={[styles.tableCell_3, styles.alignRight]}>
                                    <Text>{task.translatorPrice} €</Text>
                                </View>
                            </View>
                        ))}

                        <View style={[styles.tableRow, styles.noBorder]}>
                            <View style={styles.tableCell_1} />
                            <View style={styles.tableCell_2} />
                            <View style={styles.tableCell_3} />
                            <View style={styles.tableCell_3}>
                                <Text style={styles.h4}>Total</Text>
                            </View>
                            <View style={[styles.tableCell_3, styles.alignRight]}>
                                <Text style={styles.h4}>{sum} €</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}
