import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';

function useDeleteHoliday() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ id }: { id: string }) => API.delete(`/translator-holidays/${id}` ),
        onSuccess() {
            queryClient.invalidateQueries('translator-holidays');
        },
    });
}

export default useDeleteHoliday;
