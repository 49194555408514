import moment from 'moment';
import Label from '../../../components/Label';
import Column from '../../../components/ConnectedTable/Column';
import Task from '../../../models/Task';

const columns: Column<Task>[] = [
    { id: 'taskId', label: 'Task ID' },
    { id: 'created', label: 'Created', getter: (task) => task.created ?
        moment(task.created)?.format('MM/DD/YY hh:mm A') :
        '-' },
    { id: 'priority', label: 'Priority', getter: ({ priority }) => priority?.name },
    { id: 'style', label: 'Type' },
    { id: 'priceApprovedAt',
        label: 'Price approved',
        getter: (task) => task.priceApprovedAt ?
            moment(task.priceApprovedAt)?.format('MM/DD/YY hh:mm A') :
            'No need to approve' },
    { id: 'finishedAt', label: 'Finished', getter: (task) => task.finishedAt ?
        moment(task.finishedAt)?.format('MM/DD/YY hh:mm A') :
        '-' },
    { id: 'approvedAt', label: 'Approved', getter: (task) => task.approvedAt ?
        moment(task.approvedAt)?.format('MM/DD/YY hh:mm A') :
        '-' },
    {
        id: 'price',
        label: 'Price',
        getter: (task) => task.price ? task.price + ' €' : '-',
    },
    { id: 'translatorPrice', label: 'Translation price', getter: (task) => task.translatorPrice ? task.translatorPrice + ' €' : '-' },
    { id: 'status',
        width: 170,
        label: 'Status', getter: (task) => <Label
            variant={'ghost'}
            taskStatus={task.status}
        >
            {task.status?.split('_').join(' ')}
        </Label> },

];

export default columns;
