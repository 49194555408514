import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import Discount from '../../../models/Discount';

function useRefetchDiscounts(customerId?: string) {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery<Discount[]>(['discounts', '/', 'handleactivation', '?', 'customerId=', customerId], {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess() {
            enqueueSnackbar('Calculation Success', {
                variant: 'success',
            });
        },
    });
}

export default useRefetchDiscounts;
