import lodash from 'lodash';
import { Skeleton, TableCell, TableRow } from '@mui/material';
import * as React from 'react';
import Column from './Column';


type SkeletonRowsProps<T> = {
    columns: Column<T>[]
}

function SkeletonRows<T>({ columns }: SkeletonRowsProps<T>) {
    return <>
        {lodash.times(5).map((empty, index) => <TableRow key={index}>
            {columns
                .filter((c) => !c.isHidden)
                .map((column) => <TableCell
                    sx={{ width: column.width, p: 1 }}
                    key={column.id.toString()} component="td" scope="row">
                    <Skeleton variant={'rectangular'} height={20}/>
                </TableCell>)}
        </TableRow>)}
    </>;
}

export default SkeletonRows;
