import {
    Card, Container, Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Customer from '../../../models/Customer';
import Discount from '../../../models/Discount';
import Form from '../../Form';
import DiscountEditor from './DiscountEditor';
import useSaveDiscounts from './useSaveDiscounts';

type DiscountProps = {
    customer?: Customer;
}

export type DiscountForm = {
  discounts: Discount[]
}

export default function Discounts({ customer }: DiscountProps): JSX.Element {
    const { id: customerId } = useParams();
    const { data: discounts } = useQuery<Discount[]>(['discounts', '?', 'customerId=', customerId]);
    const { mutate } = useSaveDiscounts();

    const onSubmit = (form: DiscountForm) => {
        mutate({ discounts: form.discounts });
    };

    return (
        <Container>
            <Card sx={{ mb: 1, textAlign: 'center', p: 2 }}>
                <Typography variant='h6'>Currently translated page number: {customer?.translatedPageNumber}</Typography>
            </Card>
            <Form<DiscountForm> onSubmit={onSubmit} initialValues={{ discounts: discounts ?? [] }}>
                <DiscountEditor />
            </Form>
        </Container>
    );
}
