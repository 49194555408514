import {
    TableRow,
    TableHead,
    TableCell,
} from '@mui/material';

export default function ReportDetailsTableHead(): JSX.Element {
    return (
        <TableHead
            sx={{
                'borderBottom': (theme) => `solid 1px ${theme.palette.divider}`,
                '& th': { backgroundColor: 'transparent' },
            }}
        >
            <TableRow>
                <TableCell width={40}>#</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Source</TableCell>
                <TableCell align="left">Target</TableCell>
                <TableCell align="left">Low Page Qty.</TableCell>
                <TableCell align="left">High Page Qty.</TableCell>
                <TableCell align="right">Low price</TableCell>
                <TableCell align="right">High price</TableCell>
                <TableCell align="right">Total</TableCell>
            </TableRow>
        </TableHead>
    );
}
