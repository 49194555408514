export default function Badge() {
    return {
        MuiBadge: {
            styleOverrides: {
                standard: {
                    width: 18,
                    height: 18,
                    borderRadius: '50%',
                    zIndex: 2,
                },
            },
        },
    };
}
