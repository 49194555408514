import { useQuery } from 'react-query';

function useRefetchAutoTranslation(taskId: string) {
    return useQuery(`tasks/${taskId}/autotranslation/restart`, {
        refetchOnWindowFocus: false,
        enabled: false,
    });
}

export default useRefetchAutoTranslation;
