import { Card, CardHeader, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Label from '../../../../../components/Label';
import * as React from 'react';
import useTranslatorStatistics from './useTranslatorStatistics';
import numeral from 'numeral';


const columns = ['Translator', 'Margin ratio', 'Pages delivered', 'Average delivery'];


const TranslatorStatistics: React.FC = () => {
    const { data: translatorData } = useTranslatorStatistics();

    if (!translatorData) {
        return <Card>
            <Skeleton variant={'rectangular'} height={400} />
        </Card>;
    }

    return <Card sx={{ p: 2, height: 480 }}>
        <CardHeader title="Translators" subheader="Key stats" sx={{ p: 0, mb: 3 }} />
        <Table sx={{
            '& .MuiTableCell-root': {
                padding: 1,
            },
        }}>
            <TableHead>
                <TableRow>
                    {columns.map((column) => <TableCell key={column}>{ column }</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {translatorData?.map((translator) => (
                    <TableRow key={translator.name}>
                        <TableCell>
                            {translator.name}
                        </TableCell>
                        <TableCell>
                            <Label>{numeral(translator.marginRatio).format('0.0%')}</Label>
                        </TableCell>
                        <TableCell>
                            {numeral(translator.pagesDelivered).format('0,0')} pages
                        </TableCell>
                        <TableCell>
                            {translator.averageDelivery ? numeral(translator.averageDelivery).format('0.0') + ' days' : '-'}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>

    </Card>;
};

export default TranslatorStatistics;
