import { useQuery } from 'react-query';
import createQueryString from '../../../utils/createQueryString';
import Priority from '../../../models/Priority';

function useCustomerPriorities(customerId?: string) {
    return useQuery<any>(createQueryString('priorities', { customerId: customerId }), {
        enabled: !!customerId,
        select(res) {
            return res.map((priority: Priority) => ({
                id: priority.id ?? 'id missing',
                name: priority.name ?? 'name missing',
            }));
        },
    });
}

export default useCustomerPriorities;
