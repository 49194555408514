import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { API } from '../../../../utils/ApiClient';
import LanguagePair from '../../../../models/LanguagePair';


function useSaveLanguagePairs() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ languagePairs }: {languagePairs: LanguagePair[]}) => API.post(`/languagepairs/bulkcreate`, languagePairs),
        onSuccess() {
            queryClient.invalidateQueries('languagepairs');
            enqueueSnackbar('Language Pairs saved', {
                variant: 'success',
            });
        },
    });
}


export default useSaveLanguagePairs;

