import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../../utils/ApiClient';
import Billing from '../../../../models/BillingDetails';
import { useSnackbar } from 'notistack';

function useUpdateBillingDetails() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: ({ billing } : { billing: Billing }) => API.put('/billing/' + billing.id, billing),
        onSuccess: () => {
            queryClient.invalidateQueries('billing');
            enqueueSnackbar('Billing details updated successfully', { variant: 'success' });
        },
    });
}

export default useUpdateBillingDetails;
