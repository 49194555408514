import { Button, Card, Container, Stack } from '@mui/material';
import PageHeader from '../../../components/PageHeader';
import { Icon } from '@iconify/react';
import downloadFill from '@iconify/icons-eva/download-fill';
import Filters from './Filters';
import ConnectedTable from '../../../components/ConnectedTable';
import Page from '../../../components/Page';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import Task from '../../../models/Task';
import columns from './columns';
import TaskDetails from './TaskDetails';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import numeral from 'numeral';
import useExportData from './useExportData';

const SummaryPage: React.FC = () => {
    const { mutate: exportData } = useExportData();
    const { isCollapse } = useCollapseDrawer();
    const location = useLocation();

    const { data } = useQuery<{ total: number }, unknown, string>(['tasks', location.search ? location.search + '&' : '?', 'summary=true'], {
        staleTime: 10000,
        select: (res) => {
            return 'Total: ' + numeral(res.total).format('0,0') + ' EUR';
        },
    });

    return <>
        <Page title={'Task summary'}>
            <Container maxWidth={isCollapse ? false : 'lg'}>
                <PageHeader
                    title={'Task summary'}
                    actions={<Button
                        onClick={() => exportData()}
                        variant="contained"
                        startIcon={<Icon icon={downloadFill} />}
                    >
                        Export
                    </Button>} />
                <Card>
                    <Filters />
                    <Stack px={1}>
                        <ConnectedTable<Task>
                            queryKey={'tasks'}
                            idKey={'id'}
                            defaultFilters={{ summary: 'true' }}
                            columns={columns}
                            summaryInfo={data}
                            expandedComponent={(task) => <TaskDetails task={task} />}
                        />
                    </Stack>
                </Card>
            </Container>
        </Page></>;
};

export default SummaryPage;
