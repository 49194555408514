import React, { useRef, useEffect } from 'react';

export default function ScrollToBottom(): JSX.Element {
    const elementRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (elementRef.current) {
            elementRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
    });
    return <div ref={elementRef} />;
}
