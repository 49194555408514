/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { CircularProgress, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useField } from 'formik';
import TextField from '../../../components/Form/fields/TextField';
import PricingComponent from './PricingComponent';
import SwitcherFieldRevert from '../../../components/Form/fields/SwitcherFieldRevert';
import SwitcherField from '../../../components/Form/fields/SwitcherField';
import { Icon } from '@iconify/react';
import repeatIcon from '@iconify/icons-eva/repeat-fill';
import useRefetchPriceCalculation from './useRefetchPriceCalculation';

type Props = {
    taskId?: string;
}

const CustomerPricing: React.FC<Props> = ({ taskId }) => {
    const [field] = useField('customCustomerPrice');
    const { refetch, isRefetching } = useRefetchPriceCalculation(taskId ?? '');

    const restartAutoTranslation = () => {
        refetch();
    };


    return useMemo(() => {
        const isPricingComponentHidden = field.value;
        return (
            <Stack>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography alignSelf={'flex-start'}
                        variant='subtitle2' color='text.secondary' gutterBottom>
                        Customer pricing
                    </Typography>
                    <Stack alignItems={'center'} direction={'row'}>
                        {!isRefetching ? (
                            <Tooltip title={'Recalculate'}>
                                <IconButton onClick={restartAutoTranslation} sx={{ mt: -0.5 }}>
                                    <Icon icon={repeatIcon} width={20} height={20} />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <CircularProgress color='primary' size={20} sx={{ ml: 1 }} />
                        )}
                    </Stack>
                </Stack>
                <Stack alignItems={'center'}>
                    {isPricingComponentHidden ?
                        <TextField label={'Customer price'} name={'price'} /> :
                        <PricingComponent />}
                    <Stack direction={'row'} gap={2}>
                        <SwitcherFieldRevert label={'Free task'} name={'include'} />
                        <SwitcherField label={'Set manually'} name={'customCustomerPrice'} />
                    </Stack>
                </Stack>
            </Stack>
        );
    }, [field.value]);
};

export default CustomerPricing;
