import { Avatar, IconButton, List, Stack, TextField, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router';
import _ from 'lodash';
import SkeletonUser from '../SkeletonUser';
import useAutocomplete from '../../../hooks/useAutocomplete';
import { AnimateSharedLayout } from 'framer-motion';
import Conversation from './Conversation';
import ConversationType from '../../../models/Conversation';


const Sidebar: React.FC = () => {
    const navigate = useNavigate();
    const { options, isLoading, hasNoOptions, onChange } = useAutocomplete<ConversationType>(`conversations?name=`, undefined, true);


    return (
        <Stack height={'100%'}>
            <Stack p={2} gap={2}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Avatar color={'secondary'} sx={{ fontSize: 60 }} />
                    <IconButton onClick={() => navigate('/chat/new')}>
                        <Icon icon={'jam:write'} />
                    </IconButton>
                </Stack>

                <TextField size={'small'} onChange={onChange} label={'Search conversation...'} />
            </Stack>
            <Stack position={'relative'} flex={1}>
                <Stack position={'absolute'} overflow={'scroll'} top={0} bottom={-16} left={0} right={-4}>
                    <List>
                        {isLoading && _.times(10, (i) => (<SkeletonUser key={i} />))}
                        {hasNoOptions && <Typography px={3}>No items to show</Typography>}
                        <AnimateSharedLayout>
                            {options?.map((conversation) => (
                                <Conversation conversation={conversation} key={conversation.id} />
                            ))}
                        </AnimateSharedLayout>
                    </List>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Sidebar;
