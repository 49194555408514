import Task from '../../../models/Task';
import { UserRole } from '../../../enums/UserRole';

const setSeenStatus = (task: Task, role: UserRole):Task => {
    const nextTask = { ...task };
    if (role === UserRole.ADMIN) {
        nextTask.adminSeen = true;
    }
    if (role === UserRole.TRANSLATOR) {
        nextTask.translatorSeen = true;
    }
    if ([UserRole.USER, UserRole.AGENT].includes(role)) {
        nextTask.customerSeen = true;
    }
    if (role === UserRole.LINGUIST) {
        nextTask.linguistSeen = true;
    }
    return nextTask;
};

export default setSeenStatus;
