import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { Box, Theme, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { useSnackbar } from 'notistack';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { UploadIllustration } from '../../assets';
import config from '../../config';
import convertBytes from '../../utils/ByteConverter';
import { MIconButton } from '../@material-extend';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
    'outline': 'none',
    'display': 'flex',
    'overflow': 'hidden',
    'textAlign': 'center',
    'position': 'relative',
    'alignItems': 'center',
    'flexDirection': 'column',
    'justifyContent': 'space-around',
    'padding': theme.spacing(2, 0),
    'borderRadius': theme.shape.borderRadius,
    'transition': theme.transitions.create('padding'),
    'backgroundColor': theme.palette.background.neutral,
    'border': `1px dashed ${theme.palette.grey[500_32]}`,
    '&:hover': {
        opacity: 0.72,
        cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' },
}));

// ----------------------------------------------------------------------

interface UploadMultipleFileProps extends DropzoneOptions {
    onUpload: (files: globalThis.File[]) => void;
    error?: boolean;
  sx?: SxProps<Theme>;
}

export default function UploadMultipleFile({
    error = false,
    sx,
    onUpload,
    ...other
}: UploadMultipleFileProps): JSX.Element {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        multiple: true,
        onDropAccepted: (file) => onUpload(file),
        maxSize: config.uploadLimit,
        onDropRejected: (response) => {
            enqueueSnackbar(
                response[0].errors[0].code === 'file-too-large' ?
                    `Uploaded file is too large! Maximum allowed file size: ${convertBytes(config.uploadLimit)}, 
                    actual file size: ${convertBytes(response[0].file.size)}` :
                    'Unknown error occurred during file upload!',
                {
                    autoHideDuration: 5000,
                    variant: 'error',
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    ),
                });
        },
        ...other,
    });

    return (
        <Box sx={{ width: '100%', ...sx }}>
            <DropZoneStyle
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...((isDragReject || error) && {
                        color: 'error.main',
                        borderColor: 'error.light',
                        bgcolor: 'error.lighter',
                    }),
                }}
            >
                <input {...getInputProps()} />

                <UploadIllustration sx={{ maxWidth: 120, ml: 2 }} />

                <Box sx={{ p: 2, ml: { md: 2 } }}>
                    <Stack direction={'column'}>
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            Drop file(s) here or click&nbsp;
                            <Typography
                                variant="caption"
                                component="span"
                                sx={{ color: 'primary.main', textDecoration: 'underline' }}
                            >
                            browse
                            </Typography>
                            &nbsp;thorough your machine.
                        </Typography>
                        <Typography variant="caption">
                        Maximum file size: {convertBytes(config.uploadLimit)}
                        </Typography>
                    </Stack>
                </Box>
            </DropZoneStyle>
        </Box>
    );
}
