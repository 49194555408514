import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import UploadAvatar from '../upload/UploadAvatar';
import img from '../../assets/images/customer.jpg';
import useBinaryLogoByCustomer from './useBinaryLogoByCustomer';
import useUploadCustomerLogo from './useUploadCustomerLogo';
import { useParams } from 'react-router-dom';


const RootStyle = styled('div')(({ theme }) => ({
    '&:before': {
        top: 0,
        zIndex: 9,
        width: '100%',
        content: '\'\'',
        height: '100%',
        position: 'absolute',
        backdropFilter: 'blur(3px)',
        WebkitBackdropFilter: 'blur(3px)',
        backgroundColor: alpha(theme.palette.primary.darker, 0.72),
    },
}));

const InfoStyle = styled('div')(({ theme }) => ({
    left: 0,
    right: 0,
    zIndex: 99,
    position: 'absolute',
    marginTop: theme.spacing(8),
}));

const CoverImgStyle = styled('img')(() => ({
    zIndex: 8,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
}));


export default function CustomerCover(): JSX.Element {
    const params = useParams();
    const customerId = params.id;
    const { mutate } = useUploadCustomerLogo(customerId);
    const { data: logoBinary } = useBinaryLogoByCustomer(customerId);

    return (
        <RootStyle>
            <InfoStyle>
                <UploadAvatar
                    onUpload={(file: globalThis.File) => mutate({ file })}
                    file={logoBinary ?? ''}
                />
            </InfoStyle>
            <CoverImgStyle alt="customer cover" src={img} />
        </RootStyle>
    );
}
