import { Grid } from '@mui/material';
import TextField from '../../../Form/fields/TextField';
import React from 'react';
import { useFormikContext } from 'formik';
import SwitcherField from '../../../Form/fields/SwitcherField';
import ProfileEditorForm from '../ProfileEditorForm';

const InvoiceAddress: React.FC = () => {
    const { values } = useFormikContext<ProfileEditorForm>();

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <SwitcherField label={'Is same as postal'} name={'isSameAsPostal'} />
        </Grid>
        {!values.isSameAsPostal && <><Grid item xs={12}>
            <TextField name={'invoiceAddress.name'} label={'Name'}/>
        </Grid>
        <Grid item xs={6}>
            <TextField name={'invoiceAddress.zip'} label={'ZIP'}/>
        </Grid>
        <Grid item xs={6}>
            <TextField name={'invoiceAddress.country'} label={'Country'}/>
        </Grid>
        <Grid item xs={6}>
            <TextField name={'invoiceAddress.state'} label={'State'}/>
        </Grid>
        <Grid item xs={6}>
            <TextField name={'invoiceAddress.city'} label={'City'}/>
        </Grid>
        <Grid item xs={12}>
            <TextField name={'invoiceAddress.address'} label={'Address'}/>
        </Grid>
        <Grid item xs={12}>
            <TextField name={'invoiceAddress.vatNumber'} label={'VAT number'}/>
        </Grid></>}
    </Grid>;
};

export default InvoiceAddress;
