/* eslint-disable indent */
import { useReducer, createContext } from 'react';
import { FormattedAnnotation, AnnotatedPage } from './AnnotationTypes';

type EditorState = {
    originalData: FormattedAnnotation;
    transformedData: FormattedAnnotation;
    selectedPage: number;
    selectedPageData: AnnotatedPage | null;
    selectedBlockId: string | null;
    selectedScrollToId: string | null;
    sidebarStatus: boolean;
};

type EditorActions =
    | {
          type: 'INIT_EDITOR';
          payload: {
              annotations: FormattedAnnotation;
              selectedPage: number;
              selectedPageData: AnnotatedPage;
          };
      }
    | {
          type: 'SET_SELECTED_PAGE';
          payload: number;
      }
    | {
          type: 'SET_SELECTED_PAGE_DATA';
          payload: AnnotatedPage;
      }
    | {
          type: 'SET_SELECTED_BLOCK_ID';
          payload: string | null;
      }
    | {
          type: 'SET_SELECTED_SCROLL_TO_ID';
          payload: string | null;
      }
    | {
          type: 'SET_TRANSFORMED_DATA';
          payload: FormattedAnnotation;
      }
    | {
          type: 'SET_SIDEBAR_STATUS';
          payload: boolean;
      }
    | {
          type: 'RESET';
      }
    | {
          type: 'DISCARD';
      };

const initState: EditorState = {
    originalData: [],
    transformedData: [],
    selectedPage: 0,
    selectedPageData: null,
    selectedBlockId: null,
    selectedScrollToId: null,
    sidebarStatus: false,
};

const EditorContext = createContext<{
    editorState: EditorState;
    editorDispatch: React.Dispatch<EditorActions>;
}>({ editorState: initState, editorDispatch: () => null });

const EditorReducer = (state: EditorState, action: EditorActions): EditorState => {
    switch (action.type) {
        case 'INIT_EDITOR':
            return {
                ...state,
                originalData: action.payload.annotations,
                transformedData: action.payload.annotations,
                selectedPage: action.payload.selectedPage,
                selectedPageData: action.payload.selectedPageData,
            };

        case 'SET_SELECTED_PAGE':
            return {
                ...state,
                selectedPage: action.payload,
            };

        case 'SET_SELECTED_PAGE_DATA':
            return {
                ...state,
                selectedPageData: action.payload,
            };

        case 'SET_SELECTED_BLOCK_ID':
            return {
                ...state,
                selectedBlockId: action.payload,
            };

        case 'SET_SELECTED_SCROLL_TO_ID':
            return {
                ...state,
                selectedScrollToId: action.payload,
            };

        case 'SET_TRANSFORMED_DATA':
            return {
                ...state,
                transformedData: action.payload,
            };

        case 'SET_SIDEBAR_STATUS':
            return {
                ...state,
                sidebarStatus: action.payload,
            };

        case 'RESET':
            return {
                ...initState,
                originalData: state.originalData,
                selectedPageData: state.originalData[0],
                selectedPage: 1,
                transformedData: state.originalData,
            };

        case 'DISCARD':
            return { ...initState };

        default:
            return state;
    }
};

interface PropTypes {
    children: JSX.Element;
}

const EditorProvider = ({ children }: PropTypes): JSX.Element => {
    const [editorState, editorDispatch] = useReducer(EditorReducer, initState);

    return <EditorContext.Provider value={{ editorState, editorDispatch }}>{children}</EditorContext.Provider>;
};

export { EditorContext, EditorProvider };
