/* eslint-disable quote-props */
import { Box } from '@mui/material';
import DocumentBlocks from './DocumentBlocks';

const EditorSidebar = () => {
    return (
        <Box
            style={{
                height: '100vh',
                width: '100%',
                backgroundColor: '#b4b4b473',
            }}
        >
            <Box
                style={{
                    width: '100%',
                    overflowX: 'hidden',
                    height: '100%',
                }}
            >
                <DocumentBlocks />
            </Box>
        </Box>
    );
};

export default EditorSidebar;
