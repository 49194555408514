import { Card, Typography } from '@mui/material';
import { UploadSingleFile } from '../../../components/upload';
import React from 'react';
import useUploadInvoiceToReport from './useUploadInvoiceToReport';

const InvoiceUpload: React.FC<{ reportId?: string }> = ({ reportId } ) => {
    const { mutate: uploadInvoice } = useUploadInvoiceToReport(reportId);

    const uploadInvoiceToReport = (file: File) => {
        uploadInvoice({ file });
    };

    return (
        <Card sx={{ p: 2 }}>
            <Typography variant='subtitle2' color='text.secondary' sx={{ textTransform: 'capitalize' }} gutterBottom>
                Invoice for Translator Report
            </Typography>
            <UploadSingleFile onUpload={uploadInvoiceToReport}/>
        </Card>
    );
};

export default InvoiceUpload;
