import { Divider, IconButton, Input, Stack } from '@mui/material';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useSendMessage from './useSendMessage';


const MessageEditor: React.FC = () => {
    const { mutate: sendMessage } = useSendMessage();
    const [message, setMessage] = useState('');
    const theme = useTheme();

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSend();
        }
    };

    const handleSend = () => {
        sendMessage({ message });
        setMessage('');
    };

    return <Stack
        borderTop={'1px solid ' + theme.palette.divider}
        minHeight={56}
        pl={2}
        position='relative'
        alignItems='center'
        direction={'row'}
    >
        <Input
            onKeyUp={handleKeyUp}
            fullWidth
            value={message}
            disableUnderline
            onChange={(event) => setMessage(event.target.value)}
            placeholder="Type a message"
        />

        <Divider orientation="vertical" flexItem />

        <IconButton color="primary" disabled={!message} sx={{ mx: 1 }} onClick={handleSend}>
            <Icon icon="ic:round-send" width={22} height={22} />
        </IconButton>

    </Stack>;
};

export default MessageEditor;
