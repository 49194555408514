import { useEffect, useState } from 'react';
import qs, { ParsedUrlQuery } from 'querystring';
import { useLocation, useNavigate } from 'react-router-dom';


function useQueryString(): [ ParsedUrlQuery, (partial: any) => void ] {
    const navigate = useNavigate();
    const location = useLocation();
    const [query, setQuery] = useState<ParsedUrlQuery>(qs.parse(location.search.substring(1)));

    const setValue = (partial: any) => {
        const nextQuery = {
            ...query,
            ...partial,
        };
        const queryString = location.pathname + '?' + qs.stringify(nextQuery);
        navigate(queryString, { replace: false });
    };

    useEffect(() => {
        const nextQuery = qs.parse(location.search.substring(1));
        setQuery(nextQuery);
    }, [location.search]);

    const value = query ?? '';
    return [value, setValue];
}

export default useQueryString;
