import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';


function useDeleteTaskFile(id: string, containerId: string, containerType: 'task' | 'user', onClose?: () => void) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => API.delete(containerType === 'task' ? `tasks/${containerId}/files/${id}` : `users/${containerId}/files/${id}`),
        onSuccess() {
            if (containerType === 'task') {
                queryClient.invalidateQueries(`/tasks/${containerId}/files`);
            } else {
                queryClient.invalidateQueries(`users/${containerId}/files`);
            }

            if (onClose) {
                onClose();
            }
        },
    });
}

export default useDeleteTaskFile;
