import { useMutation } from 'react-query';
import Task from '../../models/Task';
import { API } from '../../utils/ApiClient';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import CustomFile from '../../interfaces/CustomFile';
import { FileType } from '../../enums/FileType';

function useCreateTask(files: CustomFile[]) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    return useMutation<void, unknown, Task>({
        mutationFn: async (form) => {
            for (const file of files) {
                const res = await API.post(`tasks/`, form);
                const fileData = new FormData();
                fileData.append(FileType.ORIGINAL, file.file, file.file.name);
                await API.post(`/tasks/${res.data.id}/files`, fileData, { timeout: 60000 });
            }
        },
        onSuccess: async () => {
            enqueueSnackbar('Task created', {
                variant: 'success',
            });
            navigate('/tasks');
        },
    });
}

export default useCreateTask;
