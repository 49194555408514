import { Document, Page } from 'react-pdf';
import { Box, Paper } from '@mui/material';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import useEditor from './useEditor';
import useAPI from '../../utils/ApiClient';
import { useParams } from 'react-router-dom';
import useFilesByTask from '../TaskEditor/FilesByType/useFilesByTask';
import { FileType } from '../../enums/FileType';

type PropTypes = {
    show: 'translated' | 'both' | 'original';
};

const OriginalDocument = ({ show }: PropTypes) => {
    const { id: taskId, fileId } = useParams();
    const [, previewFile] = useAPI({ method: 'GET' }, { manual: true });
    const { data: files } = useFilesByTask(FileType.ORIGINAL, taskId);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const {
        editorState: { selectedPage, selectedPageData },
    } = useEditor();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const document = useMemo(() => {
        return files && files[0].mimetype === 'application/pdf' ? (
            <Document
                file={{
                    url: previewUrl,
                }}
            >
                <Page
                    pageNumber={selectedPage}
                    className='pdf-document-page'
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                />
            </Document>
        ) : null;
    }, [previewUrl, selectedPage]);

    useEffect(() => {
        if (files) {
            (async () => {
                const url = `/tasks/${taskId}/files/${files[0].id}/preview`;
                const res = (await previewFile({ url })).data;
                if (res) {
                    setPreviewUrl(res);
                }
            })();
        }
    }, [files]);

    return (
        <Box sx={{ display: 'flex', margin: 'auto' }} justifyContent='center' alignItems='center'>
            <Paper
                sx={{
                    borderRadius: 0,
                    width: '100%',
                }}
                elevation={3}
                ref={wrapperRef}
            >
                {previewUrl &&
                    files &&
                    (files[0].mimetype === 'application/pdf' ? (
                        document
                    ) : (
                        <img src={previewUrl} style={{ width: '100%' }} alt='original_image' />
                    ))}
            </Paper>
        </Box>
    );
};

export default memo(OriginalDocument);
