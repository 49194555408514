import { Box } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import * as React from 'react';
import { merge } from 'lodash';
import { useTheme } from '@mui/material/styles';
import getBaseOptionChart from '../../theme/getBaseOptionChart';
import numeral from 'numeral';
import { ApexOptions } from 'apexcharts';


type HorizontalBarChartType = {
    data: number[]
    categories: string[]
}


const HorizontalBarChart: React.FC<HorizontalBarChartType> = ( { data, categories } ) => {
    const theme = useTheme();

    const chartOptions: ApexOptions = merge(getBaseOptionChart(theme), {
        tooltip: {
            marker: { show: false },
            y: {
                formatter: (seriesName: string) => numeral(seriesName).format('0,0'),
                title: {
                    formatter: () => '',
                },
            },
        },
        plotOptions: {
            bar: { horizontal: true, barHeight: '28%', borderRadius: 2 },
        },
        xaxis: {
            labels: {
                formatter: (value: string) => numeral(value).format('0,0'),
            },
            categories,
        },
    });


    return (
        <Box sx={{
            'mx': 3,
            '& .apexcharts-tooltip.apexcharts-theme-light': {
                backgroundColor: theme.palette.background.paper,
            } }} dir="ltr">
            <ReactApexChart type="bar" series={[{ data }]} options={chartOptions} height={388} />
        </Box>
    );
};

export default HorizontalBarChart;
