import parseJSON from './parseJSON';


/**
 * Utility method to parse stringified object to an array of key-value pairs for display purposes
 * Eg "{apple: 'tree'}" => [ { key: 'apple', value: 'tree'} ]
 * Method does not work recursively, in case a field's value is an object it will not be added to the displayable array.
 * @param {string} raw: stringified JSON object
 * @return {Object[]}
 */
function parseJSONObjectToKeyValueArray(raw?: string): { key: string; value: string | number }[] {
    if (!raw) {
        return [];
    }

    const object = parseJSON<Record<string, string | number>>(raw, {});
    const keys = Object.keys(object);

    const keyValueArray: {key: string; value: number | string}[] = [];
    keys.forEach((key) => {
        if (!['string', 'number'].includes(typeof object[key])) {
            return;
        }

        keyValueArray.push({
            key,
            value: object[key],
        });
    });

    return keyValueArray;
}

export default parseJSONObjectToKeyValueArray;
