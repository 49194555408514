import Billing from '../../../../models/BillingDetails';
import useBillingDetails from './useBillingDetails';
import Form from '../../../Form';
import TextField from '../../../Form/fields/TextField';
import { Card, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import useUpdateBillingDetails from './useUpdateBillingDetails';
import useCreateBillingDetails from './useCreateBillingDetails';

const emptyBillingDetailsForm: Billing = {
    address: '', city: '', country: '', name: '', state: '', vatNumber: '', zip: '',
};

type BillingDetailsForm = Billing

const BillingDetails: React.FC = () => {
    const { mutate: updateBillingDetails } = useUpdateBillingDetails();
    const { mutate: createBillingDetails } = useCreateBillingDetails();
    const { data: billingDetails } = useBillingDetails();


    const onSubmit = (values: BillingDetailsForm) => {
        if (values.id) {
            updateBillingDetails({ billing: values });
        } else {
            createBillingDetails({ billing: values });
        }
    };

    return <Card sx={{ p: 2 }}>
        <Form<BillingDetailsForm>
            initialValues={billingDetails ?? emptyBillingDetailsForm}
            onSubmit={onSubmit}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField name={'name'} label={'Name'} />
                </Grid>
                <Grid item xs={6}>
                    <TextField name={'zip'} label={'ZIP'} />
                </Grid>
                <Grid item xs={6}>
                    <TextField name={'country'} label={'Country'} />
                </Grid>
                <Grid item xs={6}>
                    <TextField name={'state'} label={'State'} />
                </Grid>
                <Grid item xs={6}>
                    <TextField name={'city'} label={'City'} />
                </Grid>
                <Grid item xs={12}>
                    <TextField name={'address'} label={'Address'} />
                </Grid>
                <Grid item xs={12}>
                    <TextField name={'vatNumber'} label={'VAT number'} />
                </Grid>
                <Grid item xs={12}>
                    <Button variant={'contained'} fullWidth type={'submit'}>Save</Button>
                </Grid>
            </Grid>
        </Form>
    </Card>;
};

export default BillingDetails;
