import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import useUserById from '../../../../../hooks/useUserById';
import FileType from '../../../../../models/File';
import { UserRole } from '../../../../../enums/UserRole';

function useTranslatorCertificates() {
    const { id } = useParams();
    const { data: user } = useUserById(id);
    return useQuery<FileType[]>(`users/${user?.id}/files`, {
        enabled: !!user && user.role === UserRole.TRANSLATOR,
    });
}

export default useTranslatorCertificates;
