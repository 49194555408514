import React, { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

const PageHeader: React.FC<{ title: string; actions?: ReactNode }> = ({ title, actions }) => {
    return (
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={4}>
            <Typography variant={'h5'}>{title}</Typography>
            {actions}
        </Stack>
    );
};

export default PageHeader;
