function createQueryString(path: string, params: { [key: string]: any }) {
    const queryParams = Object.entries(params)
        .filter(([, value]) => !!value)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');

    return `${path}?${queryParams}`;
}

export default createQueryString;
