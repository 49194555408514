import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import MuiDialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import React, { cloneElement, ReactElement, ReactNode, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import Form from '../Form';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props}>{ props.children}</Slide>;
});


type DialogProps<T> = {
    trigger: ReactElement
    onSubmit: (form?: T) => void
    initialValues?: T
    submitLabel: string
    title: string
    content: string | ReactNode
    validationSchema?: unknown
}

function FormDialog<T>({ trigger, validationSchema, content, title, onSubmit, submitLabel, initialValues }: DialogProps<T>) {
    const [isOpen, setIsOpen] = useState(false);

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            {cloneElement(trigger, { onClick: () => setIsOpen(true) })}
            <MuiDialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
            >
                <DialogTitle id="delete--dialog-title"
                    sx={{ textAlign: 'center' }}>{title}</DialogTitle>
                <Form
                    validationSchema={validationSchema}
                    onSubmit={(form ) => {
                        onSubmit(form);
                        onClose();
                    }} initialValues={initialValues}>
                    <DialogContent sx={{ width: 400 }}>
                        <DialogContentText id="delete--dialog-description" sx={{ textAlign: 'center' }}>
                            {content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button variant="contained" type={'submit'} color="primary">
                            {submitLabel}
                        </Button>
                    </DialogActions>
                </Form>
            </MuiDialog>
        </>
    );
}

export default FormDialog;
