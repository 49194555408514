import { useQuery } from 'react-query';
import Customer from '../models/Customer';

function useCustomers(queryParams?: string) {
    const query = ['customers'];
    if (queryParams) {
        query.push('?' + queryParams);
    }

    return useQuery<{ customers: Customer[] }, unknown, Customer[]>(query, { select: (data) => data.customers, staleTime: Infinity });
}

export default useCustomers;
