import Label from '../../../components/Label';
import moment from 'moment';
import Actions from './Actions';
import React from 'react';
import Customer from '../../../models/Customer';
import Column from '../../../components/ConnectedTable/Column';

const columns: Column<Customer>[] = [
    { id: 'name', label: 'Name' },
    { id: 'translatedPageNumber', label: 'Pages translated' },
    { id: 'mustApprovePrice', label: 'Must approve price', getter(customer) {
        return <Label
            color={customer.mustApprovePrice ? 'primary' : 'secondary'}
            variant={customer.mustApprovePrice ? 'outlined' : 'ghost'}>{customer.mustApprovePrice ? 'Yes' : 'No'}</Label>;
    } },
    {
        id: 'created',
        label: 'Added at',
        getter(customer) {
            return moment(customer.created).format('YYYY MMM. DD');
        },
    },
    { id: 'modified', width: 60, label: '', getter(customer) {
        return <Actions customer={customer} />;
    } },
];

export default columns;
