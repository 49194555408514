import lodash from 'lodash';

/* eslint-disable no-unused-vars */
export enum Sureness {
    SURE = 'sure',
    MAYBE_SURE = 'maybe_sure',
    NOT_SURE = 'not_sure',
}

export function parse(sureness: Sureness): { name: string } {
    return {
        name: lodash.capitalize(sureness.replace('_', ' ')),
    };
}


export const surenessOptions = [
    { value: Sureness.SURE, label: parse(Sureness.SURE).name },
    { value: Sureness.MAYBE_SURE, label: parse(Sureness.MAYBE_SURE).name },
    { value: Sureness.NOT_SURE, label: parse(Sureness.NOT_SURE).name },
];
