import { useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import useDeletePriority from './useDeletePriority';
import { PriorityForm } from './Priorities';
import Priority from '../../../models/Priority';
import TextField from '../../Form/fields/TextField';
import { Button, IconButton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';


const PriorityEditor = () : JSX.Element => {
    const { id: customerId } = useParams();
    const { mutate: deletePriority } = useDeletePriority();
    const { setFieldValue, values } = useFormikContext<PriorityForm>();

    const onAddPriority = () => {
        const priority: Priority = {
            id: uuidv4(),
            name: '',
            customerId: customerId,
            scannedHours: 0,
            deletable: true,
            isNew: true,
        };

        setFieldValue('priorities', [...values.priorities, priority]);
    };


    function removePriorityByIndex(prioIndex: number) {
        const targetPriority = values.priorities[prioIndex];
        if (targetPriority.isNew) {
            setFieldValue('priorities', values.priorities.filter((l, index) => index !== prioIndex));
        } else {
            deletePriority({ id: targetPriority.id });
        }
    }


    return (
        <Stack>
            {values.priorities.map((priority, index) => (
                <Stack direction={'row'} spacing={2} key={priority.id} sx={{ my: 1 }}>
                    <TextField label={'Name'} name={`priorities[${index}].name`} />
                    <TextField label={'Hours'} name={`priorities[${index}].scannedHours`} type='number'/>
                    <IconButton onClick={() => removePriorityByIndex(index)} disabled={!priority.deletable ?? true}>
                        <Icon icon={trash2Outline} width={20} height={20} />
                    </IconButton>
                </Stack>
            ))}
            <Stack direction='column' gap={2} alignItems="center">
                <Button color="inherit"
                    startIcon={<Icon icon={plusFill} width={20} height={20}/>}
                    onClick={onAddPriority}
                >
                    Add Priority
                </Button>
                <Button type={'submit'} variant={'contained'}>Save</Button>
            </Stack>

        </Stack>
    );
};

export default PriorityEditor;
