import { useQuery, useQueryClient } from 'react-query';
import UserEvent from '../../../models/UserEvent';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useMe from '../../../hooks/useMe';
import useSocket from '../../../contexts/SocketContext';

function useUserEventLogs(shouldNotify?: boolean) {
    const socket = useSocket();
    const { enqueueSnackbar } = useSnackbar();
    const [newItems, setNewItems] = useState<(string | undefined)[]>([]);
    const queryClient = useQueryClient();
    const { data: me } = useMe();

    useEffect(() => {
        socket?.on('USER_EVENTS_UPDATE', (userEvents: UserEvent[]) => {
            setNewItems(userEvents.map((u) => u.id));
            queryClient.invalidateQueries('userevents/all');
            if (shouldNotify) {
                enqueueSnackbar('New event update', {
                    variant: 'info',
                });
            }
        });

        return () => {
            socket?.off('USER_EVENTS_UPDATE');
        };
    }, [enqueueSnackbar, queryClient, shouldNotify, socket]);

    return useQuery<(UserEvent & { isNew: boolean })[]>('userevents/all', {
        staleTime: Infinity,
        enabled: me?.isAdminOrCustomer,
        select(res) {
            return res.map((userEvent) => {
                return {
                    ...userEvent,
                    isNew: newItems.includes(userEvent.id),
                };
            });
        } });
}

export default useUserEventLogs;
