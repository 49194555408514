import { Link, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import TextField from '../../Form/fields/TextField';
import CheckboxField from '../../Form/fields/CheckboxField';
import Form from '../../Form';
import useLogin from './useLogin';
import PasswordField from '../../Form/fields/PasswordField';


export type LoginForm = {
  email: string;
  password: string;
  rememberMe: boolean;
};

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email required'),
    password: Yup.string().required('Password required'),
});

export default function LoginForm(): JSX.Element {
    const { mutate, isError, isLoading } = useLogin();

    const onSubmit = (form: LoginForm) => {
        mutate({ form });
    };


    return (
        <Form<LoginForm>
            onSubmit={onSubmit}
            validationSchema={LoginSchema}
            initialValues={{
                rememberMe: false,
                email: '',
                password: '',
            }}>
            <Stack spacing={2}>
                <TextField name={'email'} label="Email address"/>
                <PasswordField name={'password'} label="Password"/>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <CheckboxField name={'rememberMe'} label={'Remember me'} />
                    <Link component={RouterLink} variant="subtitle2" to={'/reset-password'}>
                       Forgot password?
                    </Link>
                </Stack>
                <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                >
                    Login
                </LoadingButton>
                {isError && <Typography color={'error'}>Invalid email and/or password</Typography>}
            </Stack>
        </Form>
    );
}
