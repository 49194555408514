import { Box, Container, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import { useTheme } from '@mui/material/styles';
import Page from '../../components/Page';


const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const SectionStyle = styled(Paper)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));


export default function Login(): JSX.Element {
    const theme = useTheme();
    const date = new Date();
    const hours = date.getHours();
    return (
        <RootStyle title="WeTranslate">
            <MHidden width="mdDown">
                <SectionStyle>
                    {theme.palette.mode === 'light' ? (
                        <img
                            src="/static/icons/logo_login.svg"
                            alt="login"
                            style={{ maxHeight: 350, maxWidth: 220 }}
                        />
                    ) : (
                        <img
                            src="/static/icons/logo_login_light.svg"
                            alt="login"
                            style={{ maxHeight: 300, maxWidth: 200 }}
                        />
                    )}
                    <Typography variant="h3" sx={{ px: 5, mt: 5 }}>
                        {hours < 12 ? 'Hi, Good Morning!' : hours < 18 ? 'Hi, Good Afternoon!' : 'Hi, Good Evening!'}
                    </Typography>
                </SectionStyle>
            </MHidden>

            <Container maxWidth="sm">
                <ContentStyle>
                    <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h4" gutterBottom>
                                Sign in
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
                        </Box>
                    </Stack>

                    <LoginForm />

                </ContentStyle>
            </Container>
        </RootStyle>
    );
}
