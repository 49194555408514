import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../../utils/ApiClient';
import { useParams } from 'react-router-dom';

function useDeleteMessage() {
    const queryClient = useQueryClient();
    const { userId } = useParams();
    return useMutation({
        mutationFn: ({ messageId }: {messageId: string}) => API.delete('messages/' + messageId),
        onSuccess: () => {
            queryClient.invalidateQueries('messages/' + userId);
        },
    });
}

export default useDeleteMessage;
