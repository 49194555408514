export default function generateHolidayYears(): number[] {
    const current = new Date().getFullYear();
    const max = current + 8;
    const min = current - 2;
    const years = [];

    for (let i = max; i >= min; i--) {
        years.push(i);
    }
    return years;
}
