/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Column from '../../../components/ConnectedTable/Column';
import User from '../../../models/User';
import Status from '../../../pages/admin/UserList/customFields/Status';
import Actions from './customFields/Actions';

const columns: Column<User>[] = [
    {
        getter(row): string | React.ReactNode {
            return row.firstname + ' ' + row.lastname;
        },
        id: 'id',
        label: 'Name',
    },
    {
        id: 'email',
        label: 'E-mail',
    },
    {
        id: 'phone',
        label: 'Phone number',
    },
    {
        id: 'role',
        label: 'Role',
        width: 100,
    },
    {
        id: 'active',
        label: 'Status',
        width: 120,
        getter(user) {
            return <Status user={user} />;
        },
    },

    {
        id: 'lastname',
        label: '',
        width: 60,
        getter(user) {
            return <Actions user={user} />;
        },
    },
];


export default columns;
